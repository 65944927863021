import React, { useState, useEffect } from "react";
import { Dropdown, DropdownProps, Icon } from "semantic-ui-react";
import {
  DeviceFilterOption,
  DeviceFilters,
  fetchDeviceFilterOptions,
} from "../../../BytebeamClient";

type DashboardFilterProps = {
  filterKeys: string[];
  selectedFilters: DeviceFilters;
  disabled: boolean;
  onFilterSelected: (filterName: string, filterValue: string[]) => void;
};

export function DashboardFilters(props: DashboardFilterProps) {
  let filterSelectedTimeout: any = null;

  const onFilterChange = (
    data: DropdownProps,
    filterOption: DeviceFilterOption
  ) => {
    const filterName = filterOption.filterName;
    const filterValue = data.value as string[];
    clearTimeout(filterSelectedTimeout);
    filterSelectedTimeout = setTimeout(() => {
      props.onFilterSelected(filterName, filterValue);
    }, 1000);
  };

  const [filterOptions, setFilterOptions] = useState<DeviceFilterOption[]>([]);

  useEffect(() => {
    const defaultFilterOptions = props.filterKeys.map((filterName) => {
      return {
        filterName: filterName,
        filterValues: [],
      };
    });

    setFilterOptions(defaultFilterOptions);
  }, [props.filterKeys]);

  useEffect(() => {
    const refreshFilterOptions = async (filters: DeviceFilters) => {
      const filterOptions = await fetchDeviceFilterOptions(filters);
      const filterOptionsMap = Object.fromEntries(
        filterOptions.map(({ filterName, filterValues }) => [
          filterName,
          filterValues,
        ])
      );

      setFilterOptions(
        props.filterKeys.map((filterName) => {
          return {
            filterName: filterName,
            filterValues: filterOptionsMap[filterName] || [],
          };
        })
      );
    };

    refreshFilterOptions(props.selectedFilters);
  }, [props.selectedFilters, props.filterKeys]);

  return (
    <div className="dashboard-filters">
      <Icon name="filter" />

      {filterOptions.map((filterOption) => {
        // Filtering out values containing only white spaces and Mapping to Dropdown options using FlatMap
        const options = filterOption.filterValues.flatMap((v) => {
          let value = String(v);
          if (value?.trim() !== "")
            return [
              {
                key: value,
                value: value,
                text: value,
              },
            ];
          else return [];
        });

        return (
          <span
            className="dashboard-header-dropdown"
            key={filterOption.filterName}
          >
            <Dropdown
              placeholder={filterOption.filterName}
              options={options}
              search
              multiple
              selection
              button
              labeled
              defaultValue={props.selectedFilters[filterOption.filterName]}
              onChange={(_event, data) => onFilterChange(data, filterOption)}
              disabled={props.disabled}
            />
          </span>
        );
      })}
    </div>
  );
}
