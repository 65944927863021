import React, { MouseEvent, useEffect, useState } from "react";
import {
  Device,
  DeviceFilters,
  SearchDeviceResponse,
  createDeviceConfiguration,
  searchDevices,
  triggerDeviceAction,
} from "../../../../BytebeamClient";
import { OptionType } from "../util";
import { capitalizeFirstLetter } from "../../util";
import { ActionModalSearchResults } from "./ActionModalSearchResults";
import { Pagination, PaginationProps } from "semantic-ui-react";
import { Mixpanel } from "../../common/MixPanel";
import { useHistory } from "react-router-dom";
import { DropdownOptionType } from "../../DeviceManagement/Devices/ActionModals/UpdateConfigModal";
import LoadingAnimation from "../../../common/Loader";
import { LatLng } from "../Inventory/DeviceConfigurations/GeofenceEditor";
import { useUser } from "../../../../context/User.context";
import ThemeSchema from "../../../../theme/schema";
import { beamtoast } from "../../../common/CustomToast";

type ActionExecutionSummaryModalProps = {
  uploadExecuted: string;
  optionType: OptionType;
  action: string;
  payloadType: string;
  filters: DeviceFilters;
  customFileInputData: string;
  customScriptInputData: string;
  customInputTextData: string;
  customInputJSONData: string;
  selectedFirmwareVersion: DropdownOptionType;
  selectedCustomFirmwareVersion: string;
  selectedConfigVersion: DropdownOptionType;
  selectedGeofenceVersion: DropdownOptionType;
  selectedCustomConfigVersion: string;
  selectedCustomGeofenceVersion: string;
  geofenceConfigs: {
    [key: string]: any;
  };
  setGeofenceConfigs: (arg0: { [key: string]: any }) => void;
  JSONConfigs: {
    [key: string]: any;
  };
  setJSONConfigs: (arg0: { [key: string]: any }) => void;
  path: LatLng[];
  alertThreshold: number;
  setDisableSubmitButton: (arg0: boolean) => void;
  setDisableBackButton: (arg0: boolean) => void;
  setLoading: (arg0: boolean) => void;
  onClose: () => void;
  // fetchAllActions: () => void;
  selectedDevices: SearchDeviceResponse;
  setSelectedDevices: (arg0: SearchDeviceResponse) => void;
  allSelected: boolean;
  deviceCount: number;
  metadataKeysToShow: string[];
};

export default function ActionExecutionSummaryModal(
  props: ActionExecutionSummaryModalProps
) {
  let abortController = new AbortController();

  const { user } = useUser();
  const theme = user?.settings?.theme;

  const history = useHistory();

  const [devices, setDevices] = useState<SearchDeviceResponse>();
  const [loading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const pageLimit: number = 5;

  const getStringifiedDeviceIDs = (deviceIDArr) => {
    let arr: string[] = [];
    deviceIDArr.map((deviceID: Device) => arr.push(String(deviceID.id)));
    return arr;
  };

  const triggerAction = async (actionName, params = {}) => {
    beamtoast.info(`Triggering action: ${actionName}`);
    props.setLoading(true);
    props.setDisableSubmitButton(true);
    props.setDisableBackButton(true);

    let deviceArray: SearchDeviceResponse = { devices: [], count: 0 };
    let body = {};
    let deviceIDs: string[] = [];
    let searchType: string = "";
    let searchKey: string = "";
    let searchQuery: string = "";
    let metadata = {};

    // if the "all" checkbox has been ticked
    if (props.allSelected) {
      // if filters have also been selected while the "all" checkbox is ticked
      if (Object.keys(props.filters).length !== 0) {
        searchType = "allSearch";
        // case when "all" checkbox is ticked with filters being ONLY "id" and nothing else
        // this is done as setting "searchQuery" as "id" fails the api therefore this is switched to "default" type
        // and IDs are added to the "device_ids" param
        if (
          Object.keys(props.filters)[0] === "id" &&
          Object.keys(props.filters).length === 1
        ) {
          searchType = "default";
          deviceArray = props.selectedDevices;
          deviceIDs = getStringifiedDeviceIDs([
            ...Array.from(deviceArray.devices),
          ]);
          // case when "all" checkbox is ticked with filters INCLUDING "id" with other filters
          // this aligns with the previous case but as other filters are also added, the first filter being "id" fails the api
          // as that is the one which gets set as the "searchQuery" param therefore we DO NOT switch this to "default" type but
          // set the second (any other apart from searchKey ids work) filter as the "searchKey" param
        } else if (
          Object.keys(props.filters)[0] === "id" &&
          Object.keys(props.filters).length !== 1
        ) {
          searchKey = Object.keys(props.filters)[1];
          searchQuery = Object.values(props.filters)[1][0];

          Object.keys(props.filters).forEach((key) => {
            metadata[key] = props.filters[key];
          });
          // normal case with "all" checkbox being ticked and filters also being used
        } else {
          searchKey = Object.keys(props.filters)[0];
          searchQuery = Object.values(props.filters)[0][0];

          Object.keys(props.filters).forEach((key) => {
            metadata[key] = props.filters[key];
          });
        }
        // case when only "all" checkbox is ticked and no filters are being used (triggers action on all devices)
      } else searchType = "all";
    } else {
      // case when "all" checkbox is not ticked
      // here we simply take the ids being ticked as an array and set them as "device_ids" param irrespective of the filters being used
      searchType = "default";
      deviceArray = props.selectedDevices;
      deviceIDs = getStringifiedDeviceIDs([...Array.from(deviceArray.devices)]);
    }

    try {
      body = {
        device_ids: deviceIDs,
        search_type: searchType,
        search_key: searchKey,
        search_query: searchQuery,
        metadata,
        action: actionName,
        params,
      };
      await triggerDeviceAction(body)
        .then(() => {
          beamtoast.success(`${actionName} triggered successfully!`);
          Mixpanel.track("Triggered Action", {
            action: actionName,
          });
          props.setDisableSubmitButton(false);
          props.setDisableBackButton(false);
          props.onClose();
          props.setLoading(false);
          // props.fetchAllActions();
        })
        .catch((e) => {
          console.log(e);
          beamtoast.error(`Error in triggering action: ${actionName}`);
          props.setDisableSubmitButton(false);
          props.setDisableBackButton(false);
          props.onClose();
          props.setLoading(false);
          history.push(`/actions/action-summary`);
        });
    } catch (e) {
      console.log(e);
      Mixpanel.track("Failure", {
        type: `trigger action ${actionName}`,
        error: JSON.stringify(e),
      });
      beamtoast.error(`Error in triggering action: ${actionName}`);
      props.setDisableSubmitButton(false);
      props.setDisableBackButton(false);
      props.onClose();
      props.setLoading(false);
      history.push(`/actions/action-summary`);
    }
  };

  const callGeofenceConfigAPI = () => {
    const configJson = {
      type: "polygon",
      path: props.path,
      alert_interval: props.alertThreshold.toString(),
    };
    return createDeviceConfiguration(
      props.selectedCustomGeofenceVersion,
      configJson,
      "update_geofence"
    );
  };

  const uploadAndTriggerGeofence = async () => {
    beamtoast.info(
      `uploading geofence config ${props.selectedCustomGeofenceVersion}...`
    );
    props.setDisableSubmitButton(true);
    props.setDisableBackButton(true);
    try {
      const newGeofenceConfig = await callGeofenceConfigAPI();
      beamtoast.success(
        `Created Geofence Configuration ${newGeofenceConfig.version_name}`
      );

      Mixpanel.track("Created Configuration", {
        Config: newGeofenceConfig.version_name,
      });

      const configJson = {
        type: "polygon",
        path: props.path,
        alert_interval: props.alertThreshold.toString(),
      };

      triggerAction(
        "update_geofence",
        Object.assign(configJson, {
          version: props.selectedCustomGeofenceVersion,
        })
      );
    } catch (e) {
      Mixpanel.track("Failure", {
        type: "Upload Configuration",
        error: JSON.stringify(e),
      });
      console.log(e);
    }
  };

  const uploadAndTriggerConfig = async () => {
    beamtoast.info(`uploading config ${props.selectedCustomConfigVersion}...`);
    props.setDisableSubmitButton(true);
    props.setDisableBackButton(true);
    try {
      const configJson = JSON.parse(props.customInputJSONData);

      const newConfig = await createDeviceConfiguration(
        props.selectedCustomConfigVersion,
        configJson,
        "update_config"
      );
      beamtoast.success("Created Configuration " + newConfig.version_name);

      Mixpanel.track("Created Configuration", {
        Config: newConfig.version_name,
      });

      triggerAction("update_config", {
        version: props.selectedCustomConfigVersion,
      });
    } catch (e) {
      Mixpanel.track("Failure", {
        type: "Upload Configuration",
        error: JSON.stringify(e),
      });
      console.log(e);
    }
  };

  const uploadConfig = () => {
    let payload = {};
    try {
      payload = JSON.parse(props.customInputJSONData);
      triggerAction(props.action, payload);
    } catch (e) {
      beamtoast.error("Please enter correct JSON payload.");
      console.log("Error parsing JSON payload", e);
    }
  };

  const uploadText = () => {
    try {
      triggerAction(props.action, props.customInputTextData); // directly use chosenJSON which is the text in this case
    } catch (e) {
      beamtoast.error("Please enter valid text.");
      console.log("Error triggering action", e);
    }
  };

  const paginateDevices = async (pageNumber: number) => {
    abortController.abort();
    abortController = new AbortController();
    const status = "active"; // for now only showing active as we don't think actions are triggered on inactive devices
    await searchDevices(
      props.filters,
      pageNumber,
      pageLimit,
      status,
      abortController.signal
    ).then((devices) => {
      setDevices(devices);
      setLoading(false);
    });
  };

  const onPageChange = (event: MouseEvent, data: PaginationProps) => {
    const activePage = data.activePage as number;
    setLoading(true);
    setPage(activePage);
    if (!props.allSelected) {
      let array: Device[] = props.selectedDevices.devices;
      array = array.slice((activePage - 1) * pageLimit, activePage * pageLimit);
      setDevices({ devices: array, count: props.selectedDevices.count });
      setLoading(false);
    } else paginateDevices(activePage);
  };

  useEffect(() => {
    if (!props.allSelected) {
      let array: Device[] = props.selectedDevices.devices;
      array = array.slice(0, pageLimit);
      setDevices({ devices: array, count: props.selectedDevices.count });
      setLoading(false);
    } else {
      paginateDevices(1);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props.uploadExecuted === "execute") {
      switch (props.optionType) {
        case OptionType.ChooseFirmware:
          triggerAction("update_firmware", {
            version: props.selectedFirmwareVersion.value,
          });
          break;

        case OptionType.UploadFirmware:
          triggerAction("update_firmware", {
            version: props.selectedCustomFirmwareVersion,
          });
          break;

        case OptionType.ChooseConfig:
          triggerAction("update_config", {
            version: props.selectedConfigVersion.value,
          });
          break;

        case OptionType.UploadConfig:
          uploadAndTriggerConfig();
          break;

        case OptionType.UploadCommonConfig:
          uploadConfig();
          break;

        case OptionType.SendFile:
          triggerAction("send_file", {
            id: props.customFileInputData, // chosen version is set as uploadedFileResponse.data.id
          });
          break;

        case OptionType.SendScript:
          triggerAction("send_script", {
            id: props.customScriptInputData, // chosen version is set as uploadedFileResponse.data.id
          });
          break;

        case OptionType.UploadText:
          uploadText();
          break;

        case OptionType.NoPayloadOption:
          triggerAction(props.action, {});
          break;

        case OptionType.ChooseGeofence:
          triggerAction(
            "update_geofence",
            Object.assign(
              { version: props.selectedGeofenceVersion.value },
              props.geofenceConfigs[props.selectedGeofenceVersion.value][
                "config_json"
              ]
            )
          );
          break;

        case OptionType.UploadGeofence:
          uploadAndTriggerGeofence();
          break;

        default:
          break;
      }
    }
  }, [props.uploadExecuted]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div style={{ display: "flex" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "70%",
        }}
      >
        {loading && (
          <LoadingAnimation
            loaderContainerHeight="350px"
            loadingText={`Loading devices`}
            fontSize="20px"
          />
        )}
        {!loading && devices && (
          <>
            <p style={{ fontSize: "1.1rem", fontWeight: "bold" }}>
              Selected Devices
            </p>
            <ActionModalSearchResults
              devices={devices}
              checkEnabled={false}
              metadataKeysToShow={props.metadataKeysToShow}
            />
            <div style={{ marginTop: "1rem" }}>
              <Pagination
                boundaryRange={0}
                defaultActivePage={page}
                ellipsisItem={null}
                siblingRange={2}
                totalPages={Math.ceil(props.selectedDevices.count / pageLimit)}
                onPageChange={onPageChange}
              />
            </div>
          </>
        )}
      </div>
      <div
        style={{
          border: `1px solid ${
            ThemeSchema.data[theme ?? "dark"]?.colors["table-outer-border"]
          }`,
          padding: "1rem",
          marginLeft: "2rem",
          width: "30%",
          height: "100%",
        }}
      >
        <p
          style={{
            fontSize: "1.1rem",
          }}
        >
          Trigger action: <b>{capitalizeFirstLetter(props.action)}</b>
        </p>
        <p
          style={{
            fontSize: "1.1rem",
          }}
        >
          Payload type: <b>{props.payloadType}</b>
        </p>
        <p
          style={{
            fontSize: "1.1rem",
          }}
        >
          Triggering action on:{" "}
          <b>
            {props.selectedDevices.count}{" "}
            {props.selectedDevices.count === 1 ? "device" : "devices"}
          </b>
        </p>
      </div>
    </div>
  );
}
