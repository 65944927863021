import React, {
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import LoadingAnimation from "../../../common/Loader";
import styled from "styled-components";
import { StyledHeader } from "../ActionsV3/SelectableItem";
import LiveActionsList from "./LiveActionsList";
import { MenuItem, PaginationProps } from "semantic-ui-react";
import { SelectDevicesPerPage } from "../../DeviceManagement/Devices/Devices";
import { validateWholeNumber } from "../../../../util";

import DeviceV3Overview from "./DeviceV3Overview";
import {
  ActionStatusResponse,
  ActionStatusType,
  devicesPerPageOptions,
  fetchAction,
  fetchAllActionStatus,
  fetchAllUsers,
} from "../../../../BytebeamClient";
import DeviceListView from "./DeviceListView";
import { RouteComponentProps, useLocation, withRouter } from "react-router-dom";
import {
  StyledCardSearchPageInput,
  StyledPagination,
  StyledSecondaryDevicePerPageWidget,
} from "../../../common/commonStyledComps";
import { ErrorMessage } from "../../../common/ErrorMessage";
import Toggle from "../../../common/Toggle";
import { beamtoast } from "../../../common/CustomToast";
import {
  CardContainer,
  FlexContainer,
  NewActionContainer,
} from "../../../common/ActionsUtils";
import ActionsSummarySection from "./ActionsSummarySection";

const _ = require("lodash");

interface LiveActionsProps extends RouteComponentProps {
  readonly setDoParamsExist: (arg0: boolean) => void;
  readonly setActionIDParam: (arg0: any) => void;
  readonly setActiveItem: (arg: string) => void;
}

const NewActionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

function LiveActions(props: LiveActionsProps) {
  const [mainLoading, setMainLoading] = useState<boolean>(true);
  const [selectedPhase, setSelectedPhase] = useState<string>("all");

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [actionIDParam, setActionIDParam] = useState<any>(
    searchParams.get("action_id")
  );
  const [deviceIDParam, setDeviceIDParam] = useState<any>(
    searchParams.get("device_id")
  );
  const [showIncompleteActions, setShowIncompleteActions] =
    useState<boolean>(false);

  const [openAddPhase, setOpenAddPhase] = useState<boolean>(false);

  // Flag state to track the operations like pagination and actions per page change
  const operationInProgressRef = useRef(false);
  const controllerRef = useRef(new AbortController());
  const recreateAbortController = () => {
    controllerRef.current?.abort?.(); // Abort any ongoing operation
    controllerRef.current = new AbortController(); // Create a new controller for the next operation
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    if (searchParams.get("action_id")) {
      setActionIDParam(searchParams.get("action_id"));
      props.setActionIDParam(searchParams.get("action_id"));
    } else {
      setActionIDParam(null);
      props.setActionIDParam(searchParams.get("action_id"));
    }

    if (searchParams.get("device_id"))
      setDeviceIDParam(searchParams.get("device_id"));
    else setDeviceIDParam(null);

    if (searchParams.get("action_id") && searchParams.get("device_id"))
      props.setDoParamsExist(true);
    else props.setDoParamsExist(false);

    if (window.location.search) {
      const searchParams = new URLSearchParams(location.search);

      if (
        searchParams.get("action_id") &&
        searchParams.get("device_id") &&
        window.location.search.includes("action_id") &&
        !window.location.search.includes("device_id")
      ) {
        props.setDoParamsExist(false);
        setDeviceIDParam(null);
      }
    }
  }, [window.location.search]); // eslint-disable-line react-hooks/exhaustive-deps

  // -------------------- Action data fetching logic start ---------------------- //

  const [filteredData, setFilteredData] = useState(Array<ActionStatusType>());
  const [users, setUsers] = useState<{ name: string; email: string }[]>([]);
  const [liveActionsListLoading, setLiveActionsListLoading] =
    useState<boolean>(true);
  const [totalActionsForPagination, setTotalActionsForPagination] =
    useState<number>(0);

  const [actionsPageNumber, setActionsPageNumber] = useState(1);
  const [actionsPageLimit, setActionsPageLimit] = useState(0);

  const [inputPageNumber, setInputPageNumber] = useState(0);

  const fetchAllOfTheUsers = async () => {
    const res = await fetchAllUsers();
    let userArray: { name: string; email: string }[] = [];
    Object.values(res.result).forEach((user) =>
      userArray.push({ name: user.name, email: user.email })
    );
    setUsers(userArray);
  };

  const handlePaginationInputChange = (event) => {
    const newValue = event.target.value;

    setInputPageNumber(newValue);
  };

  const getInputActivePage = (newValue: number) => {
    let pageNumber =
      newValue > 0
        ? newValue > Math.ceil(totalActionsForPagination / actionsPageLimit)
          ? Math.ceil(totalActionsForPagination / actionsPageLimit)
          : newValue
        : 1;

    return pageNumber;
  };

  const handlePaginationInputKeyDown = async (event) => {
    if (event.key === "Enter" || event.keyCode === 13) {
      // If the pressed key is "Enter", trigger the function for changing active page
      if (validateWholeNumber(inputPageNumber?.toString())) {
        await onPaginationChange(event, {
          activePage: getInputActivePage(inputPageNumber),
          totalPages: Math.ceil(totalActionsForPagination / actionsPageLimit),
        });
        setInputPageNumber(0);
      } else {
        beamtoast.error("Please enter whole number for jump to page.");
      }
    }
  };

  const onPaginationChange = async (
    event: MouseEvent,
    data: PaginationProps
  ) => {
    const activePage = data.activePage as number;
    setLiveActionsListLoading(true);
    operationInProgressRef.current = true; // Setting the flag to indicate pagination operation is in progress

    try {
      setActionsPageNumber(activePage);

      recreateAbortController();
      await fetchPaginatedActions(
        activePage,
        actionsPageLimit,
        controllerRef.current.signal
      );
    } catch (e) {
      beamtoast.error("Failed to change page");
      console.error("Error in changeDeviceStatus: ", e);
    } finally {
      setLiveActionsListLoading(false);
      operationInProgressRef.current = false; // Reset flag when operation is complete
    }
  };

  const handleSortByIncompleteActionsToggle = () => {
    setLiveActionsListLoading(true);
    setActionsPageNumber(1);
    setShowIncompleteActions(!showIncompleteActions);
  };

  const fetchPaginatedActions = async (
    pageNumber: number,
    pageLimit: number,
    signal: AbortSignal
  ) => {
    try {
      const res: ActionStatusResponse = await fetchAllActionStatus(
        pageNumber,
        pageLimit,
        showIncompleteActions,
        signal
      );
      setTotalActionsForPagination(res.count);
      let sortedActionList = [];

      // If show incomplete actions is true, need not to order the actions by created_at date
      sortedActionList = showIncompleteActions
        ? res.actions
        : _.orderBy(res.actions, "created_at", "desc");
      setFilteredData(sortedActionList);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchAllActions = async (count, signal, activePageNumber) => {
    try {
      await fetchPaginatedActions(activePageNumber, count ?? 10, signal);
    } catch (e) {
      console.log(e);
    } finally {
      if (!operationInProgressRef.current) {
        setMainLoading(false);
        setLiveActionsListLoading(false);
      } // To Avoid setting main loading to false when pagination call cancel this call
    }
  };

  const changeActionsPerPage = useCallback(
    async (e, data) => {
      operationInProgressRef.current = true; // Setting the flag to indicate device per page change operation is in progress

      try {
        setLiveActionsListLoading(true);
        setActionsPageLimit(data.value);
        setActionsPageNumber(1);

        window.localStorage.setItem("actionsPerPage", data.value);

        recreateAbortController();
        await fetchAllActions(data.value, controllerRef.current?.signal, 1);
      } catch (e) {
        beamtoast.error("Failed to change number of devices per page");
        console.error("Error in changeDeviceStatus: ", e);
      } finally {
        operationInProgressRef.current = false; // Resetting flag when operation is complete
      }
    },
    [actionsPageLimit, actionsPageNumber] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    document.title = "Live Actions | Bytebeam";
    const actionsCountPerPage: number = parseInt(
      window.localStorage.getItem("actionsPerPage") ?? "10"
    );
    setActionsPageLimit(actionsCountPerPage);

    let actionsCount: number =
      actionsPageLimit !== 0 ? actionsPageLimit : actionsCountPerPage;

    let isMounted: boolean = true;
    let timeoutId: NodeJS.Timeout | null = null;

    const fetchActions = async () => {
      if (!isMounted || actionIDParam !== null || deviceIDParam !== null)
        return;
      try {
        if (!operationInProgressRef.current && !openAddPhase) {
          await fetchAllActions(
            actionsCount,
            controllerRef.current?.signal,
            actionsPageNumber
          );
        }
      } catch (error) {
        console.log(error);
      } finally {
        if (isMounted) {
          timeoutId = setTimeout(() => {
            fetchActions();
          }, 2 * 1000);
        }
      }
    };

    fetchActions();

    return () => {
      isMounted = false;
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    actionsPageNumber,
    actionsPageLimit,
    showIncompleteActions,
    actionIDParam,
    deviceIDParam,
    openAddPhase,
  ]);

  useEffect(() => {
    fetchAllOfTheUsers();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // -------------------- Action data fetching logic ends ---------------------- //

  const [selectedAction, setSelectedAction] = useState<ActionStatusType>(
    filteredData[0]
  );

  const DeviceListViewRef = useRef<{
    handleRefetchDeviceStatusDetails: () => void;
  }>(null);

  /**
   * Triggers the refetch of device status details in the DeviceListView component.
   */
  const refetchDeviceStatusDetails = () => {
    if (DeviceListViewRef.current) {
      DeviceListViewRef.current.handleRefetchDeviceStatusDetails();
    }
  };

  const fetchActionData = async (actionID?: number) => {
    await fetchAction(actionID ?? Number(actionIDParam))
      .then((data) => {
        setSelectedAction(data);
        setMainLoading(false);
        setLiveActionsListLoading(false);
      })
      .catch((e) => console.log(e));
  };

  /**
   * Converts the filtered data array into a sorted and stringified dependency array.
   * The dependency array contains the action IDs of the filtered data.
   *
   * @param filteredData - The array of filtered data.
   * @returns The stringified dependency array.
   */
  const dependencyArray = JSON.stringify(
    filteredData.map((action) => action?.action_id).sort((a, b) => b - a)
  );

  /**
   * This useEffect runs whenever the dependency array (stringified array of sorted action IDs) changes.
   * If actionIDParam is present, it fetches action data by calling fetchActionData.
   * If actionIDParam is absent, it sets the selected action to the first element in filteredData and sets the selected phase to "all".
   */
  useEffect(() => {
    if (actionIDParam) {
      fetchActionData();
    } else {
      setSelectedAction(filteredData[0]);
      setSelectedPhase("all");
    }
  }, [dependencyArray]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    props.setActiveItem("live actions");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (mainLoading && !(deviceIDParam && actionIDParam))
    return (
      <LoadingAnimation
        loadingText="Loading Live Actions"
        fontSize="20px"
        loaderContainerHeight="70vh"
      />
    );
  else if (
    !mainLoading &&
    !(deviceIDParam || actionIDParam) &&
    filteredData?.length === 0
  ) {
    return (
      <div
        style={{
          height: "65vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ErrorMessage
          marginTop="30px"
          message="Please trigger an Action to view its progress here."
        />
      </div>
    );
  } else {
    return (
      <>
        {deviceIDParam && actionIDParam ? (
          <NewActionContainer>
            <DeviceV3Overview
              deviceIDParam={deviceIDParam}
              actionIDParam={actionIDParam}
            />
          </NewActionContainer>
        ) : selectedAction?.action_id ? (
          <>
            <ActionsSummarySection
              actionIDParam={actionIDParam}
              deviceIDParam={deviceIDParam}
              selectedAction={selectedAction}
              selectedPhase={selectedPhase}
              setSelectedPhase={setSelectedPhase}
              setMainLoading={setMainLoading}
              openAddPhase={openAddPhase}
              setOpenAddPhase={setOpenAddPhase}
              fetchActionData={fetchActionData}
              refetchDeviceStatusDetails={refetchDeviceStatusDetails}
            />

            {actionIDParam ? (
              <DeviceListView
                ref={DeviceListViewRef}
                actionID={actionIDParam}
                actionsData={selectedAction}
                setSelectedAction={setSelectedAction}
                selectedPhase={selectedPhase}
              />
            ) : (
              <NewActionContainer>
                <NewActionWrapper>
                  {filteredData?.length !== 0 ? (
                    <CardContainer>
                      <StyledHeader as="h2">Actions List</StyledHeader>
                      <FlexContainer
                        style={{
                          marginBottom: "30px",
                          justifyContent: "flex-end",
                        }}
                      >
                        {filteredData?.length > 0 && (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "16px",
                            }}
                          >
                            <div>Sort by Incomplete Actions</div>
                            <div>
                              <Toggle
                                id="sortByIncompleteActions"
                                checked={showIncompleteActions}
                                onChange={() => {
                                  handleSortByIncompleteActionsToggle();
                                }}
                                style={{
                                  top: "4px",
                                }}
                              />
                            </div>
                          </div>
                        )}
                        {/* <StyledNonBoldHeader as="h3" style={{ marginTop: "5px" }}>
                        Filter By
                      </StyledNonBoldHeader>

                      <SelectedFilter
                        name="Failed"
                        icon={
                          <PublishedWithChangesSVG
                            height="20px"
                            style={{ marginRight: "10px", marginTop: "5px" }}
                          />
                        }
                        onClick={() => {}}
                      />

                      <div style={{ position: "relative" }}>
                        <ActionButton
                          onClick={() => {
                            setShowFilterDropdown(true);
                          }}
                          label={"Add"}
                          selected={selectedItem === "not decided"}
                          marginLeft={"20px"}
                          padding={"0.5rem 1rem"}
                          icon={"plus"}
                        />

                        {showFilterDropdown && (
                          <ActionFilterDropdown
                            dropdownOptions={[
                              {
                                id: 1,
                                name: "check",
                                icon: (
                                  <PublishedWithChangesSVG
                                    height="20px"
                                    style={{
                                      marginRight: "10px",
                                      marginTop: "5px",
                                    }}
                                  />
                                ),
                              },
                            ]}
                            setShowFilterDropdown={(value) =>
                              setShowFilterDropdown(value)
                            }
                            addFilter={() => {}}
                          />
                        )}
                      </div> */}
                      </FlexContainer>

                      <LiveActionsList
                        users={users}
                        action={filteredData}
                        selectedAction={selectedAction}
                        setSelectedAction={setSelectedAction}
                        setSelectedPhase={setSelectedPhase}
                        liveActionsListLoading={liveActionsListLoading}
                      />

                      {!liveActionsListLoading && (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "nowrap",
                            gap: "16px",
                          }}
                        >
                          <StyledPagination
                            boundaryRange={0}
                            ellipsisItem={null}
                            siblingRange={2}
                            activePage={actionsPageNumber}
                            totalPages={Math.ceil(
                              totalActionsForPagination / actionsPageLimit
                            )}
                            onPageChange={onPaginationChange}
                          />

                          <StyledCardSearchPageInput
                            icon="search"
                            placeholder="Jump to page..."
                            name="activePage"
                            min={1}
                            onChange={handlePaginationInputChange}
                            onKeyDown={handlePaginationInputKeyDown}
                            type="number"
                            value={inputPageNumber ? inputPageNumber : ""}
                          />

                          <StyledSecondaryDevicePerPageWidget>
                            <MenuItem>Actions per page</MenuItem>
                            <MenuItem style={{ padding: "0px" }}>
                              <SelectDevicesPerPage
                                compact
                                selection
                                options={devicesPerPageOptions}
                                value={actionsPageLimit}
                                onChange={changeActionsPerPage}
                              />
                            </MenuItem>
                          </StyledSecondaryDevicePerPageWidget>
                        </div>
                      )}
                    </CardContainer>
                  ) : (
                    <div
                      style={{
                        height: "65vh",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <ErrorMessage
                        marginTop="30px"
                        message="Please trigger an Action to view its progress here."
                      />
                    </div>
                  )}
                </NewActionWrapper>
              </NewActionContainer>
            )}
          </>
        ) : (
          <LoadingAnimation
            loadingText="Loading Live Actions"
            fontSize="20px"
            loaderContainerHeight="70vh"
          />
        )}
      </>
    );
  }
}

export default withRouter(LiveActions);
