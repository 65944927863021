import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { Button } from "semantic-ui-react";
import Toggle from "../../../common/Toggle";
import {
  DeviceFilters,
  PendingDeviceActionsResponse,
  getPendingActionsOnDevices,
  triggerDeviceAction,
} from "../../../../BytebeamClient";
import { OptionType } from "../util";
import LoadingAnimation from "../../../common/Loader";
import { Mixpanel } from "../../common/MixPanel";
import { useHistory, useRouteMatch } from "react-router-dom";
import { StyledHeader } from "../ActionsV3/SelectableItem";
import SummaryComponent from "./SummaryComponent";
import PhaseControlSection from "./PhaseControlSection";
import CreateActionSection from "./CreateActionSection";
import AdvanceSettingsSection from "./AdvanceSettingsSection";
import { validateTimestampInterval } from "../../util";
import { beamtoast } from "../../../common/CustomToast";
import { CardContainer } from "../../../common/ActionsUtils";
import _ from "lodash";
import ActionWarningModal from "./ActionWarningModal";

export type PhaseInfo = {
  type: "fixed-list" | "filter-fraction-lazy" | "filter-fraction";
  device_ids?: number[];
  filter?: Record<string, string[]>;
  fraction?: number;
};

export type PhaseData = {
  id: number;
  name: string;
  trigger_on: {
    timestamp: Date | number;
  };
  info: PhaseInfo;
  status?: string;
  icon?: any;
};

type ScheduleData = {
  retry_on_failure_until: number | Date;
  release_notes: string;
  end_timestamp: Date | number;
  phases: Omit<PhaseData, "id">[];
};

type ActionRequestBody = {
  action: string;
  search_type: "default" | "all" | "allSearch";
  params: Record<string, any> | string;
  schedule?: ScheduleData;
  device_ids?: string[];
  search_key?: string;
  search_query?: string;
  metadata?: Record<string, string[]>;
};

export const CreateActionContainer = styled.div<{ marginBottom?: string }>`
  display: flex;
  width: 100%;
  margin-bottom: 16px;
`;

export const NewActionLabelContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const NewActionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledNonBoldHeader = styled(StyledHeader)`
  display: flex;
  align-items: center;
  margin-top: 0px;
  font-size: 18px;
  font-weight: normal;
  white-space: nowrap;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledButtons = styled(Button)`
  width: 300px;
  white-space: nowrap;
`;

export default function NewAction() {
  const history = useHistory();
  const matchedPath = useRouteMatch("/projects/:tenant/actions");
  const currentDateTime = new Date();
  // Setting never timeout to Thu Apr 01 2100 00:00:00 #BlameGautamBT
  const neverTimestamp = 4110201000000;

  const [mainLoading, setMainLoading] = useState<boolean>(false);

  // ------------------------- States for action creation START ------------------------- //
  const [actionName, setActionName] = useState<string>("");
  const [action, setAction] = useState<string>("");
  const [optionType, setOptionType] = useState<OptionType>(
    OptionType.NoOptionSelected
  );
  const [payload, setPayload] = useState<Record<string, any> | string>("");
  const [selectedVersion, setSelectedVersion] = useState<string>("");
  const [isWarningModalOpen, setIsWarningModalOpen] = useState<boolean>(false);
  // -------------------------- States for action creation END -------------------------- //

  // ------------------------- States for phased rollout START ------------------------- //
  const [isPhasedRollout, setIsPhasedRollout] = useState<boolean>(false);
  const [phasesData, setPhasesData] = useState<PhaseData[]>([
    {
      id: 1,
      name: "Phase I",
      trigger_on: {
        timestamp: new Date(currentDateTime.getTime() + 10 * 60 * 1000), // After 30 minutes
      },
      info: {
        type: "fixed-list", //other phase type are filter-fraction-lazy and filter-fraction
        device_ids: [],
      },
    },
  ]);
  const [retryUntil, setRetryUntil] = useState<Date | number>(0);
  const [releaseNotes, setReleaseNotes] = useState<string>("");
  const [endTimestamp, setEndTimestamp] = useState<Date | number>(
    neverTimestamp
  );

  const [pendingActionsOnDevices, setPendingActionsOnDevices] =
    useState<PendingDeviceActionsResponse>({});
  const [pendingActionsOnDevicesLoading, setPendingActionsOnDevicesLoading] =
    useState<boolean>(false);
  // -------------------------- States for phased rollout END -------------------------- //

  const handlePhasedRolloutToggleClick = useCallback(
    (e) => {
      setPhasesData([phasesData[0]]);
      setIsPhasedRollout(!isPhasedRollout);
    },
    [isPhasedRollout, phasesData]
  );

  const isDeviceSelectedInPhases = (phase: Omit<PhaseData, "id">) => {
    const { type, device_ids } = phase.info;

    if (type === "fixed-list" && device_ids && device_ids.length === 0) {
      beamtoast.error(
        `No devices selected${isPhasedRollout ? " for phase: " + phase.name : ""}`
      );
      return false;
    }

    return true;
  };

  const isDeviceSelectedBool = (body: ActionRequestBody) => {
    const isDeviceSelectedPhases = body?.schedule?.phases.every((phase) =>
      isDeviceSelectedInPhases(phase)
    );
    return isDeviceSelectedPhases;
  };

  const isDeviceRepeatedInPhases = (body) => {
    const phaseDeviceMapping: Record<string, number[]> = {};

    body?.schedule?.phases.forEach((phase, phaseIndex) => {
      if (phase.info.type === "fixed-list") {
        const deviceIds = phase.info.device_ids;
        deviceIds.forEach((deviceId) => {
          if (!phaseDeviceMapping[deviceId]) {
            phaseDeviceMapping[deviceId] = [];
          }
          phaseDeviceMapping[deviceId].push(phaseIndex + 1);
        });
      }
    });

    for (const phases of Object.values(phaseDeviceMapping)) {
      if (phases.length > 1) {
        beamtoast.error(
          `Device ID is repeated in phases: ${phases.join(", ")}`
        );
        return true;
      }
    }

    return false;
  };

  const createScheduleObject = () => {
    let scheduleObject: ScheduleData = {
      retry_on_failure_until: retryUntil,
      release_notes: releaseNotes,
      end_timestamp: endTimestamp,
      phases: phasesData,
    };

    scheduleObject.phases = phasesData.map((phase) => {
      const deepCopiedPhase: PhaseData = _.cloneDeep(phase);
      const { id, ...restPhaseData } = deepCopiedPhase;

      let phaseInfo = { ...restPhaseData.info };

      if (phaseInfo.type === "fixed-list") {
        restPhaseData.info = {
          type: phaseInfo.type,
          // @ts-ignore
          device_ids: phaseInfo?.device_ids
            ?.toString()
            .split(",")
            .filter(Boolean),
        };
      } else if (
        phaseInfo.type === "filter-fraction-lazy" ||
        phaseInfo.type === "filter-fraction"
      ) {
        restPhaseData.info = {
          type: phaseInfo.type,
          filter: phaseInfo.filter,
          fraction: phaseInfo.fraction,
        };
      }

      restPhaseData.trigger_on.timestamp =
        restPhaseData.trigger_on.timestamp instanceof Date
          ? restPhaseData.trigger_on.timestamp.getTime()
          : new Date(restPhaseData.trigger_on.timestamp).getTime();

      return restPhaseData;
    });

    return scheduleObject;
  };

  const fractionSumValidation = () => {
    const filterFractions = {};
    const errorPhases = {};
    phasesData.forEach((element) => {
      // Check if the type is 'fixed-list'

      if (
        element.info.type === "fixed-list" ||
        element.info.filter === undefined
      ) {
        return;
      }
      // Process the filter and fraction
      // sort the filter keys and values and making it array before stringify
      const sortedFilter = Object.entries(element.info.filter)
        .map(([key, value]) => [key, value.slice().sort()])
        .sort();
      const filterValue = JSON.stringify(sortedFilter);
      const fractionValue = element.info.fraction;

      // Update the sum of fractions for each filter
      if (filterFractions.hasOwnProperty(filterValue)) {
        filterFractions[filterValue] += fractionValue;
        errorPhases[filterValue].push(element.name);
      } else {
        filterFractions[filterValue] = fractionValue;
        errorPhases[filterValue] = [element.name];
      }
    });

    // Check if any filter's fractions do not sum to 100
    for (const filter in filterFractions) {
      if (filterFractions[filter] !== 100) {
        return { result: false, filter: filter, phases: errorPhases[filter] }; // Return the filter for which the sum is not 100
      }
    }

    return { result: true, filter: null, phases: null };
  };

  const createRequestBody = (
    actionType: string,
    params: Record<string, any> | string
  ) => {
    let requestBody: ActionRequestBody = {
      action: actionType,
      params,
      search_type: "default",
    };

    const scheduleObject = createScheduleObject();

    requestBody = {
      ...requestBody,
      schedule: scheduleObject,
    };
    return requestBody;
  };

  /**
   * Fetches the pending actions on devices for the selected devices.
   * @returns {Promise<boolean>} - A promise that resolves to true if there are pending actions on devices, else false.
   */
  async function areTherePendingActions() {
    let pendingActionData: PendingDeviceActionsResponse = {};
    setPendingActionsOnDevicesLoading(true);

    async function getPendingActionInfo(filter: DeviceFilters) {
      const response = await getPendingActionsOnDevices(filter);
      pendingActionData = { ...pendingActionData, ...response };
    }

    try {
      for (const phase of phasesData) {
        if (phase.info.type === "fixed-list") {
          await getPendingActionInfo({
            id: phase.info.device_ids?.map(String) ?? [],
          });
        } else {
          await getPendingActionInfo(phase.info.filter as DeviceFilters);
        }
      }
    } catch (error) {
      console.log("Error in fetching pending actions", error);
    } finally {
      setPendingActionsOnDevicesLoading(false);
      setPendingActionsOnDevices(pendingActionData);

      if (
        Object.values(pendingActionData)
          .flat()
          .filter(
            (action) =>
              action.type !== "cancel_action" && action.type !== "launch_shell"
          ).length > 0
      ) {
        return true;
      } else {
        return false;
      }
    }
  }

  const triggerAction = async (
    actionType: string,
    params: Record<string, any> | string = {},
    checkPendingActions: boolean = true
  ) => {
    const allValidIntervals = phasesData.every((phase) =>
      validateTimestampInterval(
        phase.trigger_on.timestamp,
        phase.name,
        phasesData
      )
    );
    const firstPhaseTriggerTime =
      phasesData.length > 1 && phasesData[0].trigger_on.timestamp;

    const body = createRequestBody(actionType, params);

    if (!isDeviceSelectedBool(body)) {
      return; // Validation message is been triggered through isDeviceSelectedBool/isDeviceSelectedInPhases function
    } else if (isDeviceRepeatedInPhases(body)) {
      return; // Validation message is been triggered through isDeviceRepeatedBool/isDeviceRepeatedInPhases function
    } else if (!allValidIntervals) {
      if (
        (phasesData.length > 1 && firstPhaseTriggerTime < new Date()) ||
        phasesData.length === 1
      ) {
        beamtoast.error(
          `The trigger time should be a minimum of 5 minutes later than the current time.`
        );
      } else if (phasesData.length > 1)
        beamtoast.error(
          `The time gap between consecutive phase's trigger time, should be a minimum of 5 minutes.`
        );
    } else if (!fractionSumValidation().result) {
      beamtoast.error(
        `The fraction of ${
          fractionSumValidation().phases ?? " same filters in phases "
        } should add upto 100%.`
      );
    } else {
      // Check if there are pending actions on devices, and gets a boolean value
      let areTherePendingActionsOnDevices = false;
      if (checkPendingActions) {
        areTherePendingActionsOnDevices = await areTherePendingActions();
      }

      if (areTherePendingActionsOnDevices && checkPendingActions) {
        setIsWarningModalOpen(true); // Warning modal will be opened if there are pending actions on devices.
      } else {
        setMainLoading(true);
        try {
          await triggerDeviceAction(body);
          beamtoast.success(`${actionType} triggered successfully!`);
          Mixpanel.track("Triggered Action", { action: actionType });
          history.push(`${matchedPath?.url}/live-actions`);
        } catch (error) {
          console.error(error);
          beamtoast.error(`Error in triggering action: ${actionType}`);
          Mixpanel.track("Failure", {
            type: `trigger action ${actionType}`,
            error: JSON.stringify(error),
          });
        } finally {
          setMainLoading(false);
        }
      }
    }
  };

  const executeAction = (checkPendingActions: boolean = true) => {
    if (action === "") {
      beamtoast.error(`Please select an action to trigger`);
    } else {
      switch (optionType) {
        case OptionType.ChooseFirmware:
          if (selectedVersion !== "")
            triggerAction(
              "update_firmware",
              {
                version: selectedVersion,
              },
              checkPendingActions
            );
          else beamtoast.error(`Please select a firmware version`);
          break;
        case OptionType.UploadFirmware:
          if (selectedVersion !== "")
            triggerAction(
              "update_firmware",
              {
                version: selectedVersion,
              },
              checkPendingActions
            );
          else beamtoast.error(`Please upload a firmware file`);
          break;

        case OptionType.ChooseConfig:
          if (selectedVersion !== "")
            triggerAction(
              "update_config",
              {
                version: selectedVersion,
              },
              checkPendingActions
            );
          else beamtoast.error(`Please select a JSON config version`);
          break;
        case OptionType.UploadConfig:
          if (selectedVersion !== "")
            triggerAction(
              "update_config",
              {
                version: selectedVersion,
              },
              checkPendingActions
            );
          else beamtoast.error(`Please create a JSON config version`);
          break;

        case OptionType.ChooseGeofence:
          if (selectedVersion !== "")
            triggerAction(
              "update_geofence",
              {
                version: selectedVersion,
              },
              checkPendingActions
            );
          else beamtoast.error(`Please select a GeoFence config version`);
          break;
        case OptionType.UploadGeofence:
          if (selectedVersion !== "")
            triggerAction(
              "update_geofence",
              {
                version: selectedVersion,
              },
              checkPendingActions
            );
          else beamtoast.error(`Please create a GeoFence config version`);
          break;

        case OptionType.SendFile:
          if (selectedVersion !== "")
            triggerAction(
              "send_file",
              {
                id: selectedVersion,
              },
              checkPendingActions
            );
          else beamtoast.error(`Please upload file`);
          break;
        case OptionType.SendScript:
          if (selectedVersion !== "")
            triggerAction(
              "send_script",
              {
                id: selectedVersion,
              },
              checkPendingActions
            );
          else beamtoast.error(`Please upload script`);
          break;

        case OptionType.UploadCommonConfig:
          if (payload !== "") {
            triggerAction(action, payload, checkPendingActions);
          } else beamtoast.error(`Please upload JSON payload`);
          break;
        case OptionType.UploadText:
          if (payload !== "") {
            triggerAction(action, payload, checkPendingActions);
          } else beamtoast.error(`Please upload text payload`);
          break;
        case OptionType.NoPayloadOption:
          triggerAction(action, {}, checkPendingActions);
          break;

        default:
          break;
      }
    }
  };

  return mainLoading ? (
    <LoadingAnimation
      loaderContainerHeight="80vh"
      loadingText="Loading..."
      fontSize="20px"
      loaderSize="48px"
    />
  ) : (
    <>
      <ActionWarningModal
        isWarningModalOpen={isWarningModalOpen}
        setIsWarningModalOpen={setIsWarningModalOpen}
        pendingActionsOnDevices={pendingActionsOnDevices}
        triggerAction={executeAction}
      />
      <CreateActionSection
        action={action}
        setAction={setAction}
        optionType={optionType}
        setOptionType={setOptionType}
        actionName={actionName}
        setActionName={setActionName}
        selectedVersion={selectedVersion}
        setSelectedVersion={setSelectedVersion}
        payload={payload}
        setPayload={setPayload}
      />

      <CreateActionContainer style={{ marginBottom: "2px" }}>
        <CardContainer>
          <NewActionWrapper>
            <StyledHeader
              as="h2"
              style={{ marginTop: "0px", marginBottom: "30px" }}
            >
              Select Devices
            </StyledHeader>
            <div
              style={{
                position: "relative",
                paddingLeft: "15px",
              }}
            >
              <NewActionLabelContainer style={{ marginBottom: "20px" }}>
                <StyledNonBoldHeader
                  as="h3"
                  style={{
                    marginBottom: "15px",
                  }}
                >
                  Phased Rollout
                </StyledNonBoldHeader>
                <div style={{ marginLeft: "40px", paddingBottom: "5px" }}>
                  <Toggle
                    id="action-toggle"
                    size="large"
                    bgcolor="#05DB0A"
                    checked={isPhasedRollout}
                    onChange={handlePhasedRolloutToggleClick}
                  />
                </div>
              </NewActionLabelContainer>
              <PhaseControlSection
                phasesData={phasesData}
                setPhasesData={setPhasesData}
                isPhasedRollout={isPhasedRollout}
                setIsPhasedRollout={setIsPhasedRollout}
              />
            </div>
          </NewActionWrapper>
        </CardContainer>
      </CreateActionContainer>

      <AdvanceSettingsSection
        endTimestamp={endTimestamp}
        releaseNotes={releaseNotes}
        action={action}
        setEndTimestamp={setEndTimestamp}
        setReleaseNotes={setReleaseNotes}
        setRetryUntil={setRetryUntil}
      />

      <CreateActionContainer>
        <CardContainer>
          <SummaryComponent
            phasesData={phasesData}
            isPhasedRollout={isPhasedRollout}
            action={action}
            optionType={optionType}
            selectedVersion={selectedVersion}
            actionName={actionName}
          />
        </CardContainer>
      </CreateActionContainer>

      <ButtonsContainer>
        <StyledButtons
          primary
          size="big"
          loading={pendingActionsOnDevicesLoading}
          onClick={() => {
            executeAction();
          }}
        >
          {"Trigger Action"}
        </StyledButtons>
      </ButtonsContainer>
    </>
  );
}
