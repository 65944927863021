import React, { SyntheticEvent, useEffect, useState } from "react";
import { DropdownProps, Icon, Popup } from "semantic-ui-react";
import UnitSelection from "./UnitSelection";
import { TwoItems } from "./ColumnType";
import { isNumericType } from "../../util";

export default function ColumnUnit({
  required,
  value,
  pendingUnit,
  status,
  columnName,
  onChange,
  permission,
  columnType,
}) {
  const [newUnit, setNewUnit] = useState(value);
  const [editMode, setEditMode] = useState(false);

  function handleOnChange(
    event: SyntheticEvent<HTMLElement, Event>,
    data: DropdownProps
  ): void {
    event.preventDefault();
    const { value } = data;
    setNewUnit(value);
  }

  useEffect(() => {
    if (!isNumericType(columnType) && editMode) {
      setNewUnit(null);
    }
  }, [columnType, editMode]);

  if (required) {
    return value;
  }

  if (status === "editing" && pendingUnit !== value) {
    return value + " -> " + pendingUnit;
  }

  if (!editMode) {
    let right = <></>;
    if (status === "ready" && permission) {
      right = (
        <Popup
          trigger={
            <Icon
              link
              name="edit"
              onClick={() => setEditMode(true)}
              disabled={
                !isNumericType(columnType) &&
                (newUnit === "None" || newUnit === null)
              }
            />
          }
          content="Units are exclusive to numeric type column!"
          position="top center"
          inverted
        />
      );
    }

    return <TwoItems left={value} right={right} />;
  } else {
    return (
      <TwoItems
        left={<UnitSelection value={newUnit} onChange={handleOnChange} />}
        right={
          <>
            <Icon
              link
              name="save"
              disabled={
                newUnit === value ||
                (!isNumericType(columnType) && newUnit !== null)
              }
              onClick={() => {
                setEditMode(false);
                onChange(newUnit);
              }}
            />
            <Icon
              link
              name="cancel"
              onClick={() => {
                setEditMode(false);
                setNewUnit(value);
              }}
            />
          </>
        }
      />
    );
  }
}
