import React, { Component } from "react";
import { Button, Modal, Icon } from "semantic-ui-react";
import { searchDevices } from "../../../../../BytebeamClient";
import { MessageContainer } from "./BulkMetadataUpdateModal";
import csvjson from "csvjson";
import { saveAs } from "file-saver";
import LoadingAnimation from "../../../../common/Loader";
import styled from "styled-components";

function replaceNullWithEmptyString(object) {
  for (let key in object) {
    if (object[key] === null) {
      object[key] = "";
    }
  }
}

type DownloadMetadataModalProps = {
  close: (...args: any[]) => any;
  searchKey: string;
  searchValue: string[];
};

type DownloadMetadataModalState = {
  buttonDisabled: boolean;
};

const CodeQueryText = styled.code`
  color: ${({ theme }) => theme.colors["code-query-text-color"]} !important;
  background: ${({ theme }) =>
    theme.colors["code-query-text-background-color"]} !important;
`;

export class DownloadFilteredMetadataModal extends Component<
  DownloadMetadataModalProps,
  DownloadMetadataModalState
> {
  constructor(props) {
    super(props);
    this.state = {
      buttonDisabled: false,
    };
  }

  async getDevicesMetadata(): Promise<string> {
    try {
      const status = "active";
      const deviceFilters = {};
      deviceFilters[this.props.searchKey] = this.props.searchValue;
      const devicesPerPage = 100000;
      const page = 1;

      // totalDevices is possible maximum number of devices (totalDevices = page * devicesPerPage)
      const { devices } = await searchDevices(
        deviceFilters,
        page,
        devicesPerPage,
        status,
        null
      );

      let metadata: any[] = [];

      for (const device of devices) {
        const object = {};
        object["id"] = device["id"];
        replaceNullWithEmptyString(device["metadata"]);
        object["Metadata"] = device["metadata"];
        metadata.push(object);
      }

      let csvData: string = csvjson.toCSV(metadata, { delimiter: "," });

      // To give a proper header to the csv file
      csvData = csvData.replace("[].", "");
      csvData = csvData.replaceAll("[].Metadata.", "");
      return csvData;
    } catch (error) {
      console.error("Error While Fetching all devices data: ", error);
      return "";
    }
  }

  async downloadSelectedDevicesMetadata() {
    try {
      const csvData: BlobPart = await this.getDevicesMetadata();
      this.setState({
        buttonDisabled: false,
      });

      const file = new File([csvData], "metadata.csv", {
        type: "text/csv",
      });
      saveAs(file);
    } catch (error) {
      console.log("Error in downloading metadata: ", error);
    }
  }

  async onClickDownloadMetadata() {
    this.setState({
      buttonDisabled: true,
    });
    await this.downloadSelectedDevicesMetadata();
    this.props.close();
  }

  render() {
    return (
      <>
        <Modal.Content>
          <Modal.Description>
            <MessageContainer>
              {this.state.buttonDisabled ? (
                <LoadingAnimation
                  loadingText="Downloading all devices metadata..."
                  loaderBorderSize="3px"
                  loaderSize="30px"
                  fontSize="14px"
                />
              ) : (
                <p>
                  Download metadata for filtered devices, based on search query:
                  <br></br>
                  <CodeQueryText id={"filter_query"} className="codeQueryText">
                    {`${this.props.searchKey} : ${this.props.searchValue}`}{" "}
                  </CodeQueryText>
                </p>
              )}
            </MessageContainer>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            secondary
            onClick={() => {
              this.props.close();
            }}
          >
            <Icon name="angle left" /> Back
          </Button>
          {/* disable the button unless parsing and fetching is done */}
          <Button
            primary
            disabled={this.state.buttonDisabled}
            onClick={this.onClickDownloadMetadata.bind(this)}
          >
            <Icon name="checkmark" /> Download Metadata
          </Button>
        </Modal.Actions>
      </>
    );
  }
}
