import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Checkbox, Input } from "semantic-ui-react";
import { DeviceFilters } from "../../../../BytebeamClient";
import { useOutsideClickHandler } from "../../../../hooks/useOutsideClickHandler";
import {
  List,
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
} from "react-virtualized";
import { ButtonIcon } from "../../util";
import { ButtonPosition } from "../NewAction/PhaseControlSection";

export interface ActionNestedDropdownOptions {
  label: string;
  children?: any[];
}

interface ActionFilterNestedDropdownProps {
  setShowDropdown: (arg0: boolean) => void;
  depth: number;
  onClick: (arg0: any, arg1: any) => void;
  nestedDropdownOptions: ActionNestedDropdownOptions[];
  filters: DeviceFilters;
  currentDepth: number;
  setCurrentDepth: (arg0: number) => void;
  parentButtonPosition?: ButtonPosition;
  search?: boolean;
}

const DropdownHeaderDiv = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 37px;
  position: sticky !important;
  top: 0px !important;
  z-index: 1 !important;
  margin-bottom: 0.3rem;
  border-bottom: 1px solid
    ${(props) => props.theme.colors["action-border-color"]} !important;
`;

const BackButton = styled(ButtonIcon)`
  position: relative;
  bottom: 2px;
  margin-right: 0 !important;
  margin-left: 5px !important;
`;

const DropdownSearchDiv = styled(Input)`
  width: 100%;
  border: none !important;
  background-color: ${(props) =>
    props.theme.colors["action-dropdown-color"]} !important;
  input {
    border: none !important;
    background-color: ${(props) =>
      props.theme.colors["action-dropdown-color"]} !important;
  }
`;

const DropdownWrapper = styled.div<{
  marginTopExist: boolean;
  buttonPosition?: ButtonPosition;
}>`
  z-index: 10;
  position: ${(props) => (props.buttonPosition ? "fixed" : "absolute")};
  background-color: ${(props) => props.theme.colors["action-dropdown-color"]};
  border: 1px solid ${(props) => props.theme.colors["action-border-color"]};
  border-radius: 8px;
  height: 300px;
  width: 210px;
  overflow: hidden;
  top: ${(props) =>
    props.buttonPosition
      ? `calc(${props.buttonPosition.top}px + ${props.buttonPosition.height}px + 0.5rem)`
      : "0.3rem"};
  left: ${(props) =>
    props.buttonPosition ? `${props.buttonPosition.left}px` : "0"};
  transition: all 0.15s ease-in;
  animation: fade-in 0.15s;
  padding: 0.3rem;
  padding-top: ${(props) => (props.marginTopExist ? "0.3rem" : "0")};

  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const DropdownItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  width: 100%;
  background-color: transparent;
  padding: 0.5rem 0.5rem;
  border-radius: 8px;
  transition: background-color 0.15s ease-in;
  &:hover {
    background-color: ${(props) =>
      props.theme.colors["action-border-color-hover"]};
  }
`;

export default function ActionFilterNestedDropdown(
  props: ActionFilterNestedDropdownProps
) {
  const wrapperRef = useRef(null);

  const primaryCache = React.useRef(
    new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 40,
    })
  );

  const secondaryCache = React.useRef(
    new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 40,
    })
  );

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filterDropdownOptions, setFilterDropdownOptions] = useState<
    ActionNestedDropdownOptions[]
  >([]);
  const [selectedMetadataKey, setSelectedMetadataKey] = useState<string>("");
  const [secondaryDropdownOptions, setSecondaryDropdownOptions] = useState<
    any[]
  >([]);
  const [secondaryDropdownDisplayOptions, setSecondaryDropdownDisplayOptions] =
    useState<any[]>([]);

  // used to close the dropdown if clicked outside it
  useOutsideClickHandler(wrapperRef, () => {
    const dropdownWrapper = document.getElementById("dropdown-wrapper");
    if (dropdownWrapper) dropdownWrapper.style.opacity = "0";
    setTimeout(() => {
      props.setShowDropdown(false);
      props.setCurrentDepth(0);
    }, 150);
  });

  // add the metadata value from filter list
  const selectMetadataValue = useCallback(
    (key: number, fromCheckbox: boolean) => {
      let optionKey: number = key;
      if (fromCheckbox) {
        // get the original key from the unfiltered list
        for (let option in secondaryDropdownOptions) {
          if (
            secondaryDropdownOptions[option] ===
            secondaryDropdownDisplayOptions[key]
          ) {
            optionKey = Number(option);
            break;
          }
        }
      }

      let newValues: string[] = props.filters[selectedMetadataKey]
        ? [
            ...props.filters[selectedMetadataKey],
            secondaryDropdownOptions[optionKey],
          ]
        : [secondaryDropdownOptions[optionKey]];

      let selectedFilterValues = props.filters;
      selectedFilterValues[selectedMetadataKey] = newValues;

      props.onClick(selectedMetadataKey, newValues);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      props.filters,
      selectedMetadataKey,
      secondaryDropdownOptions,
      secondaryDropdownDisplayOptions,
    ]
  );

  // remove the metadata value from filter list
  const removeMetadataValue = useCallback(
    (key: number, fromCheckbox: boolean) => {
      let optionKey: number = key;
      if (fromCheckbox) {
        // get the original key from the unfiltered list
        for (let option in secondaryDropdownOptions) {
          if (
            secondaryDropdownOptions[option] ===
            secondaryDropdownDisplayOptions[key]
          ) {
            optionKey = Number(option);
            break;
          }
        }
      }

      let newValues: string[] = [];
      // Only proceed if the value is defined and not undefined
      if (props.filters[selectedMetadataKey]) {
        newValues = props.filters[selectedMetadataKey].filter(
          (item) => item !== secondaryDropdownOptions[optionKey]
        );
      } else {
        // Handle the case where the value is undefined
        console.warn("Selected filter values or label is undefined.");
      }

      let selectedFilterValues = props.filters;
      if (newValues.length === 0)
        delete selectedFilterValues[selectedMetadataKey];
      else selectedFilterValues[selectedMetadataKey] = newValues;

      props.onClick(selectedMetadataKey, newValues);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      props.filters,
      selectedMetadataKey,
      secondaryDropdownOptions,
      secondaryDropdownDisplayOptions,
    ]
  );

  // checks whether a metadata value already exists in selected filter values or not
  const metadataValueExists = useCallback(
    (key: number) => {
      let valueExists = false;
      for (let filterKey in props.filters) {
        if (filterKey === selectedMetadataKey) {
          valueExists = props.filters[filterKey].includes(
            secondaryDropdownOptions[key]
          );
          break;
        }
      }
      return valueExists;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedMetadataKey, secondaryDropdownOptions, props.filters]
  );

  // main filter function which is triggered when a metadata value is selected
  const filterValues = useCallback(
    (index: number) => {
      let optionKey: number = 0;

      // get the original key from the unfiltered list
      for (let option in secondaryDropdownOptions) {
        if (
          secondaryDropdownOptions[option] ===
          secondaryDropdownDisplayOptions[index]
        ) {
          optionKey = Number(option);
          break;
        }
      }

      // check if the filter is already applied else remove the filter and vice versa
      if (!metadataValueExists(optionKey)) {
        selectMetadataValue(optionKey, false);
        props.setShowDropdown(false);
        props.setCurrentDepth(0);
      } else {
        removeMetadataValue(optionKey, false);
        props.setShowDropdown(false);
        props.setCurrentDepth(0);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      secondaryDropdownOptions,
      secondaryDropdownDisplayOptions,
      props.currentDepth,
      props.depth,
    ]
  );

  // checks whether a metadata value is already selected or not
  const isMetadataValueSelected = useCallback(
    (dropdownKey: number) => {
      let optionKey: number = 0;

      // get the original key from the unfiltered list
      for (let option in secondaryDropdownOptions) {
        if (
          secondaryDropdownOptions[option] ===
          secondaryDropdownDisplayOptions[dropdownKey]
        ) {
          optionKey = Number(option);
          break;
        }
      }

      for (let key in props.filters) {
        if (props.filters.hasOwnProperty(key)) {
          if (key === selectedMetadataKey) {
            return props.filters[key].some(
              (value) => value === secondaryDropdownOptions[optionKey]
            );
          }
        }
      }
    },
    [
      selectedMetadataKey,
      secondaryDropdownOptions,
      secondaryDropdownDisplayOptions,
      props.filters,
    ]
  );

  // triggered when a metadata key is selected
  const handleFilterKeySelect = (key: number) => {
    setSearchTerm("");
    setSelectedMetadataKey(filterDropdownOptions[key].label);
    let optionKey: number = 0;

    // get the original key from the unfiltered list
    for (let option in props.nestedDropdownOptions) {
      if (
        props.nestedDropdownOptions[option].label ===
        filterDropdownOptions[key].label
      ) {
        optionKey = Number(option);
        break;
      }
    }

    props.setCurrentDepth(props.currentDepth + 1);

    if (props.nestedDropdownOptions[optionKey]?.children !== undefined) {
      setSecondaryDropdownOptions(
        props.nestedDropdownOptions[optionKey].children!
      );
      setSecondaryDropdownDisplayOptions(
        props.nestedDropdownOptions[optionKey].children!
      );
    }
  };

  // case for filtering metadata values list (part of below function)
  const filterMetadataValueList = useCallback(
    (searchText: string) => {
      let filteredDropdownOptions = secondaryDropdownOptions.filter((option) =>
        option.toLowerCase().includes(searchText)
      );
      setSecondaryDropdownDisplayOptions(filteredDropdownOptions);
    },
    [secondaryDropdownOptions]
  );

  // dropdown list filter function triggered when search key is typed in the input field
  const filterList = (value: string) => {
    setSearchTerm(value);
    const searchText = value.toLowerCase();
    if (props.currentDepth === 0) {
      // case for metadata keys
      let dropdownOptions = props.nestedDropdownOptions.filter((option) =>
        option.label.toLowerCase().includes(searchText)
      );
      setFilterDropdownOptions(dropdownOptions);
    } else {
      // case for metadata values
      filterMetadataValueList(searchText);
    }
  };

  const goBack = (e: any) => {
    e.stopPropagation();
    setFilterDropdownOptions(props.nestedDropdownOptions);
    setSearchTerm("");
    props.setCurrentDepth(props.currentDepth - 1);
  };

  const handleKeyPress = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        // Handle Enter key press here
        // auto trigger selection of the only item in the list
        if (props.currentDepth === 0) {
          // depth level initial
          if (filterDropdownOptions.length === 1) handleFilterKeySelect(0);
        } else if (props.currentDepth === props.depth - 1) {
          // depth level final (for now only 2 levels so no loop)
          if (secondaryDropdownDisplayOptions.length === 1) filterValues(0);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      filterDropdownOptions,
      props.currentDepth,
      props.depth,
      secondaryDropdownDisplayOptions,
    ]
  );

  useEffect(() => {
    if (props.nestedDropdownOptions) {
      setFilterDropdownOptions(props.nestedDropdownOptions);
    }

    return () => {
      // reset dropdown state if it unmounts
      props.setShowDropdown(false);
      props.setCurrentDepth(0);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.nestedDropdownOptions]);

  return (
    <DropdownWrapper
      id="dropdown-wrapper"
      ref={wrapperRef}
      marginTopExist={!props.search && props.currentDepth !== props.depth - 1}
      buttonPosition={props.parentButtonPosition}
    >
      {!props.search && props.currentDepth === props.depth - 1 && (
        <DropdownHeaderDiv>
          <BackButton
            link
            name="arrow left"
            title="Go back"
            onClick={(e) => goBack(e)}
          />
        </DropdownHeaderDiv>
      )}

      {props.search && (
        <DropdownHeaderDiv>
          {props.currentDepth === props.depth - 1 && (
            <BackButton
              link
              name="arrow left"
              title="Go back"
              onClick={(e) => goBack(e)}
            />
          )}
          <DropdownSearchDiv
            id="search-action-filters-input"
            fluid
            autoFocus={true}
            icon="search"
            placeholder="Filter..."
            value={searchTerm}
            onKeyDown={handleKeyPress}
            onChange={(e) => filterList(e.currentTarget.value)}
          />
        </DropdownHeaderDiv>
      )}
      {props.currentDepth === 0 ? (
        filterDropdownOptions.length !== 0 ? (
          <div
            style={{
              width: "100%",
              height: `${!props.search && props.currentDepth !== props.depth - 1 ? "300px" : "260px"}`,
            }}
          >
            <AutoSizer>
              {({ width, height }) => (
                <List
                  width={width}
                  height={height}
                  rowHeight={primaryCache.current.rowHeight}
                  deferredMeasurementCache={primaryCache.current}
                  rowCount={filterDropdownOptions.length}
                  rowRenderer={({ key, index, style, parent }) => {
                    const option = filterDropdownOptions[index];

                    return (
                      <CellMeasurer
                        key={key}
                        cache={primaryCache.current}
                        parent={parent}
                        columnIndex={0}
                        rowIndex={index}
                      >
                        <DropdownItem
                          id={`metadata-name-${option.label}-${index}`}
                          style={style}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleFilterKeySelect(index);
                          }}
                        >
                          {option.label}
                        </DropdownItem>
                      </CellMeasurer>
                    );
                  }}
                />
              )}
            </AutoSizer>
          </div>
        ) : (
          <DropdownItem>No matching values</DropdownItem>
        )
      ) : props.currentDepth === 1 ? (
        secondaryDropdownDisplayOptions.length !== 0 ? (
          <div
            style={{
              width: "100%",
              height: "260px",
            }}
          >
            <AutoSizer>
              {({ width, height }) => (
                <List
                  width={width}
                  height={height}
                  rowHeight={secondaryCache.current.rowHeight}
                  deferredMeasurementCache={secondaryCache.current}
                  rowCount={secondaryDropdownDisplayOptions.length}
                  rowRenderer={({ key, index, style, parent }) => {
                    const option = secondaryDropdownDisplayOptions[index];

                    return (
                      <CellMeasurer
                        key={key}
                        cache={secondaryCache.current}
                        parent={parent}
                        columnIndex={0}
                        rowIndex={index}
                      >
                        <DropdownItem
                          id={`metadata-value-${option}-${index}`}
                          style={style}
                          onClick={() => filterValues(index)}
                        >
                          {props.currentDepth === props.depth - 1 && (
                            <Checkbox
                              id={`metadata-dropdown-value-${index}`}
                              fitted
                              style={{ marginRight: "1rem" }}
                              checked={isMetadataValueSelected(index)}
                              onClick={(e) => e.stopPropagation()}
                              onChange={(e, { checked }) => {
                                if (!checked) {
                                  removeMetadataValue(index, true);
                                } else {
                                  selectMetadataValue(index, true);
                                }
                              }}
                            />
                          )}
                          {option}
                        </DropdownItem>
                      </CellMeasurer>
                    );
                  }}
                />
              )}
            </AutoSizer>
          </div>
        ) : (
          <DropdownItem>No matching values</DropdownItem>
        )
      ) : (
        <DropdownItem>No matching values</DropdownItem>
      )}
    </DropdownWrapper>
  );
}
