import React, { Fragment, useEffect } from "react";
import { compose, withProps } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Polyline,
  TrafficLayer,
} from "react-google-maps";

import { GOOGLE_MAPS_API_KEY } from "../../../../../constants/index";
import { TrackDevicesMetaData, TrackDevicesData } from "./PanelDef";
import { PanelViewComponent } from "../PanelDef";
import { MarkerWithInfoWindow } from "../LocateDevices/ViewLocateDevices";
import { ReplayState } from "../../DashboardHeader";
import { darkTheme, lightTheme } from "./MapStyle";
import { DashboardsInfo } from "../../ViewDashboard";
import { Permission, Settings } from "../../../../../util";
import {
  DeviceConfigurationType,
  fetchAllDeviceConfiguration,
} from "../../../../../BytebeamClient";
import { useUser } from "../../../../../context/User.context";

type MapComponentProps = {
  devicesLocationData: TrackDevicesData;
  deviceDashboardId: string;
  replayTimestamp: number;
  replayStep: number;
  replayState: ReplayState;
  trafficLayerFlag: boolean;
  satelliteLayerFlag: boolean;
  allDashboards: DashboardsInfo[];
  settings: Settings;
  markerIcon: string;
};

const MapComponent = compose<MapComponentProps, MapComponentProps>(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?v=3.exp&key=" +
      GOOGLE_MAPS_API_KEY +
      "&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: "100%" }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)((props: MapComponentProps) => {
  const mapsRef = React.createRef<GoogleMap>();
  const [geofenceConfigs, setGeofenceConfigs] = React.useState<
    { [key: string]: DeviceConfigurationType } | undefined
  >(undefined);

  const { user } = useUser();
  const permissions: Permission = user?.role?.permissions ?? [];
  const theme = user?.settings?.theme ?? "dark";
  const mapTheme = theme === "light" ? lightTheme : darkTheme;

  async function getGeoFenceConfigs() {
    const configs = await fetchAllDeviceConfiguration();
    const geofenceConfigs = configs.filter(
      (config) => config.action_type === "update_geofence"
    );
    const groupedConfigs = {};

    geofenceConfigs.forEach((config) => {
      groupedConfigs[config.version_name] = config;
    });
    setGeofenceConfigs(groupedConfigs);
  }

  useEffect(() => {
    // Function will only invoke if the user has permission to view device configs
    if (permissions.viewDeviceConfigs && geofenceConfigs === undefined) {
      getGeoFenceConfigs();
    }
  }, [geofenceConfigs, permissions.viewDeviceConfigs]);

  useEffect(() => {
    const bounds = new google.maps.LatLngBounds();

    props.devicesLocationData.forEach((device) => {
      device.coordinates.forEach((c) => {
        bounds.extend(new google.maps.LatLng(c.latitude, c.longitude));
      });

      const geofenceConfig =
        device.state["geofence_version"] && geofenceConfigs
          ? geofenceConfigs[device.state["geofence_version"]]
          : undefined;

      if (geofenceConfig) {
        const path = geofenceConfig["config_json"]["path"];

        path.forEach((point) => {
          bounds.extend(new google.maps.LatLng(point["lat"], point["lng"]));
        });
      }
    });

    mapsRef.current?.fitBounds(bounds);
  }, [props.devicesLocationData, mapsRef, geofenceConfigs]);

  let defaultOptions = {
    streetViewControl: false,
    scaleControl: false,
    mapTypeControl: false,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    mapTypeControlOptions: {},
    panControl: true,
    zoomControl: false,
    rotateControl: true,
    fullscreenControl: false,
    styles: mapTheme,
  };

  if (props.satelliteLayerFlag) {
    // showing satellite layer
    defaultOptions = {
      streetViewControl: false,
      scaleControl: false,
      mapTypeControl: true,
      mapTypeId: google.maps.MapTypeId.SATELLITE,
      mapTypeControlOptions: {
        style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: google.maps.ControlPosition.BOTTOM_CENTER,
        mapTypeIds: [
          google.maps.MapTypeId.ROADMAP,
          google.maps.MapTypeId.SATELLITE,
          google.maps.MapTypeId.HYBRID,
        ],
      },
      panControl: true,
      zoomControl: false,
      rotateControl: true,
      fullscreenControl: false,
      styles: mapTheme,
    };
  }

  return (
    // @ts-ignore
    <GoogleMap
      ref={mapsRef}
      defaultZoom={17}
      defaultCenter={{ lat: 12.927381, lng: 77.637729 }}
      options={defaultOptions}
    >
      {props.trafficLayerFlag && <TrafficLayer />}

      {props.devicesLocationData.map((device) => {
        const coordinates = device.coordinates;
        const path = coordinates.map(
          (c) => new google.maps.LatLng(c.latitude, c.longitude)
        );
        // const color = mapColors[parseInt(device.id) % mapColors.length];
        const color = "#238cfe";

        let coordIndex = coordinates.length - 1;

        if (
          props.replayState === ReplayState.ReplayRunning ||
          props.replayState === ReplayState.ReplayPaused
        ) {
          coordIndex = props.replayStep;

          if (coordIndex > coordinates.length - 1) {
            coordIndex = coordinates.length - 1;
          }
        }

        const currentLocation = coordinates[coordIndex];
        const geofenceConfig =
          device?.state["geofence_version"] && geofenceConfigs
            ? geofenceConfigs[device.state["geofence_version"]]
            : undefined;

        return (
          <Fragment key={device.id}>
            <Polyline
              path={path}
              options={{
                strokeColor: color,
                geodesic: true,
                strokeWeight: 4,
              }}
            />

            <MarkerWithInfoWindow
              deviceId={device.id}
              coordinates={currentLocation}
              deviceDashboardIds={[props.deviceDashboardId]}
              color={color}
              metadata={device.metadata}
              state={device.state}
              settings={props.settings}
              allDashboards={props.allDashboards}
              markerIcon={props.markerIcon}
              geofenceConfig={geofenceConfig}
              alerts={device.alerts || []}
            />
          </Fragment>
        );
      })}
    </GoogleMap>
  );
});

export class ViewTrackDevices extends PanelViewComponent<
  TrackDevicesMetaData,
  TrackDevicesData
> {
  render() {
    return (
      <MapComponent
        devicesLocationData={this.props.data}
        deviceDashboardId={this.props.panelMeta.device_dashboard_id}
        trafficLayerFlag={this.props.panelMeta.trafficLayerFlag}
        satelliteLayerFlag={this.props.panelMeta.satelliteLayerFlag}
        replayTimestamp={this.props.replayStep}
        replayState={this.props.replayState}
        replayStep={this.props.replayStep}
        allDashboards={this.props.dashboards}
        settings={this.props.settings}
        markerIcon={this.props.panelMeta.markerIcon ?? "default"}
      />
    );
  }
}
