import React from "react";
import { List } from "semantic-ui-react";
import { Permission } from "../../../../util";

/* Example English-like text for displaying permissions: 

- has homepage as "dashboards/1
- Access devices where city in bangalore, chennai
    - If the value of devices is {} then the text should be "Access all devices"
- View data from the following streams and fields
    - bms: all
    - gps: latitude, longitude
- Can view all metadata
    - Should be none if value is []
- Can edit metadata: city, model
    - Should be none if value is []
- Can [not] create a dashboard
- "Can share dashboards with users of roles: operations"
- "Can trigger actions: update_firmware, update_config"
    - Should be none if array is empty
- "Can create, view, edit, delete firmware"
    - Should be can view firmware if user has only view permissions
- "Can create, view, edit, delete device configs"
    - Should be can view device configs if user has only view permissions
- "Can create, view, edit, delete streams"
    - Should be can view streams if user has only view permissions
*/

interface PermissionViewProps {
  permission: Permission;
  roleNameMap: Record<number, string>;
}

function toHuman<T>(list: "all" | T[]) {
  if ("all" === list) {
    return "all";
  } else if (list.length === 0) {
    return "none";
  } else {
    const genericTypeList: T[] = list as T[];
    return genericTypeList?.join(", ");
  }
}

function toHumanViewEdit(view: boolean, edit: boolean) {
  if (edit) {
    return "Can create, view, edit, delete";
  } else if (view) {
    return "Can view";
  } else {
    return "Cannot create, view, edit, or delete";
  }
}

function toHumanViewEditFirmwareAndFiles(view: boolean, edit: boolean) {
  if (edit) {
    return "Can create, view, activate, deactivate";
  } else if (view) {
    return "Can view";
  } else {
    return "Cannot create, view or deactivate";
  }
}

function safeStr(str: string) {
  if (str) {
    return str;
  } else {
    return "unknown";
  }
}

function toHumanRoles(
  roleList: "all" | number[],
  roleMap: { [key: number]: string }
) {
  if (roleList === "all") {
    return "all";
  } else if (roleList.length === 0) {
    return "none";
  } else {
    return roleList.map((roleId) => safeStr(roleMap[roleId])).join(", ");
  }
}

const PermissionView = React.memo((props: PermissionViewProps) => {
  const { permission } = props;
  return (
    <List bulleted>
      <List.Item>has homepage as {permission.homepage}</List.Item>
      <List.Item>
        {Object.keys(permission.devices).length === 0 ? (
          "Access all devices"
        ) : (
          <>
            Access devices where
            <List.List>
              {Object.entries(permission.devices).map(([k, v]) => (
                <List.Item key={k}>
                  {k} in {toHuman(v)}
                </List.Item>
              ))}
            </List.List>
          </>
        )}
      </List.Item>
      <List.Item>
        {Object.keys(permission.tables).length === 0 ? (
          "Cannot access any data"
        ) : typeof permission.tables === "object" ? (
          <>
            View data from the following streams and fields
            <List.List>
              {Object.entries(permission.tables).map(([k, v]) => (
                <List.Item key={k}>
                  {k} : {toHuman(v)}
                </List.Item>
              ))}
            </List.List>
          </>
        ) : (
          <>
            View data from the streams and fields
            {typeof permission.tables === "string" &&
            permission.tables === "all"
              ? ": all"
              : ": unknown"}
          </>
        )}
      </List.Item>
      <List.Item>
        Can view metadata in device management:{" "}
        {toHuman(permission.viewMetadata)}
      </List.Item>
      <List.Item>
        Can edit metadata in device management:{" "}
        {toHuman(permission.editMetadata)}
      </List.Item>
      <List.Item>
        {toHumanViewEdit(
          permission.viewMetadataKeys,
          permission.editMetadataKeys
        )}{" "}
        metadata list
      </List.Item>
      <List.Item>
        Can {permission.createDashboards ? "" : "not"} create a dashboard
      </List.Item>
      <List.Item>
        Can share dashboards with users of roles:{" "}
        {toHumanRoles(
          permission.dashboardPermittedShareRoles,
          props.roleNameMap
        )}
      </List.Item>
      <List.Item>
        {toHumanViewEdit(
          permission.viewActionTypes,
          permission.editActionTypes
        )}{" "}
        action type list
      </List.Item>
      <List.Item>
        Can trigger actions: {toHuman(permission.allowedActions)}
      </List.Item>
      <List.Item>
        {permission.allowMarkActionAsCompleted
          ? "Can mark action as completed"
          : "Cannot mark action as completed"}
      </List.Item>
      <List.Item>
        {toHumanViewEditFirmwareAndFiles(
          permission.viewFiles,
          permission.editFiles
        )}{" "}
        files
      </List.Item>
      <List.Item>
        {toHumanViewEditFirmwareAndFiles(
          permission.viewFirmwares,
          permission.editFirmwares
        )}{" "}
        firmware
      </List.Item>
      <List.Item>
        {toHumanViewEdit(
          permission.viewDeviceConfigs,
          permission.editDeviceConfigs
        )}{" "}
        device configs
      </List.Item>
      <List.Item>
        {toHumanViewEdit(permission.viewStreams, permission.editStreams)}{" "}
        streams
      </List.Item>
      <List.Item>
        {toHumanViewEdit(permission.viewUsers, permission.editUsers)} users
      </List.Item>
      <List.Item>
        {toHumanViewEdit(permission.viewRoles, permission.editRoles)} roles
      </List.Item>
      <List.Item>
        {permission.allowCreatingDevices
          ? "Can create device"
          : "Cannot create device"}
      </List.Item>
      <List.Item>
        {permission.editTenantSettings
          ? "Can edit project settings"
          : "Cannot edit project settings"}
      </List.Item>
    </List>
  );
});

export default PermissionView;
