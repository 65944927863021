import React, { useCallback, useEffect, useRef, useState } from "react";
import PhaseMenuTab from "./PhaseMenuTab";
import DeviceSelectionSection from "./DeviceSelectionSection";
import LoadingAnimation from "../../../common/Loader";
import {
  NewActionLabelContainer,
  PhaseData,
  StyledNonBoldHeader,
} from "./NewAction";
import {
  Device,
  DeviceFilterOption,
  DeviceFilters,
  Key,
  SearchDeviceResponse,
  fetchAllMetadataKeys,
  fetchDeviceFilterOptions,
  searchDevices,
} from "../../../../BytebeamClient";
import { ErrorMessage } from "../../../common/ErrorMessage";
import { convertDecimalToRoman } from "../util";
import moment from "moment";
import styled from "styled-components";
import AdvancedPhasedSection from "./AdvancedPhasedSection";
import { ThinDivider } from "../../Dashboards/Panel/util";
import { debounce, validateTimestampInterval } from "../../util";
import ActionFilterNestedDropdown, {
  ActionNestedDropdownOptions,
} from "../ActionsV3/ActionFilterNestedDropdown";
import SelectedFilter from "../ActionsV3/SelectedFilter";
import { Divider, Icon } from "semantic-ui-react";
import ActionDisplaySection from "./ActionDisplaySection";
import { FlexContainer } from "../../../common/ActionsUtils";
import ActionButton from "../ActionsV3/ActionButton";
import { breakpoints } from "../../../common/breakpoints";
import UploadDeviceListModal from "./UploadDeviceListModal";

export const PhaseSectionContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const PhasesMenuWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 40px;
  padding: 6px 0px;
  overflow-x: scroll;
  scroll-behavior: smooth;

  @media (min-width: ${breakpoints.sm}px) {
    max-width: 75vw;
  }
  @media (max-width: ${breakpoints.sm + 1}px) {
    max-width: 90vw;
  }
`;

export const AddFiltersButton = styled(Icon)`
  margin-right: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 15px !important;
  cursor: pointer;
`;

type PhaseErrorsMessage = { [key: string]: string | null };

export type ButtonPosition = {
  top: number;
  left: number;
  height: number;
  width: number;
};

type PhaseContentProps = {
  phase: PhaseData;
  phasesData: PhaseData[];
  setPhasesData: (phasesData: PhaseData[]) => void;
  activePhase: string;
  phaseErrorsMessage: PhaseErrorsMessage;
  setPhaseErrorsMessage: (
    error: (prevErrors: PhaseErrorsMessage) => PhaseErrorsMessage
  ) => void;
  filterLoading: boolean;
  filters: DeviceFilters;
  filterOptions: DeviceFilterOption[];
  updateFilters: (
    filterName: string,
    filterValues: string[],
    allowedFilterBy: Key[]
  ) => void;
  resetFilters: (arg0: string) => void;
  devices?: SearchDeviceResponse;
  devicesLoading: boolean;
  setDevicesLoading: (arg0: boolean) => void;
  allSelected: boolean;
  setAllSelected: (allSelected: boolean) => void;
  selectedDevices: SearchDeviceResponse;
  setSelectedDevices: (selectedDevices: SearchDeviceResponse) => void;
  page: number;
  allowedFilterBys: Key[];
  pageLimit: number;
  onPageChange: (e, { activePage }) => void;
  metadataKeysToShow: string[];
  setMetadataKeysToShow: React.Dispatch<React.SetStateAction<string[]>>;
  currentDepth: number;
  setCurrentDepth: (arg0: number) => void;
  depth: number;
  setDepth: (arg0: number) => void;
  isPhasedRollout: boolean;
  selectedFilters: DeviceFilters;
  setSelectedFilters: (arg0: DeviceFilters) => void;
  filtersExist: boolean;
  setFiltersExist: (arg0: boolean) => void;
};

const PhaseContent = (props: PhaseContentProps) => {
  const {
    phase,
    phasesData,
    setPhasesData,
    activePhase,
    phaseErrorsMessage,
    setPhaseErrorsMessage,
    filterLoading,
    filters,
    filterOptions,
    updateFilters,
    resetFilters,
    devices,
    devicesLoading,
    setDevicesLoading,
    allSelected,
    setAllSelected,
    selectedDevices,
    setSelectedDevices,
    page,
    pageLimit,
    onPageChange,
    allowedFilterBys,
    metadataKeysToShow,
    setMetadataKeysToShow,
    isPhasedRollout,
    selectedFilters,
    setSelectedFilters,
    filtersExist,
    setFiltersExist,
  } = props;

  const [showFilterDropdown, setShowFilterDropdown] = useState<boolean>(false);
  const [showInsideFilterDropdown, setShowInsideFilterDropdown] =
    useState<boolean>(false);
  const [actionNestedDropdownOptions, setActionNestedDropdownOptions] =
    useState<ActionNestedDropdownOptions[]>([]);
  const [showActionDisplayDropdown, setShowActionDisplayDropdown] =
    useState<boolean>(false);
  const [addFiltersButtonPosition, setAddFiltersButtonPosition] =
    useState<ButtonPosition>();

  const getNumericalDeviceIDs = (deviceIDArr) => {
    let arr: number[] = [];
    deviceIDArr.map((deviceID: Device) => arr.push(deviceID.id));
    return arr;
  };

  const upDateActivePhaseTimestamp = (time, activePhase: string) => {
    // Validate if the timestamp has a 5-minute interval
    const isValidTimestamp = validateTimestampInterval(
      time,
      activePhase,
      phasesData
    );

    if (!isValidTimestamp) {
      if (isPhasedRollout && phasesData.length > 1) {
        setPhaseErrorsMessage((prevErrors: PhaseErrorsMessage) => ({
          ...prevErrors,
          [activePhase]:
            "Please ensure a minimum interval of 5 minutes from the previous phase.",
        }));
      } else {
        setPhaseErrorsMessage((prevErrors: PhaseErrorsMessage) => ({
          ...prevErrors,
          [activePhase]:
            "Please ensure that trigger time is a minimum of 5 minutes later than the current time.",
        }));
      }
    } else {
      setPhaseErrorsMessage((prevErrors: PhaseErrorsMessage) => ({
        ...prevErrors,
        [activePhase]: null,
      }));
    }

    const newPhasesData = phasesData.map((phase) => {
      if (phase.name === activePhase) {
        phase.trigger_on.timestamp = moment(time).toDate();
      }
      return phase;
    });
    setPhasesData(newPhasesData);
  };

  const upDateActivePhaseFraction = (value, activePhase) => {
    const newPhasesData = phasesData.map((phase) => {
      if (phase.name === activePhase) {
        phase.info.fraction = parseInt(value);
      }
      return phase;
    });
    setPhasesData(newPhasesData);
  };

  const updateFixedListPhaseData = (updatedDevices: SearchDeviceResponse) => {
    let updatedPhaseData: PhaseData = { ...phasesData[phase.id - 1] };
    updatedPhaseData.info.device_ids = getNumericalDeviceIDs(
      updatedDevices.devices
    );
    updatedPhaseData.info.filter = filters;
    updatedPhaseData.info.fraction = 100;
    if (updatedDevices.devices.length === 0) {
      updatedPhaseData.info.type =
        Object.keys(filters).length === 0 ? "fixed-list" : "filter-fraction";
    } else {
      updatedPhaseData.info.type = "fixed-list";
    }

    let updatedPhasesData: PhaseData[] = [...phasesData];
    updatedPhasesData[phase.id - 1] = updatedPhaseData;
    setPhasesData(updatedPhasesData);
  };

  const selectDevice = (device: Device) => {
    let updatedDevices: SearchDeviceResponse = {
      devices: [...selectedDevices.devices, device],
      count: selectedDevices.count + 1,
    };
    setSelectedDevices(updatedDevices);

    // Update the phase info with the selected devices ids (With or Without filters)
    updateFixedListPhaseData(updatedDevices);
  };

  const clearDevice = (device: Device) => {
    const filteredDevices = selectedDevices.devices.filter(
      (selectedDevice) => selectedDevice.id !== device.id
    );

    const updatedDevices: SearchDeviceResponse = {
      devices: filteredDevices,
      count: selectedDevices.count - 1,
    };
    setSelectedDevices(updatedDevices);

    // Update the phase info with the removed devices ids (With or Without filters)
    updateFixedListPhaseData(updatedDevices);
  };

  const handleAllDevicesSelect = (selectAll: boolean) => {
    setAllSelected(selectAll);

    let updatedPhaseData: PhaseData = { ...phasesData[phase.id - 1] };
    if (Object.keys(filters).length === 0 && !selectAll) {
      updatedPhaseData.info = {
        type: "fixed-list",
        device_ids: [],
      };
    } else {
      updatedPhaseData.info = {
        type: "filter-fraction",
        filter: filters,
        fraction: 100,
      };
    }
    let updatedPhasesData: PhaseData[] = [...phasesData];
    updatedPhasesData[phase.id - 1] = updatedPhaseData;
    setPhasesData(updatedPhasesData);
  };

  const debouncedUpdateFilters = useCallback(debounce(updateFilters, 1000), [
    updateFilters,
  ]);

  const onFilterChange = useCallback(
    (name: any, value: any) => {
      setDevicesLoading(true);

      const filterName = name;
      const filterValues = value as string[];

      const currentlySelectedFilters = { ...selectedFilters };
      if (filterValues.length > 0) {
        currentlySelectedFilters[filterName] = filterValues;
      } else {
        delete currentlySelectedFilters[filterName];
      }

      // update selectedFilters accordingly
      if (Object.keys(currentlySelectedFilters).length === 0) {
        setFiltersExist(false);
        setAllSelected(false);
      } else {
        setFiltersExist(true);
        setAllSelected(true);
      }

      setSelectedFilters(currentlySelectedFilters);
      debouncedUpdateFilters(filterName, filterValues, allowedFilterBys);
    },
    [
      // eslint-disable-line react-hooks/exhaustive-deps
      selectedFilters,
      allowedFilterBys,
      setDevicesLoading,
      setSelectedFilters,
      debouncedUpdateFilters,
      setFiltersExist,
      setAllSelected,
    ]
  );

  function getObjectDepth(obj: any): number {
    let depth = 0;

    function calculateDepth(object: any, currentDepth: number): void {
      if (typeof object === "object" && object !== null) {
        for (const key in object) {
          if (object.hasOwnProperty(key)) {
            calculateDepth(object[key], currentDepth + 1);
          }
        }
      } else {
        depth = Math.max(depth, currentDepth);
      }
    }

    calculateDepth(obj, 0);

    return depth;
  }

  const getButtonPosition = (id: string) => {
    const buttonElement = document.getElementById(id);
    if (buttonElement) {
      const buttonPosition = buttonElement.getBoundingClientRect();
      setAddFiltersButtonPosition({
        top: buttonPosition.top,
        left: buttonPosition.left,
        width: buttonPosition.width,
        height: buttonPosition.height,
      });
    }
  };

  useEffect(() => {
    let array: ActionNestedDropdownOptions[] = [];
    filterOptions.flatMap((option) => {
      array.push({
        label: option.filterName,
        // Filtering out values containing only white spaces and Mapping to Dropdown options using FlatMap
        children: option?.filterValues
          ? option.filterValues.flatMap((v) => {
              let value = String(v);
              if (value?.trim() !== "") return [value];
              else return [];
            })
          : [],
      });
    });

    setActionNestedDropdownOptions(array);

    if (array.length !== 0) {
      props.setDepth(
        Array.isArray(array) ? getObjectDepth(array) - 1 : getObjectDepth(array)
      );
    }
  }, [filterOptions]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <PhaseSectionContainer
      id={`phaseContent-${phasesData.length + 2 - phase.id}`}
      style={{
        display: activePhase === phase.name ? "block" : "none",
      }}
    >
      {filterOptions && filterOptions?.length !== 0 && (
        <>
          <NewActionLabelContainer style={{ marginBottom: "20px" }}>
            <div
              style={{
                display: "flex",
                flexGrow: 1,
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  minHeight: "35px",
                }}
              >
                <div
                  className="dashboard-header"
                  style={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <FlexContainer>
                    <div>
                      <ActionButton
                        onClick={() => setShowInsideFilterDropdown(true)}
                        border_style={"dashed"}
                        label={"Filters"}
                        icon={"filter"}
                        margin_left="0px"
                        id={`create-action-metadata-filters-button-${phasesData.length + 2 - phase.id}`}
                      />
                      <div style={{ position: "relative" }}>
                        {showInsideFilterDropdown && (
                          <ActionFilterNestedDropdown
                            setShowDropdown={(value) =>
                              setShowInsideFilterDropdown(value)
                            }
                            depth={props.depth}
                            onClick={(filterOption, filterValue) => {
                              onFilterChange(filterOption, filterValue);
                            }}
                            nestedDropdownOptions={actionNestedDropdownOptions}
                            filters={props.filters}
                            currentDepth={props.currentDepth}
                            setCurrentDepth={(value) =>
                              props.setCurrentDepth(value)
                            }
                            search
                          />
                        )}
                      </div>
                    </div>
                  </FlexContainer>
                </div>
              </div>
              {filtersExist && Object.keys(selectedFilters).length !== 0 && (
                <div
                  style={{
                    alignSelf: "center",
                  }}
                >
                  <StyledNonBoldHeader
                    as="h3"
                    style={{
                      marginTop: "0px",
                      marginBottom: "0px",
                      fontSize: "1.1rem",
                      whiteSpace: "nowrap",
                    }}
                    className={`${
                      Object.keys(selectedFilters).length === 0
                        ? "color-disabled"
                        : "selectable-item underline hover-underline color-blue"
                    }`}
                    onClick={() => {
                      resetFilters(phase.name);
                    }}
                    id={`action-filter-clear-all-${phase.id}`}
                  >
                    Clear All
                  </StyledNonBoldHeader>
                </div>
              )}
            </div>
          </NewActionLabelContainer>

          {filtersExist && Object.keys(selectedFilters).length !== 0 ? (
            <>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                  marginBottom: "10px",
                  marginRight: "10px",
                  marginLeft: "10px",
                  width: "100%",
                }}
              >
                {Object.keys(selectedFilters).map((key) => (
                  <SelectedFilter
                    key={key}
                    filterName={key}
                    filterValues={filters[key]}
                    filterNameIcon={"file"}
                    filterValueIcon={"info circle"}
                    onCrossClick={(filterOption, filterValue) => {
                      onFilterChange(filterOption, filterValue);
                    }}
                    multiple
                  />
                ))}

                <AddFiltersButton
                  name="plus"
                  id={`action-add-filters-button-${phasesData.length + 2 - phase.id}`}
                  onClick={() => {
                    setShowFilterDropdown(true);
                    getButtonPosition("action-add-filters-button");
                  }}
                />
                <div style={{ position: "relative" }}>
                  {showFilterDropdown && (
                    <ActionFilterNestedDropdown
                      setShowDropdown={(value) => setShowFilterDropdown(value)}
                      depth={props.depth}
                      onClick={(filterOption, filterValue) => {
                        onFilterChange(filterOption, filterValue);
                      }}
                      nestedDropdownOptions={actionNestedDropdownOptions}
                      filters={props.phasesData[phase.id - 1].info.filter!} // TODO
                      currentDepth={props.currentDepth}
                      setCurrentDepth={(value) => props.setCurrentDepth(value)}
                      parentButtonPosition={addFiltersButtonPosition}
                      search
                    />
                  )}
                </div>
                <Divider />
              </div>
              <ThinDivider />
            </>
          ) : (
            <></>
          )}
        </>
      )}

      {!filterLoading &&
        !devicesLoading &&
        devices &&
        devices.devices.length !== 0 && (
          <ActionDisplaySection
            showActionDisplayDropdown={showActionDisplayDropdown}
            setShowActionDisplayDropdown={setShowActionDisplayDropdown}
            setMetadataKeysToShow={setMetadataKeysToShow}
            metadataKeysToShow={metadataKeysToShow}
            allowedMetadataKeys={allowedFilterBys}
          />
        )}

      {(filterLoading || devicesLoading) && (
        <LoadingAnimation
          loaderContainerHeight="350px"
          loadingText="Loading devices"
          fontSize="20px"
          loaderSize="48px"
        />
      )}
      {!filterLoading && !devicesLoading && devices?.devices?.length !== 0 && (
        <>
          <DeviceSelectionSection
            devices={devices}
            allSelected={allSelected}
            setAllSelected={handleAllDevicesSelect}
            selectedDevices={selectedDevices}
            setSelectedDevices={setSelectedDevices}
            selectDevice={selectDevice}
            clearDevice={clearDevice}
            page={page}
            pageLimit={pageLimit}
            onPageChange={onPageChange}
            metadataKeysToShow={props.metadataKeysToShow}
            phase={activePhase}
          />

          <ThinDivider />

          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: "42px 0px 40px 0px",
            }}
          >
            <AdvancedPhasedSection
              key={activePhase}
              isPhasedRollout={isPhasedRollout}
              phases={phasesData}
              activePhase={activePhase}
              allSelected={allSelected}
              phaseErrorsMessage={phaseErrorsMessage}
              upDateActivePhaseTimestamp={upDateActivePhaseTimestamp}
              upDateActivePhaseFraction={upDateActivePhaseFraction}
            />
          </div>
        </>
      )}
      {!filterLoading && !devicesLoading && devices?.devices.length === 0 && (
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ErrorMessage
            marginTop="30px"
            marginBottom="30px"
            message={"No devices found!"}
          />
        </div>
      )}
    </PhaseSectionContainer>
  );
};

type PhaseControlSectionProps = {
  readonly phasesData: PhaseData[];
  readonly setPhasesData: (phases: PhaseData[]) => void;
  readonly isPhasedRollout: boolean;
  readonly setIsPhasedRollout: (isPhasedRollout: boolean) => void;
};

const PhaseControlSection = (props: PhaseControlSectionProps) => {
  const { phasesData, setPhasesData, isPhasedRollout, setIsPhasedRollout } =
    props;

  const scrollRef = useRef<HTMLDivElement>(null);

  let abortController = new AbortController();

  const [filters, setFilters] = useState<DeviceFilters>({});
  const [allSelected, setAllSelected] = useState<boolean>(false);
  const [selectedDevices, setSelectedDevices] = useState<SearchDeviceResponse>({
    devices: [],
    count: 0,
  });
  const [page, setPage] = useState<number>(1);
  const [devicesLoading, setDevicesLoading] = useState<boolean>(true);
  const [filterOptions, setFilterOptions] = useState<DeviceFilterOption[]>([]);
  const [devices, setDevices] = useState<SearchDeviceResponse>();
  const [filterLoading, setFilterLoading] = useState<boolean>(true);
  const [phaseErrorsMessage, setPhaseErrorsMessage] =
    useState<PhaseErrorsMessage>({});
  const [activePhase, setActivePhase] = useState<string>(
    `Phase ${convertDecimalToRoman(1)}`
  );
  const [allowedFilterBys, setAllowedFilterBys] = useState<Key[]>([]);
  const [pageLimit] = useState<number>(5);
  const [metadataKeysToShow, setMetadataKeysToShow] = useState<string[]>([]);

  const [depth, setDepth] = useState(1);
  const [currentDepth, setCurrentDepth] = useState<number>(0);

  const [selectedFilters, setSelectedFilters] = useState<DeviceFilters>({});

  const [filtersExist, setFiltersExist] = useState<boolean>(false);

  const refreshDevices = (pageNumber: number, myFilters: DeviceFilters) => {
    setDevicesLoading(true);

    abortController.abort();
    abortController = new AbortController();

    //setTimeout here is to allow change in filters/page using setState to take affect before refreshing devices;
    setTimeout(async () => {
      const status = "active";
      await searchDevices(
        myFilters,
        pageNumber,
        pageLimit,
        status,
        abortController.signal
      ).then((devices) => {
        setDevices(devices);
        setDevicesLoading(false);
      });
    });
  };

  /**
   * Updates the filter options based on the provided device filters and optional keys.
   * This function fetches the latest filter options, maps them according to the provided keys (if any),
   * and updates the state to reflect the new filter options.
   *
   * @param {DeviceFilters} filters - The current set of filters applied to devices.
   * @param {Key[]} [keys] - Optional. A list of keys to filter the filter options by. If not provided, all filter options are used.
   * @returns {Promise<void>} A promise that resolves when the filter options have been refreshed and the state has been updated.
   **/
  async function refreshFilterOptions(
    filters: DeviceFilters,
    keys?: Key[]
  ): Promise<void> {
    setFilterLoading(true);

    try {
      const allFilterOptions = await fetchDeviceFilterOptions(filters);
      const filterOptionsMap: { [key: string]: any[] } = {};

      allFilterOptions.forEach(({ filterName, filterValues }) => {
        filterOptionsMap[filterName] = filterValues;
      });

      if (keys && keys.length > 0) {
        const mappedFilterOptions = keys.map(({ key }) => ({
          filterName: key,
          filterValues: filterOptionsMap[key] || [], // Fallback to an empty array if not found
        }));

        setFilterOptions(mappedFilterOptions);
      } else {
        // If no specific keys are provided, use all filter options as is
        setFilterOptions(allFilterOptions);
      }
    } catch (error) {
      console.error("Failed to refresh filter options:", error);
    } finally {
      setFilterLoading(false);
    }
  }

  const fetchMetadataKeys = useCallback(async () => {
    setDevicesLoading(true);
    await fetchAllMetadataKeys().then((keys) => {
      const idKey = { key: "id" };
      setAllowedFilterBys([idKey, ...keys]);
      refreshFilterOptions(filters, [idKey, ...keys]);
    });
  }, [filters]);

  const onPageChange = useCallback(
    (e, { activePage }) => {
      setDevicesLoading(true);
      setPage(activePage as number);
      refreshDevices(activePage, filters);
    },
    [filters] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const resetPhaseFilters = (name: string) => {
    const selectedPhase = phasesData.find((phase) => phase.name === name);
    if (!selectedPhase) return;

    const { id } = selectedPhase;

    selectedPhase.info.device_ids = [];
    selectedPhase.info.filter = {};
    selectedPhase.info.type = "fixed-list";
    selectedPhase.info.fraction = 0;

    let updatedPhasesData: PhaseData[] = [...phasesData];
    updatedPhasesData[id - 1] = selectedPhase;
    setPhasesData(updatedPhasesData);
  };

  const resetFilters = (name?: string) => {
    setFilterLoading(true);
    setDevicesLoading(true);
    setPage(1);
    setAllowedFilterBys([]);
    setFilters({});
    setSelectedDevices({ devices: [], count: 0 });
    setAllSelected(false);
    setSelectedFilters({});
    if (name) resetPhaseFilters(name);
  };

  const addPhase = () => {
    const currentDateTime = new Date();

    const newPhase: PhaseData = {
      id: phasesData.length + 1,
      name: `Phase ${convertDecimalToRoman(phasesData.length + 1)}`,
      trigger_on: {
        timestamp:
          moment(phasesData[phasesData.length - 1].trigger_on.timestamp)
            .add(15, "minutes")
            .toDate() ?? new Date(currentDateTime.getTime() + 15 * 60 * 1000),
      },
      info: {
        type: "fixed-list",
        device_ids: [],
      },
    };

    const newPhasesData: PhaseData[] = [...phasesData, newPhase];
    setPhasesData(newPhasesData);
    setActivePhase(`Phase ${convertDecimalToRoman(phasesData.length + 1)}`);

    // Reset filters when adding a new phase
    resetFilters(newPhase.name);
  };

  const removePhase = useCallback(
    (e) => {
      e.stopPropagation();

      const newPhasesData = phasesData.filter(
        (phase) => phase.id !== phasesData.length
      );
      setPhasesData(newPhasesData);
      setActivePhase(`Phase ${convertDecimalToRoman(phasesData.length - 1)}`);

      // Reset filters when removing a phase
      resetFilters();

      const selectedPhase = phasesData.find(
        (phase) => phase.id === newPhasesData.length
      );

      if (!selectedPhase) return;

      const { type, filter, device_ids } = selectedPhase.info;

      if (type === "filter-fraction" || type === "filter-fraction-lazy") {
        const newFilters = filter ?? {};
        setFilters(newFilters);
        setSelectedFilters(newFilters);

        if (
          type === "filter-fraction" &&
          Object.keys(newFilters).length === 0
        ) {
          setAllSelected(true);
        }
      } else if (type === "fixed-list") {
        const newFilters = filter ?? {};
        setFilters(newFilters);
        setSelectedFilters(newFilters);

        const newDevices = device_ids?.map((id) => ({ id }) as Device) ?? [];
        setSelectedDevices({
          devices: newDevices,
          count: newDevices.length,
        });
      }
    },
    [phasesData, activePhase] //eslint-disable-line react-hooks/exhaustive-deps
  );

  const updateFilters = (
    filterName: string,
    filterValues: string[],
    allowedFilterBy: Key[]
  ) => {
    const myFilters = { ...filters };

    if (filterValues.length > 0) {
      myFilters[filterName] = filterValues;
    } else {
      delete myFilters[filterName];
    }

    setFilters(myFilters);
    setSelectedFilters(myFilters); // clear ambiguitites if non existent filters overlap
    setPage(1);
    setDevicesLoading(true);
    setSelectedDevices({ devices: [], count: 0 });
    setAllSelected(false);

    refreshFilterOptions(myFilters, allowedFilterBy);
    refreshDevices(1, myFilters);

    // If filters are applied, update the phase info with the filter
    if (Object.keys(myFilters).length !== 0) {
      let updatedPhasesData: PhaseData[] = phasesData.map((phase) => {
        if (phase.name === activePhase) {
          phase.info.type = "filter-fraction";
          phase.info.filter = myFilters;
          phase.info.fraction = 100;
        }
        return phase;
      });
      setPhasesData(updatedPhasesData);
      setAllSelected(true);
    } else if (Object.keys(myFilters).length === 0) {
      // case when all filters are removed remove it from the phases data as above case only works until filters exist
      let updatedPhasesData: PhaseData[] = phasesData.map((phase) => {
        if (phase.name === activePhase) {
          phase.info.type = "fixed-list";
          phase.info.filter = {};
          phase.info.fraction = 0;
        }
        return phase;
      });
      setPhasesData(updatedPhasesData);
    }
  };

  const handlePhaseClick = (name: string, phaseData?: PhaseData) => {
    setActivePhase(name);
    resetFilters();

    const selectedPhase =
      phaseData ?? phasesData.find((phase) => phase.name === name);
    if (!selectedPhase) return;

    const { type, filter, device_ids } = selectedPhase.info;
    const newDevices = device_ids?.map((id) => ({ id }) as Device) ?? [];

    if (type === "filter-fraction" || type === "filter-fraction-lazy") {
      const newFilters = filter ?? {};
      setFilters(newFilters);
      setSelectedFilters(newFilters);

      if (Object.keys(newFilters).length > 0) {
        setFiltersExist(Boolean(Object.keys(newFilters).length));
        setAllSelected(true);
        setSelectedDevices({
          devices: newDevices,
          count: devices?.count ?? newDevices.length,
        });
      }
    } else if (type === "fixed-list") {
      const newFilters = filter ?? {};
      setFilters(newFilters);
      setSelectedFilters(newFilters);

      setSelectedDevices({
        devices: newDevices,
        count: newDevices.length,
      });
    }
  };

  useEffect(() => {
    const initialFetch = () => {
      fetchMetadataKeys();
      refreshDevices(1, {});
    };

    if (
      Object.keys(filters).length === 0 &&
      (!allowedFilterBys || allowedFilterBys.length === 0)
    ) {
      initialFetch();
    }
  }, [filters]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchMetadataKeys();
    refreshDevices(1, filters);
  }, [activePhase]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let phaseName = `Phase ${convertDecimalToRoman(1)}`;
    if (activePhase !== phaseName) handlePhaseClick(phaseName);
  }, [isPhasedRollout]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (scrollRef.current) {
      const scrollable = scrollRef.current;
      scrollable.scrollLeft = scrollable.scrollWidth - scrollable.clientWidth;
    }
  }, [phasesData.length, activePhase]);

  return (
    <>
      <UploadDeviceListModal
        setIsPhasedRollout={setIsPhasedRollout}
        setPhasesData={setPhasesData}
        handlePhaseClick={handlePhaseClick}
        fetchMetadataKeys={fetchMetadataKeys}
        refreshDevices={refreshDevices}
        disable={devicesLoading || filterLoading || devices?.count === 0}
      />
      {isPhasedRollout && (
        <PhasesMenuWrapper ref={scrollRef}>
          {phasesData.map((phase) => (
            <PhaseMenuTab
              key={phase.id}
              id={phasesData.length + 2 - phase.id}
              phasetabsum={phasesData.length + 1}
              tempPhaseRemoveCheck={phase.id === phasesData.length}
              firstElement={phase.id === 1}
              name={phase.name}
              active={activePhase === phase.name}
              removePhase={(e) => removePhase(e)}
              onClick={() => {
                handlePhaseClick(phase.name);
              }}
            />
          ))}
          <PhaseMenuTab
            id={1}
            lastElement
            loading={filterLoading || devicesLoading}
            onClick={() => {
              if (!filterLoading && !devicesLoading) addPhase();
            }}
          />
        </PhasesMenuWrapper>
      )}
      {phasesData.map((phase) => (
        <PhaseContent
          key={phase.id}
          phase={phase}
          phasesData={phasesData}
          setPhasesData={setPhasesData}
          activePhase={activePhase}
          phaseErrorsMessage={phaseErrorsMessage}
          setPhaseErrorsMessage={setPhaseErrorsMessage}
          allowedFilterBys={allowedFilterBys}
          filterLoading={filterLoading}
          filters={filters}
          filterOptions={filterOptions}
          updateFilters={updateFilters}
          resetFilters={resetFilters}
          devices={devices}
          devicesLoading={devicesLoading}
          setDevicesLoading={(value) => setDevicesLoading(value)}
          allSelected={allSelected}
          setAllSelected={setAllSelected}
          selectedDevices={selectedDevices}
          setSelectedDevices={setSelectedDevices}
          page={page}
          pageLimit={pageLimit}
          onPageChange={onPageChange}
          metadataKeysToShow={metadataKeysToShow}
          setMetadataKeysToShow={setMetadataKeysToShow}
          currentDepth={currentDepth}
          setCurrentDepth={(value) => setCurrentDepth(value)}
          depth={depth}
          setDepth={(value) => setDepth(value)}
          isPhasedRollout={isPhasedRollout}
          selectedFilters={selectedFilters}
          setSelectedFilters={(value) => setSelectedFilters(value)}
          filtersExist={filtersExist}
          setFiltersExist={(value) => setFiltersExist(value)}
        />
      ))}
    </>
  );
};

export default PhaseControlSection;
