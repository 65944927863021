import uuid from "uuid";
import { PanelDef } from "../PanelDef";
import { ViewAggregateValue } from "./ViewAggregateValue";
import { EditAggregateValueMeta } from "./EditAggregateValueMeta";

export class AggregateValueMetaData {
  type = "aggregate_value";
  table: string = "";
  column: string = "";
  prefix: string = "";
  suffix: string = "";
  aggregator: Array<string> = [];
  id: string = uuid.v4();
  title: string = "";
  description: string = "";
  textSize: number = 50;
  autoTextSize: boolean = true;
  fleetTableView: boolean = false;
  enableUnits: boolean = false;
  autoScaleUnits: boolean = false;
  enableRoundoff: boolean = true;
}

export type AggregateValueData = {
  data: { value: number };
};

export const AggregateValuePanelDef: PanelDef<
  AggregateValueMetaData,
  AggregateValueData
> = {
  ViewComponent: ViewAggregateValue,
  EditComponent: EditAggregateValueMeta,
  formatData: (data) => data,
  metaConstructor: AggregateValueMetaData,
  previewAspectRatio: { width: 500, height: 300 },
};
