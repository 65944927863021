import { PanelDef } from "../../PanelDef";
import { MicelioViewTrackDevices } from "./ViewTrackDevices";
import { MicelioEditTrackDevicesMeta } from "./EditTrackDevicesMeta";
import {
  DeviceRoute,
  formatData,
  TrackDevicesMetaData,
} from "../../TrackDevices/PanelDef";

export class MicelioTrackDevicesMetaData extends TrackDevicesMetaData {
  type = "micelio_track_devices";
}

export type MicelioTrackDevicesData = DeviceRoute[];

export const MicelioTrackDevicesPanelDef: PanelDef<
  MicelioTrackDevicesMetaData,
  MicelioTrackDevicesData
> = {
  ViewComponent: MicelioViewTrackDevices,
  EditComponent: MicelioEditTrackDevicesMeta,
  formatData: formatData,
  previewAspectRatio: { width: 500, height: 500 },
  metaConstructor: MicelioTrackDevicesMetaData,
};
