import React, { useMemo } from "react";
import { Icon, Input, Popup } from "semantic-ui-react";
import { DateTimeInput } from "semantic-ui-calendar-react";
import moment from "moment";
import styled from "styled-components";
import { PhaseInfo, StyledNonBoldHeader } from "./NewAction";
import { ErrorMessage } from "../../Settings/roles/CreateOrEditRoleModal";

type AdvancedPhasedSectionProps = {
  readonly phases: PhaseData[];
  readonly activePhase: string;
  readonly upDateActivePhaseTimestamp: (arg0: any, arg1: string) => void;
  readonly upDateActivePhaseFraction: (any, string) => void;
  readonly phaseErrorsMessage: { [key: string]: string | null };
  readonly allSelected: boolean;
  readonly isPhasedRollout: boolean;
};

export const AdvancedSectionContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 48px;
`;

export const AdvancedSectionItem = styled.div`
  display: flex;
  align-items: center;
`;

export type PhaseData = {
  id: number;
  name: string;
  trigger_on: {
    timestamp: Date | null | number;
  };
  info: PhaseInfo;
};

function AdvancedPhasedSection(props: AdvancedPhasedSectionProps) {
  const {
    phases,
    activePhase,
    upDateActivePhaseTimestamp,
    upDateActivePhaseFraction,
    phaseErrorsMessage,
    isPhasedRollout,
    // allSelected,
  } = props;

  const activePhaseData: PhaseData = useMemo(
    () => phases.filter((phase) => phase.name === activePhase)[0],
    // eslint-disable-next-line
    [phases, activePhase, isPhasedRollout]
  );

  const showFractionInput = () => {
    if (activePhaseData?.info.type === "fixed-list" || !isPhasedRollout) {
      return false;
    }
    return (
      activePhaseData?.info.type === "filter-fraction" ||
      activePhaseData?.info.type === "filter-fraction-lazy"
    );
  };

  const errorMessage: string =
    phaseErrorsMessage?.[activePhaseData?.name] ?? "";
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      <AdvancedSectionContainer>
        <AdvancedSectionItem>
          <StyledNonBoldHeader
            as="h3"
            style={{
              marginTop: "0px",
              marginBottom: "0px",
              fontSize: "1.1rem",
              whiteSpace: "nowrap",
            }}
          >
            When to trigger
          </StyledNonBoldHeader>
        </AdvancedSectionItem>
        <AdvancedSectionItem>
          <DateTimeInput
            minDate={moment().add(5, "minutes").format("YYYY-MM-DD HH:mm:ss")}
            placeholder="Date Time"
            key={activePhaseData?.name}
            popupPosition="bottom right"
            name="dateTime"
            closable
            hideMobileKeyboard
            value={
              activePhaseData?.trigger_on.timestamp
                ? activePhaseData?.trigger_on.timestamp?.toLocaleString("en-GB")
                : ""
            }
            iconPosition="left"
            dateTimeFormat={moment.defaultFormat}
            preserveViewMode={false}
            autoComplete="off"
            onChange={(e, data) => {
              upDateActivePhaseTimestamp(data.value, activePhaseData?.name);
            }}
            closeOnMouseLeave={false}
          />
        </AdvancedSectionItem>
        {showFractionInput() && (
          <AdvancedSectionItem>
            <StyledNonBoldHeader
              as="h3"
              style={{
                marginTop: "0px",
                marginBottom: "0px",
                fontSize: "1.1rem",
                whiteSpace: "nowrap",
              }}
            >
              Enter Phase Percentage
            </StyledNonBoldHeader>
            <Popup
              inverted
              trigger={
                <Icon
                  name="question circle"
                  style={{ marginBottom: "2px", marginLeft: "2px" }}
                />
              }
              content={
                "This percentage represents the portion of total devices allocated to this phase. Ensure that the sum of percentages for the same filter across different phases equals 100%."
              }
              position="top center"
            />
          </AdvancedSectionItem>
        )}
        {showFractionInput() && (
          <AdvancedSectionItem>
            <div>
              <Input
                key={activePhaseData?.name}
                type="number"
                placeholder="Enter Percentage"
                disabled={
                  activePhaseData?.info.type === "fixed-list"
                  // || ((activePhaseData?.info.type === "filter-fraction" ||
                  //   activePhaseData?.info.type === "filter-fraction-lazy") &&
                  //   allSelected === true)
                }
                value={activePhaseData?.info?.fraction}
                onChange={(_e, data) => {
                  upDateActivePhaseFraction(data.value, activePhaseData?.name);
                }}
                label=" % "
                labelPosition="right"
              />
            </div>
          </AdvancedSectionItem>
        )}
      </AdvancedSectionContainer>
      {errorMessage && (
        <ErrorMessage
          style={{ fontWeight: 700, fontSize: "1rem", marginTop: "1.4rem" }}
        >
          {errorMessage}
        </ErrorMessage>
      )}
    </div>
  );
}

export default AdvancedPhasedSection;
