import React, { useCallback, useRef } from "react";
import styled from "styled-components";
import { Checkbox, Icon } from "semantic-ui-react";
import { useOutsideClickHandler } from "../../../../hooks/useOutsideClickHandler";
import { ButtonPosition } from "../NewAction/PhaseControlSection";
import { inProgressStatuses } from "../util";

export type FilteredValue = {
  id: number;
  name: string;
  icon?: any;
  color?: string;
};

type ActionFilterDropdownProps = {
  readonly dropdownOptions: FilteredValue[];
  readonly setShowFilterDropdown: (arg0: boolean) => void;
  readonly addFilter: (arg0: FilteredValue) => void;
  readonly removeFilter?: (arg0: FilteredValue) => void;
  readonly selectedValues?: string[];
  readonly parentButtonPosition?: ButtonPosition;
};

const DropdownWrapper = styled.div<{
  buttonPosition?: ButtonPosition;
}>`
  z-index: 10;
  position: ${(props) => (props.buttonPosition ? "fixed" : "absolute")};
  background-color: ${(props) => props.theme.colors["action-dropdown-color"]};
  border: 1px solid ${(props) => props.theme.colors["action-border-color"]};
  border-radius: 8px;
  max-height: 400px;
  overflow-y: scroll;
  top: ${(props) =>
    props.buttonPosition
      ? `calc(${props.buttonPosition.top}px + ${props.buttonPosition.height}px + 0.5rem)`
      : "0.3rem"};
  left: ${(props) =>
    props.buttonPosition ? `${props.buttonPosition.left}px` : "0"};
  overflow: hidden;
  transition: all 0.15s ease-in;
  animation: fade-in 0.15s;
  padding: 0.3rem;

  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const DropdownItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  min-width: 200px;
  min-height: 30px;
  background-color: transparent;
  padding: 0.5rem 1.5rem;
  border-radius: 8px;
  transition: all 0.15s ease-in;
  &:hover {
    background-color: ${(props) =>
      props.theme.colors["action-border-color-hover"]};
  }
`;

const ColorIcons = styled.div<{ color: string }>`
  width: 10px;
  height: 10px;
  background-color: ${(props) => props.theme.colors[props.color]};
  margin-right: 0.5rem;
`;

export default function ActionFilterDropdown(props: ActionFilterDropdownProps) {
  const wrapperRef = useRef(null);
  useOutsideClickHandler(wrapperRef, () => {
    const dropdownWrapper = document.getElementById("dropdown-wrapper");
    if (dropdownWrapper) dropdownWrapper.style.opacity = "0";
    setTimeout(() => props.setShowFilterDropdown(false), 150);
  });

  const isFilterValueSelected = useCallback(
    (key: number) => {
      if (props.selectedValues) {
        if (props.dropdownOptions[key].name === "In Progress") {
          return inProgressStatuses.every((value) => {
            return props.selectedValues?.includes(value);
          });
        } else {
          return props.selectedValues.some((value) => {
            return value === props.dropdownOptions[key].name;
          });
        }
      } else return false;
    },
    [props.selectedValues, props.dropdownOptions]
  );

  return (
    <DropdownWrapper
      ref={wrapperRef}
      buttonPosition={props.parentButtonPosition}
    >
      {props.dropdownOptions.flatMap((option, key) => (
        <DropdownItem
          onClick={() => {
            props.setShowFilterDropdown(false);
            props.addFilter(option);
          }}
        >
          <Checkbox
            id={key}
            fitted
            style={{ marginRight: "1rem" }}
            checked={isFilterValueSelected(key)}
            onClick={(e) => e.stopPropagation()}
            onChange={(e, { checked }) => {
              if (!checked) {
                if (props.removeFilter) props.removeFilter(option);
              } else {
                props.addFilter(option);
              }
            }}
          />
          {option.icon ? (
            typeof option.icon !== "object" ? (
              <Icon
                name={option.icon}
                style={{
                  position: "relative",
                  bottom: "3px",
                  marginRight: "10px",
                }}
              />
            ) : (
              option.icon
            )
          ) : option.color ? (
            <ColorIcons color={option.color} />
          ) : (
            <></>
          )}
          {option.name === "PendingApproval" ? "Pending Approval" : option.name}
        </DropdownItem>
      ))}
    </DropdownWrapper>
  );
}
