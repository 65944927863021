import { AlertsMetaData } from "./PanelDef";
import React from "react";

import { PanelEditComponent, PartialMetaData } from "../PanelDef";
import { Tab } from "semantic-ui-react";
import {
  EditAnimatedMetaInput,
  EditMetaRoot,
  EditMetaRow,
  ThinDivider,
  EditPanelFormContainer,
  ToggleLabel,
} from "../util";
import ToggleSwitch from "../../../common/ToggleSwitch";
import { TableInfo } from "../../../../../BytebeamClient";

export type EditAlertsMetaState = {
  tabbedView: boolean;
};

export type EditAlertsMetaProps = {
  panelMeta: AlertsMetaData;
  tables: TableInfo;
};

export class EditAlertsMeta extends PanelEditComponent<
  AlertsMetaData,
  EditAlertsMetaState
> {
  titleRef = React.createRef<HTMLInputElement>();
  descriptionRef = React.createRef<HTMLInputElement>();

  constructor(props: EditAlertsMetaProps) {
    super(props);

    if (props.panelMeta) {
      this.state = props.panelMeta;
    } else {
      this.state = { tabbedView: false };
    }
  }

  getPanelMeta(): PartialMetaData<AlertsMetaData> {
    return {
      meta: {
        type: "alerts",
        id: this.props.panelMeta.id,
        title: this.titleRef.current?.value || "",
        description: this.descriptionRef.current?.value || "",
        tabbedView: this.state.tabbedView,
      },
      complete: true,
    };
  }

  tabbedViewToggle() {
    const toggled = !this.state.tabbedView;
    this.setState({ tabbedView: toggled });
  }

  render() {
    const title = this.props.panelMeta.title;
    const description = this.props.panelMeta.description;

    const panes = [
      {
        menuItem: "General",
        pane: (
          <Tab.Pane key={"general"}>
            <EditPanelFormContainer>
              <div style={{ width: "100%", marginTop: "16px" }} />
              <EditMetaRow>
                <div style={{ width: "48%" }}>
                  <EditAnimatedMetaInput
                    defaultRef={this.titleRef}
                    defaultValue={title}
                    label="Title"
                  />
                </div>
                <div style={{ width: "48%" }}>
                  <EditAnimatedMetaInput
                    defaultRef={this.descriptionRef}
                    defaultValue={description}
                    label="Description"
                  />
                </div>
              </EditMetaRow>
              <ThinDivider />
              <EditMetaRow>
                <ToggleLabel style={{ fontWeight: "bold" }}>
                  Enable Tabbed View
                </ToggleLabel>
                <ToggleSwitch
                  id="tabbedView"
                  defaultChecked={this.props.panelMeta.tabbedView}
                  disabled={false}
                  Text={["Yes", "No"]}
                  onToggleChange={() => {
                    this.tabbedViewToggle();
                  }}
                />
              </EditMetaRow>
            </EditPanelFormContainer>
          </Tab.Pane>
        ),
      },
    ];

    return (
      <EditMetaRoot>
        <Tab menu={{}} panes={panes} renderActiveOnly={false} />
      </EditMetaRoot>
    );
  }
}
