import React, { useEffect, useState } from "react";
import { ActionType } from "../../../../util";
import { Button, Icon, Popup, SemanticICONS, Table } from "semantic-ui-react";
import Layout from "../../common/Layout";
import ConfirmationModal from "../../common/ConfirmationModal";
import ConfirmationModalMessage from "../../common/ConfirmationModalMessage";
import ActionTypesModal, { ActionTypeModalProps } from "./ActionTypesModal";
import * as uuid from "uuid";
import useAsyncEffect from "../../common/useAsyncEffect";
import { ErrorMessage } from "../../../common/ErrorMessage";
import { DisplayIf } from "../../util";
import {
  createActionType,
  deleteActionType,
  editActionType,
  fetchAllActionTypes,
} from "../../../../BytebeamClient";
import { Mixpanel } from "../../common/MixPanel";
import { useUser } from "../../../../context/User.context";
import LoadingAnimation from "../../../common/Loader";

export default function ActionTypes() {
  const [actionTypes, setActionTypes] = useState<ActionType[]>([]);
  const [actionTypeModalProps, setActionTypeModalProps] =
    useState<ActionTypeModalProps>({
      key: uuid.v4(),
      isOpen: false,
      title: "",
      onSubmit: (role) => {},
      onCancel: () => {},
      actionTypesSet: new Set(),
    });
  const [loading, setLoading] = useState<boolean>(true);
  const [errorOccurred, setErrorOccurred] = useState<boolean>(false);

  const { user, getCurrentUser } = useUser();

  const permissions = user.role.permissions;

  useEffect(() => {
    document.title = "Action Types | Bytebeam";
  }, []);

  const onUpdate = async () => {
    setLoading(true);
    try {
      const res = await fetchAllActionTypes();
      setActionTypes(res);

      // Update user context to get the latest permissions for the user after updating the action types
      await getCurrentUser();

      setLoading(false);
    } catch (e) {
      console.log(e);
      setErrorOccurred(true);
    }
  };

  const closeActionTypeModal = () => {
    setActionTypeModalProps({
      ...actionTypeModalProps,
      isOpen: false,
    });
  };
  useAsyncEffect(onUpdate, []);

  const initCreateActionTypeModal = () => {
    setActionTypeModalProps({
      key: uuid.v4(),
      isOpen: true,
      title: "Create Action",
      onSubmit: async (at) => {
        closeActionTypeModal();
        try {
          await createActionType({
            type: at.type,
            icon: at.icon,
            payload_type: at.payload_type,
            json_schema: at.json_schema,
            json_ui_schema: at.json_ui_schema,
          });
          Mixpanel.track("Create Action", {
            action: at.type,
          });
          onUpdate();
        } catch (e) {
          Mixpanel.track("Failure", {
            type: "Create Action",
            error: JSON.stringify(e),
          });
          console.log(e);
        }
      },
      onCancel: closeActionTypeModal,
      actionTypesSet: new Set(
        actionTypes?.map((actionType) => actionType.type)
      ),
    });
  };

  const initEditActionTypeModal = (actionType: ActionType) => {
    setActionTypeModalProps({
      key: uuid.v4(),
      isOpen: true,
      title: "Edit Action Type",
      actionType,
      onSubmit: async (at) => {
        closeActionTypeModal();
        try {
          await editActionType(actionType.type, {
            type: at.type,
            icon: at.icon,
            payload_type: at.payload_type,
            json_schema: at.json_schema,
            json_ui_schema: at.json_ui_schema,
          });
          onUpdate();
        } catch (e) {
          console.log(e);
        }
      },
      onCancel: closeActionTypeModal,
      actionTypesSet: new Set(
        actionTypes?.map((actionType) => actionType.type)
      ),
    });
  };

  if (errorOccurred) {
    return <ErrorMessage marginTop="270px" errorMessage />;
  }

  if (loading) {
    return (
      <LoadingAnimation
        loaderContainerHeight="65vh"
        fontSize="1.5rem"
        loadingText="Loading actions"
      />
    );
  }

  const BuiltInActions = (
    actionType: ActionType
  ): { isBuiltIn: boolean; icon: SemanticICONS; payloadType: string } => {
    switch (actionType.type) {
      case "update_firmware":
        return { isBuiltIn: true, icon: "download", payloadType: "file" };
      case "update_config":
        return { isBuiltIn: true, icon: "configure", payloadType: "json" };
      case "update_geofence":
        return { isBuiltIn: true, icon: "map outline", payloadType: "json" };
      case "send_file":
        return { isBuiltIn: true, icon: "send", payloadType: "file" };
      case "launch_shell":
        return { isBuiltIn: true, icon: "terminal", payloadType: "none" };
      case "send_script":
        return { isBuiltIn: true, icon: "file", payloadType: "file" };
      case "cancel_action":
        return { isBuiltIn: true, icon: "cancel", payloadType: "none" };
      case "retry_action":
        return { isBuiltIn: true, icon: "redo", payloadType: "none" };
      default:
        return {
          isBuiltIn: false,
          icon: actionType.icon,
          payloadType: actionType.payload_type,
        };
    }
  };

  return (
    <>
      <ActionTypesModal {...actionTypeModalProps} />
      <Layout
        buttons={
          <DisplayIf cond={permissions.editActionTypes}>
            <Button
              primary
              floated="right"
              icon
              labelPosition="left"
              onClick={initCreateActionTypeModal}
            >
              <Icon name="plus" />
              Create Action
            </Button>
          </DisplayIf>
        }
      >
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Type</Table.HeaderCell>
              <Table.HeaderCell>Payload Type</Table.HeaderCell>
              <Table.HeaderCell>Icon</Table.HeaderCell>
              <DisplayIf cond={permissions.editActionTypes}>
                <Table.HeaderCell>Ops</Table.HeaderCell>
              </DisplayIf>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {actionTypes.length !== 0 ? (
              actionTypes
                .sort((at) => (BuiltInActions(at).isBuiltIn ? -1 : 1))
                .map((at) => (
                  <Table.Row key={at.type}>
                    <Table.Cell>{at.type}</Table.Cell>
                    <Table.Cell>{BuiltInActions(at).payloadType}</Table.Cell>
                    <Table.Cell>
                      <Icon name={BuiltInActions(at).icon} />
                      {BuiltInActions(at).icon}
                    </Table.Cell>
                    <DisplayIf cond={permissions.editActionTypes}>
                      <Table.Cell>
                        {BuiltInActions(at).isBuiltIn ? (
                          <>
                            <Popup
                              inverted
                              position="top left"
                              content="Default Action, its built in the platform."
                              style={{ marginBottom: "6px" }}
                              trigger={
                                <span>
                                  <Icon name="edit" disabled={true} />
                                  <Icon name="trash" disabled={true} />
                                </span>
                              }
                            />
                          </>
                        ) : (
                          <>
                            <Icon
                              name="edit"
                              onClick={() => initEditActionTypeModal(at)}
                              link
                            />
                            <ConfirmationModal
                              prefixContent="Delete Action-Type"
                              expectedText={at.type}
                              onConfirm={async () => {
                                try {
                                  await deleteActionType(at.type);
                                  onUpdate();
                                } catch (e) {
                                  console.log(e);
                                }
                              }}
                              trigger={<Icon link name="trash"></Icon>}
                              message={
                                <ConfirmationModalMessage
                                  name={at.type}
                                  type={"Action"}
                                  specialMessage="This only works if no action is assigned to this Action-Type."
                                />
                              }
                            />
                          </>
                        )}
                      </Table.Cell>
                    </DisplayIf>
                  </Table.Row>
                ))
            ) : (
              <Table.Row>
                <Table.Cell colspan={permissions.editActionTypes ? "4" : "3"}>
                  <ErrorMessage
                    marginTop="30px"
                    message={"No Action Types Found!"}
                  />
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </Layout>
    </>
  );
}
