import React, { useEffect, useState } from "react";
import Plot from "react-plotly.js";
import { useUser } from "../../../../context/User.context";
import LoadingAnimation from "../../../common/Loader";
import ThemeSchema from "../../../../theme/schema";
import { ProgressHistogramStats } from "../../../../util";
import { condenseArraysBySum, convertPhaseToNumber } from "../util";
import {
  ActionStatusType,
  fetchProgressHistogramStats,
} from "../../../../BytebeamClient";

type ActionsProgressHistogramProps = {
  height: number;
  textInfo: boolean;
  selectedPhase: string;
  selectedAction: ActionStatusType;
};

export function ActionsProgressHistogram(props: ActionsProgressHistogramProps) {
  const { selectedAction, selectedPhase } = props;

  const { user } = useUser();
  const theme = user?.settings?.theme;

  const [progressHistogramData, setProgressHistogramData] =
    useState<ProgressHistogramStats>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [y, setY] = useState<number[]>([]);
  const [actionId, setActionId] = useState<number>(0);

  const x = [
    "0-10",
    "10-20",
    "20-30",
    "30-40",
    "40-50",
    "50-60",
    "60-70",
    "70-80",
    "80-90",
    "90-100",
    "100",
  ];
  const width = [0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5];

  useEffect(() => {
    const setHistogramData = async (stats: ProgressHistogramStats) => {
      const currentPhase = convertPhaseToNumber(selectedPhase);
      if (Object.keys(stats)[0] === "-1") setY(stats[-1]);
      else {
        if (currentPhase !== "all") setY(stats[currentPhase - 1]);
        else {
          const dataArrays: number[][] = Object.values(stats);
          setY(condenseArraysBySum(dataArrays));
        }
      }
    };

    const fetchProgressStats = async () => {
      try {
        if (selectedAction.action_id !== actionId) {
          setLoading(true);

          const stats = await fetchProgressHistogramStats(
            selectedAction.action_id.toString()
          );

          setProgressHistogramData(stats);
          setHistogramData(stats);
          setActionId(selectedAction.action_id);
        } else {
          setHistogramData(progressHistogramData);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchProgressStats();
  }, [props.selectedAction.action_id, selectedPhase]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) {
    return (
      <LoadingAnimation
        loaderSize="28px"
        fontSize="18px"
        loaderBorderSize="3px"
        marginTopText="6px"
        loadingText="Loading progress histogram..."
      />
    );
  } else if (y?.length === 0) {
    return <div>Progress Histogram is not available...</div>;
  } else {
    return (
      <Plot
        style={{ width: "100%", height: "100%" }}
        data={[
          {
            name: "Progress Histogram",
            x,
            y,
            width,
            type: "bar",
            orientation: "v",
            marker: {
              showscale: false,
              color: "#7C3EFF",
            },
            showlegend: false,
            hoverinfo: "y",
          },
        ]}
        useResizeHandler
        layout={{
          autosize: true,
          height: props.height,

          margin: {
            l: 52,
            r: 12,
            b: 42,
            t: 12,
          },
          xaxis: {
            color:
              ThemeSchema.data[theme ?? "dark"]?.colors["chart-text-color"],
            showline: true,
            showgrid: false,
            fixedrange: true,
            tickmode: "auto",
            title: "Percentage",
          },
          yaxis: {
            color:
              ThemeSchema.data[theme ?? "dark"]?.colors["chart-text-color"],
            showgrid: false,
            fixedrange: true,
            title: "Devices",
          },
          paper_bgcolor: "transparent",
          plot_bgcolor: "transparent",
        }}
        config={{
          displayModeBar: false,
        }}
      />
    );
  }
}
