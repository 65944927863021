import React from "react";
import { Popup, Table } from "semantic-ui-react";
import { NewActionWrapper, PhaseData, StyledNonBoldHeader } from "./NewAction";
import { OptionType } from "../util";
import { StyledHeader } from "../ActionsV3/SelectableItem";
import styled from "styled-components";

const SummaryGridItem = styled.div`
  display: flex;
  width: "100%";
  align-items: center;
  height: "100%";
  padding: 10px 0;
`;

interface SummaryComponentProps {
  phasesData: PhaseData[];
  isPhasedRollout: boolean;
  action: string;
  optionType: OptionType;
  selectedVersion: string;
  actionName: string;
}

const SummaryComponent: React.FC<SummaryComponentProps> = ({
  phasesData,
  isPhasedRollout,
  action,
  optionType,
  selectedVersion,
  actionName,
}) => {
  const getFilterInfo = (
    phase: PhaseData
  ): { key: string; value: string }[] => {
    const filterInfo: { key: string; value: string }[] = [];

    if (phase.info.filter) {
      for (const [key, value] of Object.entries(phase.info.filter)) {
        const formattedValue = Array.isArray(value)
          ? value.join(", ")
          : String(value);
        filterInfo.push({ key, value: formattedValue });
      }
    }

    return filterInfo;
  };

  const getPhasesDeviceCount = (phase: PhaseData) => {
    const { type, filter, device_ids, fraction } = phase.info;

    if (type === "fixed-list") {
      if (filter && Object.keys(filter).length > 0) {
        // Return applied filters
        return (
          <Popup
            inverted
            content={<p>Devices: {device_ids?.join(", ")}</p>}
            trigger={
              <div>
                {getFilterInfo(phase).map((filterItem, index) => (
                  <div key={index}>
                    <strong>{filterItem.key}:</strong> {filterItem.value}
                  </div>
                ))}
              </div>
            }
          />
        );
      } else if (device_ids && device_ids.length > 0) {
        // Return device IDs
        return (
          <div>
            <strong>Device-Id:</strong> {`${device_ids.join(", ")}`}
          </div>
        );
        // return `${device_ids.join(", ")}`;
      }
    } else if (type === "filter-fraction" || type === "filter-fraction-lazy") {
      if (filter && Object.keys(filter).length === 0 && !device_ids) {
        return "All devices are selected";
      } else {
        return (
          <div>
            <h4
              style={{ margin: "0px" }}
            >{`${fraction}% devices are selected from filter: `}</h4>
            {getFilterInfo(phase).map((filterItem, index) => (
              <div key={index}>
                <strong>{filterItem.key}:</strong> {filterItem.value}
              </div>
            ))}
          </div>
        );
      }
    }

    return "No devices selected";
  };

  return (
    <NewActionWrapper>
      <StyledHeader as="h2" style={{ marginTop: "0px", marginBottom: "30px" }}>
        Summary
      </StyledHeader>
      <div
        style={{
          paddingLeft: "15px",
        }}
      >
        <div
          style={{
            display: "grid",
            gridTemplateAreas: `"auto auto auto auto"`,
            marginBottom: "30px",
          }}
        >
          <SummaryGridItem>
            <StyledNonBoldHeader
              as="h3"
              style={{
                marginTop: "0px",
                marginBottom: "0px",
                fontSize: "1.1rem",
                whiteSpace: "nowrap",
              }}
            >
              Action Type:
            </StyledNonBoldHeader>
          </SummaryGridItem>
          <SummaryGridItem>
            {action !== "" ? action : "No action selected"}
          </SummaryGridItem>
          {(optionType === OptionType.ChooseFirmware ||
            optionType === OptionType.UploadFirmware ||
            optionType === OptionType.ChooseConfig ||
            optionType === OptionType.UploadConfig ||
            optionType === OptionType.ChooseGeofence ||
            optionType === OptionType.UploadGeofence) && (
            <>
              <SummaryGridItem>
                <StyledNonBoldHeader
                  as="h3"
                  style={{
                    marginTop: "0px",
                    marginBottom: "0px",
                    fontSize: "1.1rem",
                    whiteSpace: "nowrap",
                  }}
                >
                  Version:
                </StyledNonBoldHeader>
              </SummaryGridItem>
              <SummaryGridItem>
                {selectedVersion !== ""
                  ? selectedVersion
                  : "No version selected"}
              </SummaryGridItem>
            </>
          )}
          {false && (
            <SummaryGridItem>
              <StyledNonBoldHeader
                as="h3"
                style={{
                  marginTop: "0px",
                  marginBottom: "0px",
                  fontSize: "1.1rem",
                  whiteSpace: "nowrap",
                }}
              >
                Action Name:
              </StyledNonBoldHeader>
            </SummaryGridItem>
          )}
          {false && (
            <SummaryGridItem>
              {actionName !== "" ? actionName : "no action name"}
            </SummaryGridItem>
          )}
          <SummaryGridItem>
            <StyledNonBoldHeader
              as="h3"
              style={{
                marginTop: "0px",
                marginBottom: "0px",
                fontSize: "1.1rem",
                whiteSpace: "nowrap",
              }}
            >
              Start Date/Time:
            </StyledNonBoldHeader>
          </SummaryGridItem>
          <SummaryGridItem>
            {phasesData[0].trigger_on.timestamp
              ? phasesData[0].trigger_on.timestamp?.toLocaleString("en-GB")
              : "Immediately"}
          </SummaryGridItem>
        </div>

        <Table>
          <Table.Header>
            <Table.Row>
              {isPhasedRollout && (
                <Table.Cell
                  style={{
                    borderBottom: "1px solid #3B3F43",
                  }}
                >
                  Phases
                </Table.Cell>
              )}
              <Table.Cell
                style={{
                  borderBottom: "1px solid #3B3F43",
                }}
              >
                Devices/Filters
              </Table.Cell>
              <Table.Cell
                style={{
                  borderBottom: "1px solid #3B3F43",
                }}
              >
                When to Trigger
              </Table.Cell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {phasesData.flatMap((phase) => (
              <Table.Row key={phase.id}>
                {isPhasedRollout && <Table.Cell>{phase.name}</Table.Cell>}
                <Table.Cell>{getPhasesDeviceCount(phase)}</Table.Cell>
                <Table.Cell>
                  {phase.trigger_on.timestamp
                    ? phase.trigger_on.timestamp?.toLocaleString("en-GB")
                    : "Immediately"}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    </NewActionWrapper>
  );
};

export default SummaryComponent;
