import React, { useState, useEffect, useCallback } from "react";
import { Table, Button, Grid } from "semantic-ui-react";
import { ButtonIcon, DisplayIf } from "../../../util";
import { ErrorMessage } from "../../../../common/ErrorMessage";
import {
  fetchAllDeviceConfiguration,
  DeviceConfigurationType,
} from "../../../../../BytebeamClient";
import CreateGeofenceConfigurationsModal from "./CreateGeofenceConfigurationsModal";
import CreateDeviceConfigurationsModal from "./CreateDeviceConfigurationsModal";
import DeactivateConfigModal from "./DeactivateConfigModal";
import LoadingAnimation from "../../../../common/Loader";
import Toggle from "../../../../common/Toggle";
import BackupIcon from "../../../../../assets/svg/BackupIcon";
import { useUser } from "../../../../../context/User.context";

export enum DeviceConfigurationsOperationType {
  Create = "Create",
  Edit = "Edit",
  View = "View",
}

interface DeviceConfigurationProps {
  type: "json" | "geofence";
}

function DeviceConfigurations(props: DeviceConfigurationProps) {
  const { user } = useUser();
  const permissions = user?.role?.permissions ?? {};
  const theme = user?.settings?.theme ?? "dark";
  const { type } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [showDeactivated, setShowDeactivated] = useState<boolean>(false);
  const [allExistingDeviceConfigurations, setAllExistingDeviceConfigurations] =
    useState<DeviceConfigurationType[]>([]);
  const [deviceConfigurations, setDeviceConfigurations] = useState<
    DeviceConfigurationType[]
  >([]);
  const [
    deactivateDeviceConfigModalIsOpen,
    setDeactivateDeviceConfigModalIsOpen,
  ] = useState<boolean>(false);
  const [createDeviceConfigModalIsOpen, setCreateDeviceConfigModalIsOpen] =
    useState<boolean>(false);
  const [version, setVersion] = useState<string>("");
  const [configuration, setConfiguration] = useState<DeviceConfigurationType>({
    action_type: "",
    tenant_id: "",
    is_deactivated: false,
    version_name: "",
    config_json: "",
  });
  const [errorOccurred, setErrorOccurred] = useState<boolean>(false);
  const [operationType, setOperationType] = useState<string>(
    DeviceConfigurationsOperationType.Create
  );

  const [triggerOpen, setTriggerOpen] = useState<boolean>(false);

  // Create DeviceConfig Modal
  const openCreateDeviceConfigModal = () => {
    setCreateDeviceConfigModalIsOpen(true);
    setTriggerOpen((value) => !value);
  };
  const closeCreateDeviceConfigModal = () => {
    setCreateDeviceConfigModalIsOpen(false);
  };

  // Deactivate DeviceConfig Modal
  const openActivateDeviceConfigModal = () => {
    setDeactivateDeviceConfigModalIsOpen(true);
  };
  const closeActivateDeviceConfigModal = () => {
    setDeactivateDeviceConfigModalIsOpen(false);
  };

  const fillConfigsTable = useCallback(
    async (showDeactivatedDeviceConfigs?: boolean) => {
      setLoading(true);
      try {
        const configs = await fetchAllDeviceConfiguration();
        setAllExistingDeviceConfigurations(configs);

        let filteredConfigurations = configs.filter(
          // eslint-disable-next-line array-callback-return
          (config) => {
            const showConfig =
              typeof showDeactivatedDeviceConfigs === "boolean"
                ? showDeactivatedDeviceConfigs
                : showDeactivated;

            if (type === "json") {
              return (
                (showConfig || !config.is_deactivated) &&
                (config.action_type === "update_config" ||
                  config.action_type === null)
              );
            } else if (type === "geofence") {
              return (
                (showConfig || !config.is_deactivated) &&
                config.action_type === "update_geofence"
              );
            }
          }
        );

        setDeviceConfigurations(filteredConfigurations);
      } catch (e) {
        setErrorOccurred(true);
      } finally {
        setLoading(false);
      }
    },
    [showDeactivated, type]
  );

  async function handleShowDeactivatedToggle() {
    setShowDeactivated(!showDeactivated);
    await fillConfigsTable(!showDeactivated);
  }

  useEffect(() => {
    fillConfigsTable();
  }, [fillConfigsTable]);

  if (errorOccurred) {
    return <ErrorMessage marginTop="30px" errorMessage />;
  }
  if (loading) {
    if (type === "json") {
      return (
        <LoadingAnimation
          loaderContainerHeight="65vh"
          fontSize="1.5rem"
          loadingText="Loading JSON Configurations"
        />
      );
    } else {
      return (
        <LoadingAnimation
          loaderContainerHeight="65vh"
          fontSize="1.5rem"
          loadingText="Loading Geofence Configurations"
        />
      );
    }
  }

  const CreateConfigurationModal =
    type === "json"
      ? CreateDeviceConfigurationsModal
      : CreateGeofenceConfigurationsModal;

  if (
    type === "geofence" &&
    !permissions.allowedActions.includes("update_geofence")
  ) {
    return (
      <div
        style={{
          height: "60vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ErrorMessage
          marginTop="30px"
          message={`Please Create "update_geofence" Action OR Contact Admin to get access of this action type`}
        />
      </div>
    );
  }

  return (
    <Grid>
      {/* Modal for deactivating Device config */}
      <DeactivateConfigModal
        config_type={type}
        isOpen={deactivateDeviceConfigModalIsOpen}
        onClose={closeActivateDeviceConfigModal}
        version={version}
        fillConfigsTable={fillConfigsTable}
      />

      {/* Modal for creating Device config */}
      <CreateConfigurationModal
        name={configuration?.version_name}
        type={operationType}
        setConfig={(value) => setConfiguration(value)}
        changeType={(type) => setOperationType(type)}
        config={configuration}
        isOpen={createDeviceConfigModalIsOpen}
        close={closeCreateDeviceConfigModal}
        allExistingDeviceConfigurations={allExistingDeviceConfigurations}
        fillConfigsTable={fillConfigsTable}
        theme={theme}
        triggerOpen={triggerOpen}
        setTriggerOpen={(value) => setTriggerOpen(value)}
      />

      <Grid.Row>
        <Grid.Column width="3" verticalAlign="middle">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "12px",
              marginTop: "16px",
              fontWeight: "bold",
            }}
          >
            <div>Show deactivated</div>
            <div>
              <Toggle
                id="showDeactivatedConfig"
                checked={showDeactivated}
                onClick={async () => await handleShowDeactivatedToggle()}
                style={{
                  top: "3px",
                }}
              />
            </div>
          </div>
        </Grid.Column>
        <Grid.Column floated="right" width="3">
          <DisplayIf cond={permissions.editDeviceConfigs}>
            <Button
              primary
              floated="right"
              onClick={() => {
                openCreateDeviceConfigModal();
                setOperationType(DeviceConfigurationsOperationType.Create);
              }}
              style={{
                display: "flex",
                alignItems: "center",
                gap: "18px",
                whiteSpace: "nowrap",
              }}
            >
              <BackupIcon height="16px" altColor="#fff" />
              {type === "json"
                ? "Create JSON Config"
                : "Create Geofence Config"}
            </Button>
          </DisplayIf>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell textAlign="center">
                  Version Name
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="center">Status</Table.HeaderCell>
                <DisplayIf cond={permissions.editDeviceConfigs}>
                  <Table.HeaderCell textAlign="center">
                    Options
                  </Table.HeaderCell>
                </DisplayIf>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {deviceConfigurations.length !== 0 ? (
                deviceConfigurations.map((config) => (
                  <Table.Row key={config.version_name}>
                    <Table.Cell textAlign="center">
                      {config.version_name}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      {config.is_deactivated ? "Deactivated" : "Active"}
                    </Table.Cell>
                    <DisplayIf cond={permissions.editDeviceConfigs}>
                      <Table.Cell
                        textAlign="center"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "18px",
                        }}
                      >
                        <ButtonIcon
                          name="eye"
                          title="View Config"
                          onClick={() => {
                            openCreateDeviceConfigModal();
                            setOperationType(
                              DeviceConfigurationsOperationType.View
                            );
                            setVersion(config.version_name);
                            setConfiguration(config);
                          }}
                        />
                        <ButtonIcon
                          name="edit"
                          title="Edit Config"
                          disabled={config.is_deactivated}
                          style={{
                            cursor: config.is_deactivated
                              ? "default"
                              : "pointer",
                          }}
                          onClick={() => {
                            openCreateDeviceConfigModal();
                            setOperationType(
                              DeviceConfigurationsOperationType.Edit
                            );
                            setVersion(config.version_name);
                            setConfiguration(config);
                          }}
                        />
                        <ButtonIcon
                          name="ban"
                          title={
                            config.is_deactivated ? "" : "Deactivate Config"
                          }
                          style={{
                            cursor: config.is_deactivated
                              ? "default"
                              : "pointer",
                          }}
                          color={config.is_deactivated ? "grey" : "orange"}
                          disabled={config.is_deactivated}
                          onClick={() => {
                            openActivateDeviceConfigModal();
                            setVersion(config.version_name);
                          }}
                        />
                      </Table.Cell>
                    </DisplayIf>
                  </Table.Row>
                ))
              ) : (
                <Table.Row>
                  <Table.Cell textAlign="center" colSpan={"3"}>
                    <ErrorMessage
                      message={`No ${
                        type === "json" ? "Device" : "Geofence"
                      } Configurations Found!`}
                    />
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

export default DeviceConfigurations;
