import uuid from "uuid";
import { PanelDef } from "../PanelDef";
import { ViewAlerts } from "./ViewAlerts";
import { EditAlertsMeta } from "./EditAlertsMeta";
import { AlertRule, AlertRuleCriticality } from "../../../../../util";

export class AlertsMetaData {
  type = "alerts";
  id: string = uuid.v4();
  title: string = "";
  description: string = "";
  tabbedView: boolean = false;
}

export type Alert = {
  device_id: number;
  start_time: number;
  end_time: number;
  id: string;
  alert_rule: {
    id: string;
    name: string;
    criticality: AlertRuleCriticality;
  };
};

export type AlertsData = {
  alerts: Alert[];
  alertRules: AlertRule[];
  errors: string[];
};

export const AlertsPanelDef: PanelDef<AlertsMetaData, AlertsData> = {
  ViewComponent: ViewAlerts,
  EditComponent: EditAlertsMeta,
  formatData: (data) => data,
  metaConstructor: AlertsMetaData,
  previewAspectRatio: { width: 500, height: 300 },
};
