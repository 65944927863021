import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  fetchAction,
  triggerDeviceAction,
} from "../../../../../../BytebeamClient";
import { Mixpanel } from "../../../../common/MixPanel";
import { ErrorMessage } from "../../../../../common/ErrorMessage";
import LoadingAnimation from "../../../../../common/Loader";

export const IframeContainer = styled.div<{ isFullScreen: boolean }>`
  width: 100%;
  height: ${(props) => (props.isFullScreen ? "100vh" : "500px")};
`;

interface CreateSessionResponse {
  peer1_key: string;
  peer2_key: string;
}

export enum RemoteShellStatus {
  CreatingSession,
  FailedToCreateSession,
  TriggeringDeviceAction,
  FailedToTriggerAction,
  FailedToGetActionStatus,
  WaitingForDeviceResponse,
  DeviceActionFailed,
  Success,
}

export const fetchSessionKeys = async () => {
  const res = await fetch("/api/v1/tunshell_session_keys", {
    method: "POST",
    headers: {
      origin: "https://tunshell.com",
    },
  });

  if (res.status === 200) {
    const keys = await res.json();
    const randomized = randomizeSessionKeys(keys);
    const encryptionSecret = generateEncryptionSecret();

    return {
      targetKey: randomized["peer1_key"],
      localKey: randomized["peer2_key"],
      encryptionSecret: encryptionSecret,
    };
  }

  return null;
};

export const triggerRemoteShellAction = async (deviceId, sessionKeys) => {
  try {
    const body = {
      device_ids: [deviceId.toString()],
      search_type: "default",
      action: "launch_shell",
      params: {
        session: sessionKeys.targetKey,
        encryption: sessionKeys.encryptionSecret,
        relay: "tunnelshell.bytebeam.io",
      },
    };
    const res = await triggerDeviceAction(body);
    Mixpanel.track("Triggered Action", {
      action: "Shell access",
      device_id: deviceId.toString(),
    });
    return res["id"];
  } catch (e) {
    console.log(e);
  }
};

const randomizeSessionKeys = (
  keys: CreateSessionResponse
): CreateSessionResponse => {
  const flip = Math.random() >= 0.5;

  return flip
    ? {
        peer1_key: keys.peer2_key,
        peer2_key: keys.peer1_key,
      }
    : keys;
};

const generateEncryptionSecret = (): string => {
  const alphanumeric =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";

  const gen = (len: number): string => {
    const buff = new Uint8Array(len);
    window.crypto.getRandomValues(buff);
    let out = "";

    for (let i = 0; i < len; i++) {
      out += alphanumeric[buff[i] % alphanumeric.length];
    }

    return out;
  };

  return gen(22);
};

type RemoteShellProps = {
  readonly deviceId: number;
  readonly isFullScreen?: boolean;
  readonly setActionId: (actionId: any) => void;
};

function RemoteShell(props: RemoteShellProps) {
  const { deviceId, isFullScreen, setActionId } = props;

  const [status, setStatus] = useState(RemoteShellStatus.CreatingSession);
  const [sessionKeys, setSessionKeys] = useState({
    localKey: "",
    encryptionSecret: "",
  });

  useEffect(() => {
    setStatus(RemoteShellStatus.CreatingSession);

    let interval: any;

    const createSession = async () => {
      if (deviceId > 0) {
        const sessionKeys = await fetchSessionKeys();
        let actionId = -1;

        if (sessionKeys) {
          try {
            actionId = await triggerRemoteShellAction(deviceId, sessionKeys);
            setActionId(actionId);
          } catch (e) {
            console.log("Failed to trigger action", e);
            setStatus(RemoteShellStatus.FailedToTriggerAction);
            return;
          }
          setStatus(RemoteShellStatus.WaitingForDeviceResponse);

          interval = setInterval(async () => {
            try {
              const action = await fetchAction(actionId);
              if (action.statuses["ShellSpawned"]) {
                setStatus(RemoteShellStatus.Success);
                clearInterval(interval);
              }
              if (action.statuses["Failed"]) {
                setStatus(RemoteShellStatus.DeviceActionFailed);
                clearInterval(interval);
              }
            } catch (e) {
              console.log("Failed to trigger action", e);
              setStatus(RemoteShellStatus.FailedToGetActionStatus);
              return;
            }
          }, 2000);

          setSessionKeys({
            localKey: sessionKeys.localKey,
            encryptionSecret: sessionKeys.encryptionSecret,
          });
        } else {
          setStatus(RemoteShellStatus.FailedToCreateSession);
        }
      }
    };

    createSession();

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [deviceId]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderShell = () => {
    const url = `https://tunnelshell.bytebeam.io:3000/term.html#${sessionKeys.localKey},${sessionKeys.encryptionSecret},tunnelshell.bytebeam.io`;

    return (
      <IframeContainer
        isFullScreen={isFullScreen !== undefined ? isFullScreen : true}
      >
        <iframe
          title="Remote Shell"
          src={url}
          height="100%"
          width="100%"
          style={{ border: "none" }}
        />
      </IframeContainer>
    );
  };

  const renderLoading = (text: string, failed: boolean) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
        }}
      >
        {failed ? (
          <ErrorMessage
            errorMessage
            message={text}
            marginTop="10px"
            marginBottom="10px"
            iconSize="large"
          />
        ) : (
          <LoadingAnimation loadingText={text} loaderSize="42px" />
        )}
      </div>
    );
  };

  const renderContents = () => {
    switch (status) {
      case RemoteShellStatus.CreatingSession:
        return renderLoading("Generating session keys", false);

      case RemoteShellStatus.FailedToCreateSession:
        return renderLoading(
          "Failed to create session, Please close the window and retry",
          true
        );

      case RemoteShellStatus.TriggeringDeviceAction:
        return renderLoading("Sending session to the device", false);

      case RemoteShellStatus.FailedToTriggerAction:
        return renderLoading(
          "Failed to send session to device, Please close the window and retry",
          true
        );

      case RemoteShellStatus.FailedToGetActionStatus:
        return renderLoading(
          "Failed to get status from device, Please close the window and retry",
          true
        );

      case RemoteShellStatus.WaitingForDeviceResponse:
        return renderLoading("Waiting for device to start session", false);

      case RemoteShellStatus.DeviceActionFailed:
        return renderLoading(
          "Device Action failed, Please close the window and retry",
          true
        );

      case RemoteShellStatus.Success:
        return renderShell();
    }
  };

  return <div>{renderContents()}</div>;
}

export default RemoteShell;
