import { IframeData, IframeMetaData } from "./PanelDef";
import React from "react";
import { PanelViewComponent } from "../PanelDef";

export class ViewIframe extends PanelViewComponent<IframeMetaData, IframeData> {
  getURL() {
    return this.props.panelMeta.url;
  }

  isValidURL(url) {
    try {
      new URL(url);
      return true;
    } catch (error) {
      console.log("URL is invalid, ", error);
      return false;
    }
  }

  render() {
    const url = this.getURL();
    if (this.isValidURL(url)) {
      return (
        <iframe
          title="iframe_panel"
          style={{ width: "100%", height: "100%" }}
          src={url}
        />
      );
    } else {
      return <div className="panel-no-data">No Data</div>;
    }
  }
}
