import React, { useState, useRef, useEffect } from "react";
import { Accordion, Button, Icon, Image, Input } from "semantic-ui-react";
import styled from "styled-components";
import ThemeSchema from "../../../../../theme/schema";
import { beamtoast } from "../../../../common/CustomToast";
import { updateTenantSettings } from "../../../../../BytebeamClient";
import { capitalizeFirstLetter, Row } from "../../../util";
import { ModuleStyledAccordian } from "../ModulePermissions";
import { StyledHeader } from "../../../Actions/ActionsV3/SelectableItem";
import { TenantSettings, User } from "../../../../../util";
import { ThinDivider } from "../../../Dashboards/Panel/util";

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  margin-bottom: 20px;
`;

const StyledFileUploadInput = styled(Input)`
  max-width: 300px;
  margin-top: 10px;
  background: ${(props) =>
    props.theme.colors["select_file_button-background"]} !important;
  border: ${(props) =>
    props.theme.colors["select_file_button-border"]} !important;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};

  input {
    cursor: pointer;
    font-weight: 600;
  }

  input::file-selector-button {
    background-color: transparent;
    margin-right: 1rem;
    font-weight: 400;
    cursor: pointer;
    color: ${({ theme }) => theme.colors["text"]};
    border-radius: 3px;
    padding: 0.4rem;
    border: ${(props) =>
      props.theme.colors["select_file_button-border"]}80 !important;
  }
`;

const ResetButton = styled(Button)`
  border: ${({ theme }) => theme.colors["reset-logo-button-border"]} !important;
`;

type Dimensions = {
  width: number;
  height: number;
};

type LogoAndFaviconImageSettingsProps = {
  user: User;
  getCurrentUser: () => Promise<void>;
  currentTenant: string;
  settingsType: "logo" | "favicon";
  defaultLightImage: string;
  defaultDarkImage: string;
  fileSizeLimit: number;
  note: string;
  allowGif?: boolean;
  maxDimensions?: Dimensions;
};

const LogoAndFaviconImageSettings = (
  props: LogoAndFaviconImageSettingsProps
) => {
  const {
    user,
    getCurrentUser,
    currentTenant,
    settingsType,
    defaultLightImage,
    defaultDarkImage,
    fileSizeLimit,
    note,
    maxDimensions,
    allowGif = true,
  } = props;

  const [isSettingsActive, setIsSettingsActive] = useState<boolean>(true);

  const handleAccordianClick = () => {
    setIsSettingsActive((prev) => !prev);
  };

  const tenantSettings = user?.["tenant-settings"] ?? ({} as TenantSettings);
  const currentLightImage =
    tenantSettings?.[settingsType]?.light || defaultLightImage;
  const currentDarkImage =
    tenantSettings?.[settingsType]?.dark || defaultDarkImage;

  const [lightImage, setLightImage] = useState<string>(currentLightImage);
  const [darkImage, setDarkImage] = useState<string>(currentDarkImage);

  const [resetImage, setResetImage] = useState<boolean>(false);
  const [imageUpdated, setImageUpdated] = useState<boolean>(false);

  const lightImgRef = useRef<HTMLInputElement>(null);
  const darkImgRef = useRef<HTMLInputElement>(null);

  const resetFileInputs = (type?: string) => {
    // Resetting the input value to an empty string
    const resetLight = !type || type === "light";
    const resetDark = !type || type === "dark";

    if (resetLight && lightImgRef.current) {
      lightImgRef.current.value = "";
    }

    if (resetDark && darkImgRef.current) {
      darkImgRef.current.value = "";
    }

    if (!darkImgRef.current?.value && !lightImgRef.current?.value) {
      setImageUpdated(false);
    }
  };

  const handleFileChange = (event, type) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    if (!file) {
      if (type === "light") {
        setLightImage(currentLightImage);
      } else {
        setDarkImage(currentDarkImage);
      }
      resetFileInputs(type);
      return;
    }

    if (!file.type.startsWith("image/")) {
      beamtoast.error("Only image type files are allowed!");
      resetFileInputs(type);
      return;
    }

    // TIFF format is not allowed
    if (file.type === "image/tiff") {
      beamtoast.error(`TIFF format is not allowed.`);
      resetFileInputs(type);
      return;
    }

    // Check if the file is a GIF, and if GIF is allowed.
    if (!allowGif && file.type === "image/gif") {
      beamtoast.error(`GIF format is not allowed for ${settingsType}`);
      resetFileInputs(type);
      return;
    }

    reader.onloadend = () => {
      const image = reader.result as string;
      const fileSizeInBytes = file.size;

      // Check if the file size is greater than the limit
      if (fileSizeInBytes > fileSizeLimit) {
        beamtoast.error(
          `Image size should be less than ${fileSizeLimit / 1024}KB. Uploaded image is ${fileSizeInBytes} bytes`
        );
        resetFileInputs(type);
        return;
      }

      const img = document.createElement("img");
      img.onload = () => {
        // Check if the image dimensions are greater than the limit dimensions, if they are provided.
        if (
          maxDimensions &&
          (img.width > maxDimensions.width || img.height > maxDimensions.height)
        ) {
          beamtoast.error(
            `Image dimensions should be ${maxDimensions.width}x${maxDimensions.height} pixels or less.`
          );
          resetFileInputs(type);
          return;
        }

        if (type === "light") {
          setLightImage(image);
        } else {
          setDarkImage(image);
        }

        setImageUpdated(true);
      };
      img.src = image;
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  // Reset the image to the default image
  const handleReset = () => {
    resetFileInputs();

    setLightImage(resetImage ? currentLightImage : defaultLightImage);
    setDarkImage(resetImage ? currentDarkImage : defaultDarkImage);
    setImageUpdated(!resetImage);
    setResetImage(!resetImage);
  };

  const handleSave = async () => {
    try {
      setImageUpdated(false);
      setResetImage(false);

      // Update the tenant settings, with the new logo images based on the settings type.
      const res = await updateTenantSettings({
        settings: {
          ...tenantSettings,
          [settingsType]: {
            light: lightImage || "",
            dark: darkImage || "",
          },
        },
      });

      if (res) {
        await getCurrentUser();
        beamtoast.success(
          `${capitalizeFirstLetter(settingsType)} updated successfully`
        );
        window.localStorage.setItem(
          `${currentTenant}Light${capitalizeFirstLetter(settingsType)}`,
          lightImage
        );
        window.localStorage.setItem(
          `${currentTenant}Dark${capitalizeFirstLetter(settingsType)}`,
          darkImage
        );
      }
    } catch (err) {
      beamtoast.error(
        `Error occurred when updating ${capitalizeFirstLetter(settingsType)}`
      );
    } finally {
      resetFileInputs();
    }
  };

  // Update the image state when the tenant settings are updated.
  useEffect(() => {
    setLightImage(tenantSettings?.[settingsType]?.light || defaultLightImage);
    setDarkImage(tenantSettings?.[settingsType]?.dark || defaultDarkImage);
  }, [tenantSettings, settingsType, defaultLightImage, defaultDarkImage]);

  return (
    <ModuleStyledAccordian>
      <Accordion fluid>
        <Accordion.Title
          active={isSettingsActive}
          onClick={handleAccordianClick}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <StyledHeader
              as="h3"
              style={{
                margin: "0px",
                fontSize: "1.1rem",
                whiteSpace: "nowrap",
              }}
            >
              Customize {capitalizeFirstLetter(settingsType)}
            </StyledHeader>
            <Icon
              style={{ fontSize: "24px" }}
              name={isSettingsActive ? "angle down" : "angle right"}
            />
          </div>
        </Accordion.Title>
        <Accordion.Content active={isSettingsActive}>
          <ThinDivider style={{ margin: "15px 0px 15px 0px" }} />

          <Row>
            <LogoContainer>
              <div>Light</div>
              <Image
                id={`${settingsType}_light`}
                src={lightImage}
                height="30px"
                centered
                style={{
                  backgroundColor:
                    ThemeSchema.data.light.colors["navbar-background"],
                  marginBottom: "10px",
                  marginTop: "10px",
                }}
              />
              <StyledFileUploadInput disabled={resetImage}>
                <input
                  ref={lightImgRef}
                  type="file"
                  id="lightImg"
                  accept="image/*"
                  onChange={(e) => handleFileChange(e, "light")}
                />
              </StyledFileUploadInput>
            </LogoContainer>
            <LogoContainer>
              <div>Dark</div>
              <Image
                id={`${settingsType}_dark`}
                src={darkImage}
                height="30px"
                centered
                style={{
                  backgroundColor:
                    ThemeSchema.data.dark.colors["navbar-background"],
                  marginBottom: "10px",
                  marginTop: "10px",
                }}
              />
              <StyledFileUploadInput disabled={resetImage}>
                <input
                  ref={darkImgRef}
                  type="file"
                  id="darkImg"
                  accept="image/*"
                  onChange={(e) => handleFileChange(e, "dark")}
                />
              </StyledFileUploadInput>
            </LogoContainer>
          </Row>

          <div>{note}</div>
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              gap: "10px",
              justifyContent: "flex-end",
            }}
          >
            <ResetButton
              id="reset_btn"
              secondary
              type="button"
              onClick={handleReset}
            >
              {resetImage ? "Cancel" : "Reset"}
            </ResetButton>
            <Button
              id="save_btn"
              type="button"
              disabled={!imageUpdated}
              onClick={handleSave}
              primary
            >
              Save Changes
            </Button>
          </div>
        </Accordion.Content>
      </Accordion>
    </ModuleStyledAccordian>
  );
};

export default LogoAndFaviconImageSettings;
