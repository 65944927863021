import React, { Component } from "react";
import { Dropdown, Button, Modal, Icon } from "semantic-ui-react";
import { fetchAllFirmwares } from "../../../../../BytebeamClient";
import { DropdownOptionType } from "./UpdateConfigModal";
import { beamtoast } from "../../../../common/CustomToast";

type UpdateFirmwareModalProps = {
  isOpen: boolean;
  close: (...args: any[]) => any;
  triggerAction: (
    arg1: string,
    arg2: { id?: string; version?: string },
    ...args: any[]
  ) => any;
  allSelected: boolean;
  selectedDevicesCount: number;
};

type UpdateFirmwareModalState = {
  options: DropdownOptionType[];
  selectedOption: string;
  status: number;
  loadingDropdown: boolean;
};

enum UpdateFirmwareVersionStatus {
  SelectFirmwareVersion,
  TriggerUpdateDeviceFirmware,
}

export class UpdateFirmwareModal extends Component<
  UpdateFirmwareModalProps,
  UpdateFirmwareModalState
> {
  state = {
    options: [],
    selectedOption: "",
    status: UpdateFirmwareVersionStatus.SelectFirmwareVersion,
    loadingDropdown: true,
  };

  async fillDropdownOptions() {
    try {
      let res = await fetchAllFirmwares();
      res = res.filter((o) => !o.is_deactivated);
      const options = res.map((o, index) => {
        return {
          key: `${o.tenant_id}-${index}`,
          value: o.version_number,
          text: o.version_number,
        };
      });
      this.setState({ options });
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({ loadingDropdown: false });
    }
  }

  onSelect = async (e, data) => {
    this.setState({
      selectedOption: data.value,
    });
  };

  // Reset state and close modal
  onCloseModal = () => {
    this.setState({
      options: [],
      selectedOption: "",
      status: UpdateFirmwareVersionStatus.SelectFirmwareVersion,
      loadingDropdown: true,
    });
    this.props.close();
  };

  render() {
    const { isOpen } = this.props;
    return (
      <Modal
        open={isOpen}
        size="tiny"
        className="dark"
        onMount={this.fillDropdownOptions.bind(this)}
        onClose={this.onCloseModal}
      >
        <Modal.Header>Update Device Firmware</Modal.Header>
        <Modal.Content>
          {this.state.status ===
            UpdateFirmwareVersionStatus.SelectFirmwareVersion && (
            <Modal.Description>
              <p>Select firmware version:</p>
              <Dropdown
                placeholder="Select Version"
                fluid
                selection
                search
                options={this.state.options}
                onChange={this.onSelect}
                value={this.state.selectedOption}
                loading={this.state.loadingDropdown !== false}
                disabled={this.state.loadingDropdown}
              />
            </Modal.Description>
          )}
          {this.state.status ===
            UpdateFirmwareVersionStatus.TriggerUpdateDeviceFirmware && (
            <Modal.Description>
              <div style={{ fontSize: "16px", marginBottom: "16px" }}>
                Are you ready to deploy{" "}
                <b>firmware version {this.state.selectedOption}</b> to{" "}
                <b>
                  {this.props.allSelected
                    ? // ? `All (${this.props.selectedDevicesCount} devices)` TODO: Fix this once device count on search is available
                      `All devices`
                    : `${
                        this.props.selectedDevicesCount === 1
                          ? "1 device"
                          : `${this.props.selectedDevicesCount} devices`
                      }`}
                </b>{" "}
                ?
              </div>
              This action will update the firmware on all selected devices.
              Please click on <b>Update</b> button if you would like to proceed
              with the deployment, else click on <b>Discard</b> button.
            </Modal.Description>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            secondary
            onClick={() => {
              this.onCloseModal();
              this.setState({
                status: UpdateFirmwareVersionStatus.SelectFirmwareVersion,
              });
            }}
          >
            <Icon name="remove" /> Discard
          </Button>
          <Button
            primary
            onClick={() => {
              if (
                this.state.status ===
                UpdateFirmwareVersionStatus.SelectFirmwareVersion
              ) {
                if (this.state.selectedOption === "") {
                  beamtoast.error("Please select a Firmware version");
                } else {
                  this.setState({
                    status:
                      UpdateFirmwareVersionStatus.TriggerUpdateDeviceFirmware,
                  });
                }
              } else {
                this.props.triggerAction("update_firmware", {
                  version: this.state.selectedOption,
                });
                this.onCloseModal();
              }
            }}
          >
            {this.state.status ===
            UpdateFirmwareVersionStatus.SelectFirmwareVersion ? (
              <>
                Next <Icon name="angle right" />
              </>
            ) : (
              <>
                <Icon name="checkmark" /> Update
              </>
            )}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
