import React, { useEffect, useState, useMemo } from "react";
import { Accordion, Dropdown, Icon } from "semantic-ui-react";
import styled from "styled-components";
import { User } from "../../../../util";
import {
  fetchAllMetadataKeys,
  markMetadataSerialKey,
  unmarkMetadataSerialKey,
} from "../../../../BytebeamClient";
import { beamtoast } from "../../../common/CustomToast";
import { ModuleStyledAccordian } from "./ModulePermissions";
import { StyledHeader } from "../../Actions/ActionsV3/SelectableItem";
import { ThinDivider } from "../../Dashboards/Panel/util";

type MetadataItem = { key: string; value: string; text: string };

type SerialMetadataProps = {
  user: User;
  getCurrentUser: () => Promise<void>;
};

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-top: 35px;
`;

const Label = styled.p`
  flex: 1;
  font-weight: 700;
  margin: 0px 10px 0px 0px;
`;

const EditableSection = styled.div`
  flex: 1;
  display: flex;
`;

const StyledDropdown = styled(Dropdown)`
  &&& {
    min-width: 200px;
    max-width: 400px;
  }
`;

const IconContainer = styled.div`
  margin-left: 10px;
  gap: 6px;
  display: flex;
  align-items: center;
`;

const SerialMetadata = ({ user, getCurrentUser }: SerialMetadataProps) => {
  const userSerialKey = user?.["tenant-settings"]?.["serial-key"];

  const [metaDataItems, setMetaDataItems] = useState<MetadataItem[]>([]);
  const [dropdownLoading, setDropdownLoading] = useState<boolean>(false);
  const [dropdownDisabled, setDropdownDisabled] = useState(false);
  const [newSerialKey, setNewSerialKey] = useState(userSerialKey);
  const [editMode, setEditMode] = useState(false);

  const [isSerialKeyAccordianActive, setIsSerialKeyAccordianActive] =
    useState<boolean>(true);

  const handleSerialKeyAccordianClick = () => {
    setIsSerialKeyAccordianActive((prev) => !prev);
  };

  const fetchAllMetadataKeysfunct = async () => {
    try {
      setDropdownLoading(true);
      const res = await fetchAllMetadataKeys();
      const dropdownItems = res.map((item: any) => ({
        key: item.key,
        value: item.key,
        text: item.key,
      }));
      setMetaDataItems(dropdownItems);
    } catch (error) {
      console.error("Failed to fetch metadata keys:", error);
    } finally {
      setDropdownLoading(false);
    }
  };

  const updateSerialKey = async (metadataKey: string) => {
    try {
      setDropdownDisabled(true);
      if (userSerialKey) {
        await unmarkMetadataSerialKey(userSerialKey);
        if (metadataKey === "")
          beamtoast.success(`Removed '${userSerialKey}' as Serial Key`);
      }
      if (metadataKey) {
        await markMetadataSerialKey(metadataKey);
        beamtoast.success(`Updated Serial Key: '${metadataKey}'`);
      }
    } catch (error: any) {
      const errorMessage = error?.response?.data?.error;

      // Handling different error messages
      if (errorMessage?.includes("null values not allowed")) {
        beamtoast.error("Metadata contains null values.");
      } else if (errorMessage?.includes("values should be unique")) {
        beamtoast.error("Metadata values should be unique.");
      } else {
        beamtoast.error("Failed to update serial key.");
      }
    } finally {
      await getCurrentUser();
      setDropdownDisabled(false);
      setEditMode(false); // Exiting edit mode after successful update
    }
  };

  useEffect(() => {
    fetchAllMetadataKeysfunct();
  }, []);

  const handleSave = () => {
    updateSerialKey(newSerialKey || "");
  };

  const handleCancel = () => {
    setNewSerialKey(userSerialKey);
    setEditMode(false);
  };

  const memoizedOptions = useMemo(() => {
    return metaDataItems.map((item) => ({
      key: item.key,
      value: item.value,
      text: item.text,
    }));
  }, [metaDataItems]);

  return (
    <ModuleStyledAccordian>
      <Accordion fluid>
        <Accordion.Title
          active={isSerialKeyAccordianActive}
          onClick={handleSerialKeyAccordianClick}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <StyledHeader
              as="h3"
              style={{
                margin: "0px",
                fontSize: "1.1rem",
                whiteSpace: "nowrap",
              }}
            >
              Serial Key
            </StyledHeader>
            <Icon
              style={{ fontSize: "24px" }}
              name={isSerialKeyAccordianActive ? "angle down" : "angle right"}
            />
          </div>
        </Accordion.Title>
        <Accordion.Content active={isSerialKeyAccordianActive}>
          <ThinDivider style={{ margin: "15px 0px 15px 0px" }} />
          <Container>
            <Label>Serial Key:</Label>
            {!editMode ? (
              <EditableSection>
                <span style={{ minWidth: "200px" }}>
                  {userSerialKey || "No Serial Key Selected"}
                </span>
                <Icon
                  link
                  name="edit"
                  onClick={() => {
                    setEditMode(true);
                    fetchAllMetadataKeysfunct();
                  }}
                  style={{ cursor: "pointer", marginLeft: "10px" }}
                />
              </EditableSection>
            ) : (
              <EditableSection>
                <StyledDropdown
                  placeholder="Select Metadata"
                  fluid
                  search
                  selection
                  clearable
                  loading={dropdownLoading}
                  value={newSerialKey}
                  disabled={dropdownDisabled}
                  options={memoizedOptions}
                  onChange={(e, { value }) => setNewSerialKey(value as string)}
                />
                <IconContainer>
                  <Icon
                    link
                    name="save"
                    disabled={newSerialKey === userSerialKey}
                    onClick={handleSave}
                  />
                  <Icon link name="cancel" onClick={handleCancel} />
                </IconContainer>
              </EditableSection>
            )}
          </Container>
        </Accordion.Content>
      </Accordion>
    </ModuleStyledAccordian>
  );
};

export default SerialMetadata;
