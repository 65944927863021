import { BarChartMetaData } from "./PanelDef";
import React from "react";

import { Tab, Accordion, Icon } from "semantic-ui-react";
import { PanelEditComponent, PartialMetaData } from "../PanelDef";
import {
  EditMetaRoot,
  EditMetaRow,
  ToggleLabel,
  ThinDivider,
  EditAnimatedMetaInput,
  EditAnimatedMetaDropdown,
  EditPanelFormContainer,
  StyledInputDiv,
} from "../util";
import { TableInfo } from "../../../../../BytebeamClient";

export type EditBarChartMetaProps = {
  panelMeta: BarChartMetaData;
  tables: TableInfo;
};

export type EditBarChartMetaState = {
  table: string;
  group: string;
  subGroup: string;
  aggregationColumn: string;
  aggregator: string;
  activeIndex: number;
  minRange?: number;
  maxRange?: number;
  errorObject: { [key: string]: string }; // this handles range error
  error: boolean; // this handles error in stream and fields
};

const aggregators = ["min", "max", "avg", "sum", "count", "last"];

export class EditBarChartMeta extends PanelEditComponent<
  BarChartMetaData,
  EditBarChartMetaState
> {
  titleRef = React.createRef<HTMLInputElement>();
  descriptionRef = React.createRef<HTMLInputElement>();
  minRangeRef = React.createRef<HTMLInputElement>();
  maxRangeRef = React.createRef<HTMLInputElement>();

  constructor(props: EditBarChartMetaProps) {
    super(props);

    if (props.panelMeta) {
      this.state = {
        table: props.panelMeta.table,
        group: props.panelMeta.group,
        subGroup: props.panelMeta.subGroup,
        aggregationColumn: props.panelMeta.aggregateColumn,
        aggregator: props.panelMeta.aggregator,
        activeIndex: 0,
        minRange: props.panelMeta.minRange,
        maxRange: props.panelMeta.maxRange,
        errorObject: {},
        error: false,
      };
    } else {
      this.state = {
        table: "",
        group: "",
        subGroup: "",
        aggregationColumn: "",
        aggregator: "",
        activeIndex: 0,
        minRange: undefined,
        maxRange: undefined,
        errorObject: {},
        error: false,
      };
    }
  }

  handleAccordianClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  getPanelMeta(type): PartialMetaData<BarChartMetaData> {
    if (
      (this.maxRangeRef.current?.value && !this.minRangeRef.current?.value) ||
      (!this.maxRangeRef.current?.value && this.minRangeRef.current?.value)
    ) {
      this.setState({
        errorObject: { errorMsg: "Both Min and Max is required" },
      });
    } else {
      this.setState({ errorObject: {} });
    }

    const meta: BarChartMetaData = {
      type: "bar_chart",
      id: this.props.panelMeta.id,
      title: this.titleRef.current?.value || "",
      description: this.descriptionRef.current?.value || "",
      table: this.state.table || "",
      group: this.state.group || "",
      subGroup: this.state.subGroup || "",
      aggregateColumn: this.state.aggregationColumn || "",
      aggregator: this.state.aggregator || "",
      minRange: this.minRangeRef.current?.value
        ? parseFloat(this.minRangeRef.current?.value)
        : undefined,
      maxRange: this.maxRangeRef.current?.value
        ? parseFloat(this.maxRangeRef.current?.value)
        : undefined,
    };

    return {
      meta: meta,
      complete: this.isValidPanelMeta(meta, type),
    };
  }

  isValidPanelMeta(meta: BarChartMetaData, type?: string) {
    // type is used here to differentiate between submit and refresh in edit mode
    if (
      !(
        !!meta.table &&
        !!meta.group &&
        !!meta.aggregateColumn &&
        !!meta.aggregator
      ) &&
      type === "submit"
    ) {
      this.setState({ error: true });
    } else if (type === "submit") {
      this.setState({ error: false });
    }
    return (
      !!meta.table &&
      !!meta.group &&
      !!meta.aggregateColumn &&
      !!meta.aggregator &&
      !!(
        ((meta.minRange || meta.minRange === 0) && !!meta.maxRange) ||
        (!(meta.minRange || meta.minRange === 0) && !meta.maxRange)
      )
    );
  }

  setTable(_event, data) {
    this.setState({
      table: data.value,
      group: "",
      aggregationColumn: "",
      aggregator: "",
    });
  }

  setGroup(_event, data) {
    this.setState({
      group: data.value,
      aggregationColumn: "",
      aggregator: "",
    });
  }

  //not used for now, hidden in UI
  setSubGroup(_event, data) {
    this.setState({
      subGroup: data.value,
      aggregationColumn: "",
      aggregator: "",
    });
  }

  setAggregationColumn(_event, data) {
    this.setState({
      aggregationColumn: data.value,
      aggregator: "",
    });
  }

  setAggregator(_event, data) {
    this.setState({
      aggregator: data.value,
    });
  }

  render() {
    const title = this.props.panelMeta.title;
    const description = this.props.panelMeta.description;
    const { activeIndex, minRange, maxRange } = this.state;

    const tableOptions = Object.keys(this.props.tables).map((t) => {
      return {
        key: t,
        text: t,
        value: t,
      };
    });

    let columnTypeOptions: Array<{ key: string; value: string; text: string }> =
      [];

    if (!!this.state.table) {
      columnTypeOptions = this.props.tables?.[this.state.table]?.map(
        (f: { name: string; type: string }) => {
          return {
            key: f.name,
            text: f.name,
            value: f.name,
          };
        }
      );
    }

    const aggregateOptions = aggregators.map((a) => {
      return {
        key: a,
        text: a,
        value: a,
      };
    });

    const panes = [
      {
        menuItem: "General",
        pane: (
          <Tab.Pane key="general">
            <EditPanelFormContainer>
              <div style={{ width: "100%", marginTop: "16px" }} />
              <EditMetaRow>
                <StyledInputDiv width="48%">
                  <EditAnimatedMetaInput
                    autoFocus={true}
                    defaultRef={this.titleRef}
                    defaultValue={title}
                    label="Title"
                  />
                </StyledInputDiv>
                <StyledInputDiv width="48%">
                  <EditAnimatedMetaInput
                    defaultRef={this.descriptionRef}
                    defaultValue={description}
                    label="Description"
                  />
                </StyledInputDiv>
              </EditMetaRow>
              <ThinDivider />
              <EditMetaRow>
                <StyledInputDiv width="48%" marginTop="10px">
                  <EditAnimatedMetaDropdown
                    placeholder="Select Stream"
                    text={this.state.table || "Select Stream"}
                    search
                    selection
                    options={tableOptions}
                    onChange={this.setTable.bind(this)}
                    defaultValue={this.state.table || ""}
                    value={this.state.table || ""}
                    elementid={`tableBarChart`}
                    error={this.state.error && !this.state.table}
                  />
                </StyledInputDiv>
                <StyledInputDiv width="48%" marginTop="10px">
                  <EditAnimatedMetaDropdown
                    placeholder="GroupBy field"
                    text={this.state.group || "GroupBy field"}
                    search
                    selection
                    disabled={this.state.table === ""}
                    options={[
                      { key: "id", value: "id", text: "id" },
                      ...(columnTypeOptions ?? []),
                    ]}
                    onChange={this.setGroup.bind(this)}
                    defaultValue={this.state.group || ""}
                    value={this.state.group || ""}
                    elementid={`groupBarChart`}
                    error={this.state.error && !this.state.group}
                  />
                </StyledInputDiv>
                {/* <StyledInputDiv width="48%" marginTop="10px">
                <EditAnimatedMetaDropdown
                  placeholder="Sub-Group(optional)"
                  text={this.state.subGroup}
                  search
                  selection
                  disabled={this.state.table === ""}
                  options={[{key: "", value: "", text: ""}, ...columnTypeOptions]}
                  onChange={this.setSubGroup.bind(this)}
                  defaultValue={this.props.panelMeta.subGroup}
                  elementid={`subGroupBarChart`}
                />
               </StyledInputDiv> */}
              </EditMetaRow>
              <ThinDivider />
              <EditMetaRow>
                <StyledInputDiv width="48%" marginTop="10px">
                  <EditAnimatedMetaDropdown
                    placeholder="Aggregated Field value"
                    text={
                      this.state.aggregationColumn || "Aggregated Field value"
                    }
                    search
                    selection
                    disabled={this.state.table === ""}
                    options={columnTypeOptions}
                    onChange={this.setAggregationColumn.bind(this)}
                    defaultValue={this.state.aggregationColumn || ""}
                    value={this.state.aggregationColumn || ""}
                    elementid={`fieldBarChart`}
                    error={this.state.error && !this.state.aggregationColumn}
                  />
                </StyledInputDiv>
                <StyledInputDiv width="48%" marginTop="10px">
                  <EditAnimatedMetaDropdown
                    placeholder="Select Aggregator"
                    text={this.state.aggregator || "Select Aggregator"}
                    search
                    selection
                    disabled={
                      this.state.group === "" ||
                      this.state.aggregationColumn === ""
                    }
                    options={aggregateOptions}
                    onChange={this.setAggregator.bind(this)}
                    defaultValue={this.state.aggregator || ""}
                    value={this.state.aggregator || ""}
                    elementid={`aggregatorBarChart`}
                    error={this.state.error && !this.state.aggregator}
                  />
                </StyledInputDiv>
              </EditMetaRow>
            </EditPanelFormContainer>
          </Tab.Pane>
        ),
      },
      {
        menuItem: "View",
        pane: (
          <Tab.Pane key={"view"}>
            <EditPanelFormContainer>
              <Accordion>
                <Accordion.Title
                  active={activeIndex === 0}
                  index={0}
                  onClick={this.handleAccordianClick}
                >
                  <Icon name="dropdown" />
                  <span style={{ fontWeight: "bold" }}>Graph Settings </span>
                </Accordion.Title>
                <Accordion.Content
                  active={activeIndex === 0}
                  style={{ paddingLeft: "16px" }}
                >
                  <div className="AxisRangeDiv">
                    <ToggleLabel
                      style={{
                        justifyContent: "space-between",
                        paddingTop: "5px",
                      }}
                    >
                      Custom Y-Axis Range
                    </ToggleLabel>
                    <div className="AxisRangeInputs">
                      <EditAnimatedMetaInput
                        autoFocus={true}
                        id="minRange"
                        defaultRef={this.minRangeRef}
                        defaultValue={minRange}
                        label="Min*"
                        type={"number"}
                      />
                    </div>
                    <div
                      style={{ width: "2%", justifyContent: "space-between" }}
                    ></div>
                    <div className="AxisRangeInputs">
                      <EditAnimatedMetaInput
                        id="maxRange"
                        defaultRef={this.maxRangeRef}
                        defaultValue={maxRange}
                        label="Max*"
                        type={"number"}
                      />
                    </div>
                  </div>
                  {this.state.errorObject?.errorMsg && (
                    <div style={{ color: "red", marginTop: "10px" }}>
                      {this.state.errorObject?.errorMsg}
                    </div>
                  )}
                </Accordion.Content>
              </Accordion>
            </EditPanelFormContainer>
          </Tab.Pane>
        ),
      },
    ];

    return (
      <EditMetaRoot>
        <Tab menu={{}} panes={panes} renderActiveOnly={false} />
      </EditMetaRoot>
    );
  }
}
