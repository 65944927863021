import { MicelioAlertsPanelData, MicelioAlertsPanelMetaData } from "./PanelDef";
import React from "react";
import { PanelViewComponent } from "../../PanelDef";
import { Column, Row } from "../../../../util";
import { alertIcons } from "../../map-util";

import styled from "styled-components";
import { Button, Divider } from "semantic-ui-react";
import moment from "moment";

import {
  fetchPanelData,
  getTenantFromURL,
} from "../../../../../../BytebeamClient";
import { StyledPagination } from "../../../../../common/commonStyledComps";
import { UserContext } from "../../../../../../context/User.context";

const AlertsContainer = styled(Row)`
  padding: 0px 8px;
  margin-top: 50px;
  height: calc(100% - 60px);
  overflow: scroll;
`;

const AlertIcon = styled.img`
  width: 50px;
  padding: 10px;
`;

const AlertTabContent = styled(Column)`
  flex-grow: 1;
  width: calc(100% - 50px);
`;

const AlertTabContentBody = styled(Column)`
  flex-grow: 1;
  padding: 0px 8px 20px;
  height: calc(100% - 120px);
  background-color: ${({ theme }) =>
    theme.colors["navbar-active-item-background-color"]} !important;
  color: ${({ theme }) =>
    theme.colors["navbar-active-item-text-color"]} !important;
`;

const AlertItems = styled(Column)`
  max-height: calc(100% - 50px);
  overflow-y: scroll;
  padding: 8px 12px;
`;

const AlertTabs = styled(Column)`
  max-width: 50px;
  height: fit-content;
  background-color: ${({ theme }) =>
    theme.colors["navbar-background"]} !important;
  color: ${({ theme }) => theme.colors["navbar-item-text-color"]} !important;
  border: 1px solid
    ${({ theme }) => theme.colors["modal-separation-line-color"]};
  box-shadow: ${({ theme }) =>
    theme.colors["navbar-vertical-menu-box-shadow-hover"]} !important;
  border-radius: 10px 0px 0px 10px !important;
  overflow: hidden;
  .item {
    color: ${({ theme }) => theme.colors["navbar-item-text-color"]} !important;
    opacity: 0.8 !important;
    border-bottom: 1px solid
      ${({ theme }) => theme.colors["modal-separation-line-color"]} !important;
    &:hover {
      color: ${({ theme }) =>
        theme.colors["navbar-item-text-hover-color"]} !important;
      background-color: ${({ theme }) =>
        theme.colors["navbar-item-background-hover-color"]} !important;
      box-shadow: ${({ theme }) =>
        theme.colors["navbar-vertical-menu-box-shadow-hover"]} !important;
      opacity: 1 !important;
    }
  }
  .active.item {
    color: ${({ theme }) =>
      theme.colors["navbar-active-item-text-color"]} !important;
    background-color: ${({ theme }) =>
      theme.colors["navbar-active-item-background-color"]} !important;
    opacity: 1 !important;

    &:hover {
      color: ${({ theme }) =>
        theme.colors["navbar-item-text-hover-color"]} !important;
      background-color: ${({ theme }) =>
        theme.colors["navbar-active-item-background-hover-color"]} !important;
      box-shadow: none !important;
    }
  }
`;

const AlertTitle = styled(Row)`
  justify-content: space-between;
  font-weight: bold;
  padding: 12px;
`;

const AlertTabContainer = styled(Column)`
  justify-content: center;
  align-items: center;
  padding-bottom: 5px;
  cursor: pointer;
`;

const AllAlertsButton = styled(Button)`
  margin: 15px 0px 12px !important;
  max-width: 150px;
  align-self: flex-end;
  min-height: fit-content;
`;

const AlertItemContainer = styled.div`
  font-size: 12px;
`;

const AlertsTitle = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
  margin-left: 5px;
`;

const AlertDetail = styled.div`
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 15px;
`;

const VehicleDetail = styled(Row)`
  margin-top: 5px;
  margin-left: 15px;
  margin-right: 15px;
  justify-content: space-between;
`;

type AlertTabProps = {
  readonly icon: { light: string; dark: string };
  readonly isActive: boolean;
  readonly numAlerts: number;
  readonly theme: string;

  readonly setAsActiveTab: () => void;
};

function AlertTab(props: AlertTabProps) {
  const icon = props.theme === "dark" ? props.icon.light : props.icon.dark;

  return (
    <AlertTabContainer
      className={`item ${props.isActive ? "active" : ""}`}
      onClick={() => props.setAsActiveTab()}
    >
      <AlertIcon src={icon} />
      <div>{props.numAlerts}</div>
    </AlertTabContainer>
  );
}

type AlertItemProps = {
  readonly deviceDashboardId?: string;
  readonly deviceId: number;
  readonly startTimestamp: Date;
  readonly endTimestamp: Date;
  readonly registrationNumber: string;
  readonly alertSubType: string | null;
  readonly hub: string | null;
};

function AlertItem(props: AlertItemProps) {
  const isAlertClosed = props.endTimestamp.getTime() < new Date().getTime();

  let url = "";

  const currentTenant = getTenantFromURL();
  if (props.deviceDashboardId) {
    url = `/projects/${currentTenant}/dashboards/${props.deviceDashboardId}?id=${props.deviceId}`;
  }

  return (
    <AlertItemContainer>
      <AlertsTitle>
        - {moment(props.startTimestamp).format("DD-MM-YY HH:mm")}
        {isAlertClosed ? (
          <span>
            &nbsp;to {moment(props.endTimestamp).format("DD-MM-YY HH:mm")}{" "}
          </span>
        ) : (
          <></>
        )}
      </AlertsTitle>

      <AlertDetail>
        {props.registrationNumber}: {props.alertSubType}
      </AlertDetail>
      <VehicleDetail>
        <div>{props.hub}</div>
        <div>{url ? <a href={url}>Locate Vehicle</a> : <></>}</div>
      </VehicleDetail>
      <Divider style={{ backgroundColor: "rgba(0, 0, 0, 0.25)" }} />
    </AlertItemContainer>
  );
}

export class ViewMicelioAlertsPanel extends PanelViewComponent<
  MicelioAlertsPanelMetaData,
  MicelioAlertsPanelData
> {
  state = {
    activeTab: 0,
    data: null,
    activePage: 1,
  };

  setActiveTab(n: number) {
    this.fetchAlerts(1, n);
  }

  async fetchAlerts(pageNumber: number, activeTab: number) {
    const alertTypes = Object.keys(alertIcons);
    const alertType = activeTab === -1 ? "All" : alertTypes[activeTab];

    const panelMeta = Object.assign({}, this.props.panelMeta, {
      page: pageNumber,
      alertType: alertType,
    });

    const response = await fetchPanelData([panelMeta], this.props.fetchParams);

    this.setState({
      data: response[0].data,
      activePage: pageNumber,
      activeTab: activeTab,
    });
  }

  render() {
    const alertTypes = Object.keys(alertIcons);
    const activeTab = this.state.activeTab;
    const activeAlertType =
      activeTab === -1 ? "All" : alertTypes[this.state.activeTab];
    const { alerts, counts } = (this.state.data || this.props.data) ?? {};
    const rowsPerPage = this.props.panelMeta.rowsPerPage || 5;

    return (
      <AlertsContainer>
        <UserContext.Consumer>
          {(userContext) => (
            <AlertTabs>
              {alertTypes.map((alertType, i) => (
                <AlertTab
                  key={`${alertType}-${i}`}
                  icon={alertIcons[alertType]}
                  isActive={this.state.activeTab === i}
                  numAlerts={counts?.[alertType]}
                  setAsActiveTab={() => this.setActiveTab(i)}
                  theme={userContext.user?.settings?.theme ?? "dark"}
                />
              ))}
              <div style={{ flexGrow: 1 }} />
            </AlertTabs>
          )}
        </UserContext.Consumer>

        <AlertTabContent>
          <AlertTabContentBody>
            <AlertTitle>
              <div>{activeAlertType}</div>
              <div>{counts?.[activeAlertType]}</div>
            </AlertTitle>

            <AlertItems>
              {alerts?.map((alert, i) => (
                <AlertItem
                  key={`${alert.metadata.registration_number}-${i}`}
                  deviceId={alert.id}
                  deviceDashboardId={this.props.panelMeta.deviceDashboardId}
                  startTimestamp={new Date(alert.startTimestamp)}
                  endTimestamp={new Date(alert.endTimestamp)}
                  hub={alert.metadata.hub}
                  registrationNumber={alert.metadata.registration_number}
                  alertSubType={alert.alertSubType}
                />
              ))}
            </AlertItems>
          </AlertTabContentBody>

          {alerts?.length !== 0 && (
            <StyledPagination
              boundaryRange={0}
              ellipsisItem={null}
              siblingRange={1}
              activePage={this.state.activePage}
              totalPages={Math.ceil(counts?.[activeAlertType] / rowsPerPage)}
              onPageChange={(_, data) =>
                this.fetchAlerts(data.activePage as number, activeTab)
              }
              style={{
                marginTop: "8px",
              }}
            />
          )}

          <AllAlertsButton onClick={() => this.fetchAlerts(1, -1)}>
            All alerts
          </AllAlertsButton>
        </AlertTabContent>
      </AlertsContainer>
    );
  }
}
