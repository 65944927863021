import React, { useEffect, useState } from "react";
import { Button, Grid, Modal } from "semantic-ui-react";
import LoadingAnimation from "../../../common/Loader";
import { DBCAdditionalSettings } from "../../../../util";

interface ViewDBCAdditionalSettingsProps {
  readonly open: boolean;
  readonly onOpen: () => void;
  readonly onClose: () => void;
  readonly additionalSettings: DBCAdditionalSettings;
}

export default function ViewDBCAdditionalSettings(
  props: ViewDBCAdditionalSettingsProps
) {
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (props.additionalSettings) setLoading(false);
    else setLoading(true);
  }, [props.additionalSettings]);

  return (
    <Modal
      className="dark"
      onClose={props.onClose}
      onOpen={props.onOpen}
      open={props.open}
    >
      <Modal.Header>DBC Parser Additional Settings</Modal.Header>
      <Modal.Content>
        {loading ? (
          <LoadingAnimation
            loaderContainerHeight="calc(100%)"
            fontSize="1.5rem"
            loadingText="Loading..."
          />
        ) : (
          <Grid>
            <Grid.Row style={{ marginTop: "1rem" }}>
              <Grid.Column width={10}>
                <p style={{ fontSize: "1.1rem" }}>
                  Add CAN ID as suffix to column names
                </p>
              </Grid.Column>
              <Grid.Column width={6}>
                <p style={{ fontSize: "1.1rem" }}>
                  {props.additionalSettings.add_suffix_canid
                    ? props.additionalSettings.add_suffix_canid === true
                      ? "true"
                      : "false"
                    : "Not set"}
                </p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row style={{ marginTop: "1rem" }}>
              <Grid.Column width={10}>
                <p style={{ fontSize: "1.1rem" }}>Disable Enum Parsing</p>
              </Grid.Column>
              <Grid.Column width={6}>
                <p style={{ fontSize: "1.1rem" }}>
                  {props.additionalSettings.dont_decode_choices
                    ? props.additionalSettings.dont_decode_choices === true
                      ? "true"
                      : "false"
                    : "Not set"}
                </p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row style={{ marginTop: "1rem" }}>
              <Grid.Column width={10}>
                <p style={{ fontSize: "1.1rem" }}>
                  Lower Threshold for parser (ms)
                </p>
              </Grid.Column>
              <Grid.Column width={6}>
                <p style={{ fontSize: "1.1rem" }}>
                  {props.additionalSettings.lower_threshold ?? "Not set"}
                </p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row style={{ marginTop: "1rem" }}>
              <Grid.Column width={10}>
                <p style={{ fontSize: "1.1rem" }}>
                  Upper Threshold for parser (ms)
                </p>
              </Grid.Column>
              <Grid.Column width={6}>
                <p style={{ fontSize: "1.1rem" }}>
                  {props.additionalSettings.upper_threshold ?? "Not set"}
                </p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row style={{ marginTop: "1rem" }}>
              <Grid.Column width={10}>
                <p style={{ fontSize: "1.1rem" }}>
                  DBC Version filter on column dbc_ver
                </p>
              </Grid.Column>
              <Grid.Column width={6}>
                <p style={{ fontSize: "1.1rem" }}>
                  {props.additionalSettings.dbc_ver ?? "Not set"}
                </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button secondary onClick={props.onClose}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
