import React, {
  useState,
  useEffect,
  createRef,
  useCallback,
  useImperativeHandle,
  useRef,
  forwardRef,
} from "react";
import {
  Accordion,
  Button,
  Checkbox,
  Dropdown,
  Form,
  Grid,
  Icon,
  Input,
  Label,
  Modal,
} from "semantic-ui-react";
import styled from "styled-components";
import {
  fetchAllStreamsWithDetails,
  uploadFile,
} from "../../../../BytebeamClient";
import {
  DBCData,
  DBCResponse,
  dropDownOptionsFromArray,
} from "../../../../util";
import { beamtoast } from "../../../common/CustomToast";
import { StyledFileUploadButton } from "../../DeviceManagement/Devices/ActionModals/SendFileModal";
import { StyledInput as StyledFileInput } from "../../Actions/action-modals/UploadFirmwareComponent";
import AnimatedEllipsis from "../../common/AnimatedEllipsis";
import { Mixpanel } from "../../common/MixPanel";
import LoadingAnimation from "../../../common/Loader";

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const StyledLabel = styled(Label)`
  margin: 0px !important;
  min-width: 80px;
`;

export const StyledInput = styled(Input)`
  margin-bottom: 14px;
  height: 36px;
  width: 100%;
`;

interface UploadDBCFileSectionProps {
  readonly onClose: () => void;
  readonly DBCData: DBCResponse[];
  readonly newDBC: DBCData;
  setNewDBCName: (arg0: string) => void;
  handleUpdate: () => void;
}

interface handleUploadDBC {
  handleUpload(): void;
}

function areAllKeysNotEmpty(obj: Record<string, any>): boolean {
  return Object.entries(obj).every(([key, value]) => {
    if (key === "additional_settings") {
      return true; // Skip checking this key as it is not mandatory
    } else {
      return value || value === 0;
    }
  });
}

const UploadDBCFileSection = forwardRef<
  handleUploadDBC,
  UploadDBCFileSectionProps
>((props, ref) => {
  const DBCFileInput = createRef<any>();

  const [DBCFileName, setDBCFileName] = useState<string>("");
  const [DBCFile, setDBCFile] = useState<File>(new File([""], "filename"));
  const [showDBCUploadProgress, setShowDBCUploadProgress] =
    useState<boolean>(false);
  const [DBCFileLoaded, setDBCFileLoaded] = useState<number>(0);
  const [DBCFileTotal, setDBCFileTotal] = useState<number>(0);
  const [uploadedDBCFileResponse, setUploadedDBCFileResponse] = useState<{
    status: number;
    data: { id: string };
  }>({
    status: 0,
    data: { id: "" },
  });

  const onSelect = useCallback((e) => {
    setShowDBCUploadProgress(false);
    setDBCFileLoaded(0);
    setDBCFileTotal(0);

    if (e.target.files.length !== 0) {
      const selectedFile = e.target.files[0];
      if (selectedFile) {
        const fileName = selectedFile.name;
        const fileExtension = fileName.split(".").pop(); // Get the file extension
        if (fileExtension === "dbc") {
          setDBCFile(selectedFile);
          setDBCFileName(fileName);
        } else {
          // File extension is not ".dbc", show an error message and handle it accordingly
          beamtoast.error(`Only ".dbc" file types are allowed!`);
          console.log(
            'Only files with the ".dbc" extension are allowed to be uploaded.'
          );
          setShowDBCUploadProgress(false);
          setDBCFileLoaded(0);
          setDBCFileTotal(0);
          setDBCFile(new File([""], "filename"));
          setDBCFileName("");
        }
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const uploadDBC = async (additional_settings: string) => {
    // DBC Upload API Endpoint
    const url = `/api/v1/dbcs`;

    let formData = new FormData();

    if (
      props.newDBC.name &&
      props.newDBC.version &&
      props.newDBC.input_table &&
      props.newDBC.output_table &&
      props.newDBC.period &&
      additional_settings
    ) {
      formData.append("file", DBCFile);
      formData.append("name", props.newDBC.name.trim());
      formData.append("version", props.newDBC.version.trim());
      formData.append("input_table", props.newDBC.input_table);
      formData.append("output_table", props.newDBC.output_table.trim());
      formData.append("period", props.newDBC.period.toString().trim());
      formData.append("additional_settings", additional_settings);
    } else {
      beamtoast.error("All fields are required.");
      return;
    }

    try {
      const res = await uploadFile(url, formData, (p) => {
        // for loading progress
        setDBCFileLoaded(p.loaded);
        setDBCFileTotal(p.total);
      });

      setUploadedDBCFileResponse(res);

      if (res.status === 201) {
        beamtoast.success(`Created DBC "${props.newDBC.name}"`);
        Mixpanel.track("Uploaded DBC", {
          Firmware: res.data["version"],
        });
        props.setNewDBCName(props.newDBC.name ? props.newDBC.name : "");
        props.onClose();
        props.handleUpdate();
      }
    } catch (error: any) {
      console.log(error);

      Mixpanel.track("Failure", {
        type: "Upload DBC",
      });
      props.setNewDBCName("");

      // Handling Error for File Size exceeding limit from NGINX
      if (String(error).includes("413")) {
        beamtoast.error("Upload failed due to size limit!");
      }

      // Handling other error types
      const errorMessage = error.response?.data?.error;

      if (errorMessage.includes("invalid continuation byte")) {
        beamtoast.error("Uploaded file is not supported!");
      } else if (errorMessage.includes("fixprotobuff failed")) {
        beamtoast.error("DBC created but fixprotobuff failed");
      } else {
        beamtoast.error("Failed to create DBC: ", errorMessage); // showing error here as suggested by Nikhil for better user error reporting
      }

      console.log(errorMessage); // print error message received from api error response
    }
  };

  const isStreamValid = (output_stream: string) => {
    const regex = /^(?=.*[a-zA-Z])[a-zA-Z0-9_]+$/;
    return regex.test(output_stream);
  };

  function isNumeric(input: string): boolean {
    // Regular expression to match numeric values
    const numericRegex = /^-?(?!-)\d*\.?\d+$/;

    // Test if the input matches the numeric regex
    return numericRegex.test(input);
  }

  const handleUpload = useCallback(() => {
    let nameValidation = true;
    let fileValidation = false;
    let outputStreamValidation = false;
    let cycleTimeValidation = false;
    let additionalSettingsValidation = true;

    let nameExists = false;
    let versionExists = false;

    let lowerThresholdValidation = true;
    let upperThresholdValidation = true;
    let dbcVersionFilterValidation = true;

    // name and version validation
    props.DBCData.forEach((dbc) => {
      if (dbc.name === props.newDBC.name) {
        nameExists = true;
      }
      if (dbc.version === props.newDBC.version) {
        versionExists = true;
      }
    });

    if (nameExists) {
      nameValidation = false;
      beamtoast.error("DBC name already exists!");
    }

    if (versionExists) {
      nameValidation = false;
      beamtoast.error("DBC version already exists!");
    }

    // file validation (cannot be empty + .dbc required)
    if (DBCFileInput?.current?.files && DBCFileName !== "") {
      const fileCount = DBCFileInput.current.files.length;
      if (fileCount === 0) {
        setTimeout(() => beamtoast.error("A DBC file must be selected!"));
      } else {
        fileValidation = true;
      }
    } else {
      beamtoast.error("A DBC file must be selected!");
    }

    // output_stream value validation acc to regex
    if (props.newDBC.output_table && isStreamValid(props.newDBC.output_table))
      outputStreamValidation = true;
    else {
      outputStreamValidation = false;
      beamtoast.error(
        "Please enter a valid output stream name! Space and special characters are not allowed except underscore"
      );
    }

    // period validation (cannot be <= 0)
    if (props.newDBC.period && props.newDBC.period > 0)
      cycleTimeValidation = true;
    else {
      cycleTimeValidation = false;
      beamtoast.error("Cycle time cannot be negative or zero!");
    }

    let additional_settings = props.newDBC.additional_settings;

    // remove false values from uploading
    if (!additional_settings?.dont_decode_choices)
      delete additional_settings?.dont_decode_choices;

    // remove false values from uploading
    if (!additional_settings?.add_suffix_canid)
      delete additional_settings?.add_suffix_canid;

    if (
      additional_settings?.lower_threshold !== undefined &&
      additional_settings.upper_threshold !== undefined
    ) {
      if (
        // should be a valid numeric
        isNumeric(additional_settings.lower_threshold.toString()) &&
        isNumeric(additional_settings.upper_threshold.toString())
      ) {
        // validation for lower >= upper threshold (not allowed)
        if (
          additional_settings?.lower_threshold >=
          additional_settings?.upper_threshold
        ) {
          lowerThresholdValidation = false;
          upperThresholdValidation = false;
          beamtoast.error(
            "Lower Threshold cannot be equal or greater than upper threshold!"
          );
        } else {
          // lower_threshold validation (cannot be <= 0)
          if (additional_settings?.lower_threshold > 0)
            lowerThresholdValidation = true;
          else {
            lowerThresholdValidation = false;
            beamtoast.error("Lower Threshold cannot be negative or zero!");
          }

          // upper_threshold validation (cannot be <= 0)
          if (additional_settings?.upper_threshold > 0)
            upperThresholdValidation = true;
          else {
            upperThresholdValidation = false;
            beamtoast.error("Upper Threshold cannot be negative or zero!");
          }
        }
      } else {
        if (!isNumeric(additional_settings.lower_threshold.toString())) {
          lowerThresholdValidation = false;
          beamtoast.error("Please enter a valid lower threshold value!");
        } else if (!isNumeric(additional_settings.upper_threshold.toString())) {
          upperThresholdValidation = false;
          beamtoast.error("Please enter a valid upper threshold value!");
        } else {
          lowerThresholdValidation = false;
          upperThresholdValidation = false;
          beamtoast.error("Please enter a valid threshold value!");
        }
      }
    } else {
      if (additional_settings?.lower_threshold !== undefined) {
        // should be a valid numeric
        if (isNumeric(additional_settings.lower_threshold.toString())) {
          // lower_threshold validation (cannot be <= 0)
          if (additional_settings?.lower_threshold > 0)
            lowerThresholdValidation = true;
          else {
            lowerThresholdValidation = false;
            beamtoast.error("Lower Threshold cannot be negative or zero!");
          }
        } else {
          lowerThresholdValidation = false;
          beamtoast.error("Please enter a valid lower threshold value!");
        }
      } else lowerThresholdValidation = true;

      if (additional_settings?.upper_threshold !== undefined) {
        // should be a valid numeric
        if (isNumeric(additional_settings.upper_threshold.toString())) {
          // upper_threshold validation (cannot be <= 0)
          if (additional_settings?.upper_threshold > 0)
            upperThresholdValidation = true;
          else {
            upperThresholdValidation = false;
            beamtoast.error("Upper Threshold cannot be negative or zero!");
          }
        } else {
          upperThresholdValidation = false;
          beamtoast.error("Please enter a valid upper threshold value!");
        }
      } else upperThresholdValidation = true;
    }

    // dbc_ver validation (cannot be < 0)
    if (additional_settings?.dbc_ver !== undefined) {
      if (additional_settings?.dbc_ver >= 0) dbcVersionFilterValidation = true;
      else {
        dbcVersionFilterValidation = false;
        beamtoast.error("Version Filter cannot be negative!");
      }
    } else delete additional_settings?.dbc_ver;

    // cumulative validation check
    if (
      lowerThresholdValidation &&
      upperThresholdValidation &&
      dbcVersionFilterValidation
    )
      additionalSettingsValidation = true;
    else additionalSettingsValidation = false;

    if (
      nameValidation &&
      fileValidation &&
      outputStreamValidation &&
      cycleTimeValidation &&
      additionalSettingsValidation
    ) {
      if (!additional_settings) additional_settings = {};

      setShowDBCUploadProgress(true);
      uploadDBC(JSON.stringify(additional_settings));
    }
  }, [DBCFileName, props.DBCData, DBCFileInput, uploadDBC]); // eslint-disable-line react-hooks/exhaustive-deps

  useImperativeHandle(ref, () => ({
    handleUpload,
  }));

  return (
    <Form>
      <Form.Field>
        <label htmlFor="upload_file">
          <p
            style={{
              fontSize: "1.1rem",
            }}
          >
            Upload file from the system
          </p>
        </label>
        <div style={{ position: "relative" }}>
          <StyledFileUploadButton
            fluid
            content="Select File"
            labelPosition="left"
            icon="file"
          />
          <StyledFileInput
            type="file"
            id="upload_file"
            ref={DBCFileInput}
            onChange={onSelect}
          />
        </div>
        <label htmlFor="file_name" style={{ marginTop: "12px" }}>
          File Chosen:{" "}
        </label>
        <span id="file_name">
          {DBCFileName !== "" ? DBCFileName : "No File Chosen"}
        </span>
      </Form.Field>
      {showDBCUploadProgress && (
        <Form.Field>
          <label htmlFor="file-progress">
            {uploadedDBCFileResponse.status === 0 ? (
              <span>
                File Uploading
                <AnimatedEllipsis spacing={3} dotSize={"8px"} />
              </span>
            ) : (
              <span>File Uploaded</span>
            )}
          </label>
          <progress
            id="file-progress"
            max={DBCFileTotal}
            value={DBCFileLoaded}
          />
        </Form.Field>
      )}
    </Form>
  );
});

export enum DBCOperationType {
  Create = "create",
  Update = "update",
}

interface CreateDBCModalProps {
  readonly open: boolean;
  readonly onOpen: () => void;
  readonly onClose: () => void;
  readonly selectedDBC?: DBCResponse;
  readonly DBCData: DBCResponse[];
  readonly operationType: string;
  setNewDBCName: (arg0: string) => void;
  handleUpdate: () => void;
}

export default function CreateDBCModal(props: CreateDBCModalProps) {
  const uploadDBCRef = useRef<handleUploadDBC>(null);

  const [loading, setLoading] = useState<boolean>(true);
  // const [existingDBC, setExistingDBC] = useState<DBCResponse>();
  const [activeIndex, setActiveIndex] = useState<number>(-1);
  const [newDBC, setnewDBC] = useState<DBCData>({
    name: "",
    version: "",
    input_table: "",
    output_table: "",
    period: undefined,
    additional_settings: {
      dont_decode_choices: false,
      add_suffix_canid: false,
      lower_threshold: undefined,
      upper_threshold: undefined,
      dbc_ver: undefined,
    },
  });
  const [inputTableOptions, setInputTableOptions] = useState<
    {
      key: string;
      value: string;
      text: string;
    }[]
  >([]);
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);

  const setDBCName = (DBCName: string) => {
    setnewDBC({
      ...newDBC,
      name: DBCName,
    });
  };

  const setDBCVersion = (DBCVersion: string) => {
    setnewDBC({
      ...newDBC,
      version: DBCVersion,
    });
  };

  const setDBCInputTable = (DBCInputTableValue: string) => {
    setnewDBC({
      ...newDBC,
      input_table: DBCInputTableValue,
    });
  };

  const setDBCOutputTable = (DBCOutputTableValue: string) => {
    setnewDBC({
      ...newDBC,
      output_table: DBCOutputTableValue,
    });
  };

  const setDBCPeriod = (DBCPeriod: number) => {
    setnewDBC({
      ...newDBC,
      period: DBCPeriod,
    });
  };

  const updateSuffixCanidState = (state: boolean) => {
    setnewDBC({
      ...newDBC,
      additional_settings: {
        ...newDBC.additional_settings,
        add_suffix_canid: state,
      },
    });
  };

  const updateDisableEnumParsingState = (state: boolean) => {
    setnewDBC({
      ...newDBC,
      additional_settings: {
        ...newDBC.additional_settings,
        dont_decode_choices: state,
      },
    });
  };

  const setDBCLowerThreshold = (DBCLowerThreshold: number | undefined) => {
    setnewDBC({
      ...newDBC,
      additional_settings: {
        ...newDBC.additional_settings,
        lower_threshold: DBCLowerThreshold,
      },
    });
  };

  const setDBCUpperThreshold = (DBCUpperThreshold: number | undefined) => {
    setnewDBC({
      ...newDBC,
      additional_settings: {
        ...newDBC.additional_settings,
        upper_threshold: DBCUpperThreshold,
      },
    });
  };

  const setDBCVersionFilter = (DBCVersionFilter: number | undefined) => {
    setnewDBC({
      ...newDBC,
      additional_settings: {
        ...newDBC.additional_settings,
        dbc_ver: DBCVersionFilter,
      },
    });
  };

  const handleAccordionClick = () => {
    setActiveIndex((index) => (index === 0 ? 1 : 0));
  };

  useEffect(() => {
    const fn = async () => {
      let streamNames: string[] = [];
      const requiredFields = [
        "can_id",
        "byte1",
        "byte2",
        "byte3",
        "byte4",
        "byte5",
        "byte6",
        "byte7",
        "byte8",
        "sequence",
        "timestamp",
      ];

      const streams = await fetchAllStreamsWithDetails();

      for (const stream in streams) {
        const fields = streams[stream].fields;

        // check all mentioned fields above are present in the stream
        const allRequiredFieldsPresent = requiredFields.every((requiredField) =>
          Object.keys(fields).includes(requiredField)
        );

        if (allRequiredFieldsPresent) {
          streamNames.push(stream);
        }
      }

      const tableOptions = dropDownOptionsFromArray(streamNames);
      setInputTableOptions(tableOptions);
      setLoading(false);
    };

    setLoading(true);
    fn();
  }, []);

  useEffect(() => {
    if (areAllKeysNotEmpty(newDBC)) setButtonDisabled(false);
    else setButtonDisabled(true);
  }, [newDBC]);

  useEffect(() => {
    if (props.selectedDBC) {
      // setExistingDBC(props.selectedDBC);
      newDBC.name = props.selectedDBC.name;
      newDBC.input_table = props.selectedDBC.input_table;
      newDBC.output_table = props.selectedDBC.output_table;
      newDBC.version = props.selectedDBC.version;
      newDBC.period = Number(props.selectedDBC.period);
    } else {
      // setExistingDBC(undefined);
      setnewDBC({
        name: "",
        version: "",
        input_table: "",
        output_table: "",
        period: undefined,
      });
    }
  }, [props.selectedDBC]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal
      className="dark"
      onClose={props.onClose}
      onOpen={props.onOpen}
      open={props.open}
    >
      <Modal.Header>Create DBC Parser</Modal.Header>
      <Modal.Content>
        {loading ? (
          <LoadingAnimation
            loaderContainerHeight="calc(100%)"
            fontSize="1.5rem"
            loadingText="Loading..."
          />
        ) : (
          <Grid>
            <Grid.Row>
              <Grid.Column width={8}>
                <StyledInput labelPosition="left">
                  <StyledLabel>DBC Name</StyledLabel>
                  <input
                    placeholder="DBC Name"
                    value={newDBC.name}
                    onChange={(e) => setDBCName(e.target.value)}
                  />
                </StyledInput>
              </Grid.Column>
              <Grid.Column width={8}>
                <StyledInput labelPosition="left">
                  <StyledLabel>DBC Version</StyledLabel>
                  <input
                    placeholder="DBC Version"
                    value={newDBC.version}
                    onChange={(e) => setDBCVersion(e.target.value)}
                  />
                </StyledInput>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                <StyledInput labelPosition="left">
                  <StyledLabel>Input Stream</StyledLabel>
                  <Dropdown
                    selection
                    fluid
                    search
                    placeholder="Stream name"
                    options={inputTableOptions}
                    onChange={(_e, d) => {
                      if (d.value) setDBCInputTable(d.value.toString());
                    }}
                    style={{
                      border: "none",
                    }}
                  />
                </StyledInput>
              </Grid.Column>
              <Grid.Column width={8}>
                <StyledInput labelPosition="left">
                  <StyledLabel>Output Stream</StyledLabel>
                  <input
                    placeholder="Output Stream"
                    value={newDBC.output_table}
                    onChange={(e) => setDBCOutputTable(e.target.value)}
                  />
                </StyledInput>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16}>
                <StyledInput labelPosition="left">
                  <StyledLabel>Cycle Time (ms)</StyledLabel>
                  <input
                    placeholder="Cycle Time (ms)"
                    type="number"
                    value={newDBC.period === 0 ? undefined : newDBC.period}
                    onChange={(e) => setDBCPeriod(Number(e.target.value))}
                  />
                </StyledInput>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                <UploadDBCFileSection
                  ref={uploadDBCRef}
                  onClose={props.onClose}
                  DBCData={props.DBCData}
                  newDBC={newDBC}
                  setNewDBCName={(name: string) => props.setNewDBCName(name)}
                  handleUpdate={() => props.handleUpdate()}
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Accordion inverted>
                <Accordion.Title
                  active={activeIndex === 0}
                  index={0}
                  onClick={handleAccordionClick}
                >
                  <Icon name="dropdown" />
                  <span style={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                    Additional Settings
                  </span>
                </Accordion.Title>
                <Accordion.Content
                  active={activeIndex === 0}
                  style={{ paddingLeft: "20px" }}
                >
                  <Grid>
                    <Grid.Row style={{ marginTop: "1rem" }}>
                      <Grid.Column width={16}>
                        <Checkbox
                          checked={newDBC.additional_settings?.add_suffix_canid}
                          style={{ marginRight: "1rem" }}
                          onChange={(e, { checked }) => {
                            updateSuffixCanidState(checked ? checked : false);
                          }}
                        />
                        <span style={{ fontSize: "1.1rem" }}>
                          Add CAN ID as suffix to column names
                        </span>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={16}>
                        <Checkbox
                          checked={
                            newDBC.additional_settings?.dont_decode_choices
                          }
                          style={{ marginRight: "1rem" }}
                          onChange={(e, { checked }) => {
                            updateDisableEnumParsingState(
                              checked ? checked : false
                            );
                          }}
                        />
                        <span style={{ fontSize: "1.1rem" }}>
                          Disable Enum Parsing
                        </span>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={12}>
                        <StyledInput labelPosition="left">
                          <StyledLabel>
                            Lower Threshold for flushing clusters (ms)
                          </StyledLabel>
                          <input
                            placeholder="value in ms"
                            type="number"
                            onChange={(e) =>
                              setDBCLowerThreshold(
                                e.target.value
                                  ? Number(e.target.value)
                                  : undefined
                              )
                            }
                          />
                        </StyledInput>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={12}>
                        <StyledInput labelPosition="left">
                          <StyledLabel>
                            Upper Threshold for flushing clusters (ms)
                          </StyledLabel>
                          <input
                            placeholder="value in ms"
                            type="number"
                            onChange={(e) =>
                              setDBCUpperThreshold(
                                e.target.value
                                  ? Number(e.target.value)
                                  : undefined
                              )
                            }
                          />
                        </StyledInput>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={12}>
                        <StyledInput labelPosition="left">
                          <StyledLabel>
                            DBC Version filter in CAN table on column dbc_ver
                          </StyledLabel>
                          <input
                            placeholder="value"
                            type="number"
                            onChange={(e) =>
                              setDBCVersionFilter(
                                e.target.value
                                  ? Number(e.target.value)
                                  : undefined
                              )
                            }
                          />
                        </StyledInput>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Accordion.Content>
              </Accordion>
            </Grid.Row>
          </Grid>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button
          secondary
          onClick={() => {
            setnewDBC({
              name: "",
              version: "",
              input_table: "",
              output_table: "",
              period: undefined,
            });
            props.onClose();
          }}
        >
          Cancel
        </Button>

        <Button
          type="submit"
          primary
          disabled={buttonDisabled}
          onClick={() => {
            if (uploadDBCRef.current) {
              uploadDBCRef.current.handleUpload();
            }
          }}
        >
          Submit
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
