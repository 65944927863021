import React, { useState, useMemo } from "react";
import styled from "styled-components";
import { CreateActionContainer, StyledNonBoldHeader } from "./NewAction";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-markdown";
import "ace-builds/src-noconflict/theme-chrome";
import "ace-builds/src-noconflict/theme-chaos";
import { StyledHeader } from "../ActionsV3/SelectableItem";
import Toggle from "../../../common/Toggle";
import {
  EditAnimatedMetaDropdown,
  EditAnimatedMetaInput,
} from "../../Dashboards/Panel/util";
import ThemeSchema from "../../../../theme/schema";
import { useUser } from "../../../../context/User.context";
import { CardContainer } from "../../../common/ActionsUtils";
import { Button, Modal } from "semantic-ui-react";
import { beamtoast } from "../../../common/CustomToast";

const AdvancedSectionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 64px;
  margin-left: 24px;
`;

const AdvancedSectionItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
`;

const Header = styled(StyledHeader)`
  display: flex;
  align-items: center;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: normal;
  white-space: nowrap;
`;

type CustomTimeoutModalProps = {
  readonly isOpen: boolean;
  readonly setIsOpen: (isOpen: boolean) => void;
  readonly handleCustomTimeout: (value: number) => void;
};

function CustomTimeoutModal(props: CustomTimeoutModalProps) {
  const { isOpen, setIsOpen, handleCustomTimeout } = props;
  const [timeoutInDays, setTimeoutInDays] = useState<number>(2);

  const handleCustomTimeoutChange = (value: number) => {
    setTimeoutInDays(value);
  };

  const handleSubmit = () => {
    if (timeoutInDays <= 0) {
      beamtoast.error("Custom Timeout value should be greater than 0 days");
      return;
    }
    setIsOpen(false);
    handleCustomTimeout(timeoutInDays);
  };
  return (
    <Modal
      closeIcon
      size="mini"
      className="dark"
      closeOnDimmerClick={false}
      onClose={() => setIsOpen(false)}
      onOpen={() => setIsOpen(true)}
      open={isOpen}
    >
      <Modal.Header>Add Custom Timeout</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <p>Please set a custom timeout for Action</p>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "16px",
            }}
          >
            <EditAnimatedMetaInput
              min="0"
              elementid="custom-timeout"
              placeholder="Enter Custom Timeout"
              type="number"
              label="Custom Timeout (in days)"
              value={timeoutInDays}
              onChangeEvent={(e) => handleCustomTimeoutChange(e.target.value)}
              style={{ width: "100%" }}
            />
            <b
              style={{
                marginBottom: "12px",
              }}
            >
              Days
            </b>
          </div>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button positive onClick={handleSubmit}>
          Add
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

type AdvanceSettingsSectionProps = {
  readonly endTimestamp: Date | number;
  readonly releaseNotes: string;
  readonly action: string;
  readonly setEndTimestamp: (endTimestamp: Date | number) => void;
  readonly setReleaseNotes: (releaseNotes: string) => void;
  readonly setRetryUntil: (retryUntil: Date | number) => void;
};

function AdvanceSettingsSection({
  endTimestamp,
  releaseNotes,
  action,
  setEndTimestamp,
  setReleaseNotes,
  setRetryUntil,
}: AdvanceSettingsSectionProps) {
  const { user } = useUser();
  const theme = user?.settings?.theme ?? "dark";

  const [autoRetry, setAutoRetry] = useState<boolean>(false);
  const [isEnabledAdvancedSettings, setIsEnabledAdvancedSettings] =
    useState<boolean>(false);
  const [isCustomTimeoutOpen, setIsCustomTimeoutOpen] =
    useState<boolean>(false);
  const [timeoutDisplay, setTimeoutDisplay] = useState<string>("Never");

  const timeOutAfterOptions = useMemo(() => {
    const currentTime = new Date();
    const oneHrInMs = 60 * 60 * 1000; // milliseconds in an hour
    const oneDayInMs = 24 * oneHrInMs; // milliseconds in a day

    // Setting never timeout to Thu Apr 01 2100 00:00:00, Hi future peeps. I'm solving the Y2K bug by pushing it to Y2.1K #BlameGautamBT
    const neverTimestamp = 4110201000000;

    return [
      {
        key: "Select Custom Time",
        text: "Select Custom Time",
        icon: "add",
        onClick: () => {
          setIsCustomTimeoutOpen(true);
        },
      },
      { key: "Never", text: "Never", value: neverTimestamp },
      {
        key: "6 hours",
        text: "6 hours",
        value: currentTime.getTime() + 6 * oneHrInMs,
      },
      {
        key: "12 hours",
        text: "12 hours",
        value: currentTime.getTime() + 12 * oneHrInMs,
      },
      {
        key: "1 day",
        text: "1 day",
        value: currentTime.getTime() + oneDayInMs,
      },
      {
        key: "3 days",
        text: "3 days",
        value: currentTime.getTime() + 3 * oneDayInMs,
      },
      {
        key: "7 days",
        text: "7 days",
        value: currentTime.getTime() + 7 * oneDayInMs,
      },
    ];
  }, []);

  const handleToggleChange = () => {
    const newAutoRetry = !autoRetry;
    setAutoRetry(newAutoRetry);
    if (!newAutoRetry) {
      setRetryUntil(0);
    } else {
      setRetryUntil(endTimestamp);
    }
  };

  const handleTimeout = (e, data) => {
    const value = data.value as Date | number;
    setEndTimestamp(value);
    setTimeoutDisplay(
      data.options.find((option) => option.value === value).text
    );
    if (autoRetry) {
      setRetryUntil(value);
    }
  };

  const handleCustomTimeout = (value: number) => {
    const currentTime = new Date();
    const oneDayInMs = 24 * 60 * 60 * 1000; // milliseconds in a day

    const timeoutValue = currentTime.getTime() + oneDayInMs * value;

    setTimeoutDisplay(`${value} days`);
    setEndTimestamp(timeoutValue);
    if (autoRetry) {
      setRetryUntil(timeoutValue);
    }
  };

  return (
    <CreateActionContainer>
      <CustomTimeoutModal
        isOpen={isCustomTimeoutOpen}
        setIsOpen={setIsCustomTimeoutOpen}
        handleCustomTimeout={handleCustomTimeout}
      />

      <CardContainer>
        <AdvancedSectionContainer style={{ marginLeft: "0px" }}>
          <AdvancedSectionItem>
            <Header as="h3">Advanced Settings</Header>
            <Toggle
              id="action-toggle"
              size="large"
              bgcolor="#05DB0A"
              checked={isEnabledAdvancedSettings}
              onChange={() =>
                setIsEnabledAdvancedSettings(!isEnabledAdvancedSettings)
              }
            />
          </AdvancedSectionItem>
        </AdvancedSectionContainer>

        {isEnabledAdvancedSettings && (
          <>
            <AdvancedSectionContainer style={{ marginTop: "42px" }}>
              <AdvancedSectionItem>
                <StyledNonBoldHeader as="h3" style={{ marginBottom: "0px" }}>
                  Auto Retry
                </StyledNonBoldHeader>
                <Toggle
                  id="action-toggle"
                  size="small"
                  bgcolor="#05DB0A"
                  onChange={handleToggleChange}
                  checked={autoRetry}
                />
              </AdvancedSectionItem>
              <AdvancedSectionItem>
                <StyledNonBoldHeader as="h3" style={{ marginBottom: "0px" }}>
                  Mark Action as Timed Out after
                </StyledNonBoldHeader>
                <div
                  style={{
                    marginTop: "12px",
                  }}
                >
                  <EditAnimatedMetaDropdown
                    elementid="time-out-after"
                    placeholder="Select Period"
                    selection
                    options={timeOutAfterOptions}
                    value={endTimestamp as number}
                    onChange={handleTimeout}
                    text={timeoutDisplay}
                    labelBg={
                      ThemeSchema.data[theme]?.colors[
                        "action-card-container-background"
                      ]
                    }
                  />
                </div>
              </AdvancedSectionItem>
            </AdvancedSectionContainer>

            {action === "update_firmware" && (
              <AdvancedSectionContainer>
                <AdvancedSectionItem
                  style={{
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    marginTop: "18px",
                  }}
                >
                  <StyledNonBoldHeader as="h3" style={{ marginBottom: "0px" }}>
                    Release notes
                  </StyledNonBoldHeader>
                  <AceEditor
                    height="300px"
                    width="800px"
                    placeholder="Enter Release Notes"
                    mode="markdown"
                    theme={ThemeSchema.data[theme]?.colors["ace-editor-theme"]}
                    name="release-notes"
                    onChange={setReleaseNotes}
                    value={releaseNotes}
                    fontSize={14}
                    showPrintMargin={false}
                    showGutter={true}
                    highlightActiveLine={true}
                    setOptions={{
                      enableBasicAutocompletion: false,
                      enableLiveAutocompletion: false,
                      enableSnippets: false,
                      showLineNumbers: true,
                      tabSize: 2,
                    }}
                    style={{
                      marginBottom: "16px",
                      borderRadius: "4px",
                      border: `${ThemeSchema.data[theme]?.colors["ace-editor-border"]}`,
                      boxShadow: `${ThemeSchema.data[theme]?.colors["ace-editor-box-shadow"]}`,
                    }}
                  />
                </AdvancedSectionItem>
              </AdvancedSectionContainer>
            )}
          </>
        )}
      </CardContainer>
    </CreateActionContainer>
  );
}

export default AdvanceSettingsSection;
