import React, { useEffect, useState } from "react";
import moment from "moment";
import { Device } from "../../../../BytebeamClient";
import { Table, Button, Grid, GridColumn, GridRow } from "semantic-ui-react";
import { capitalizeFirstLetter } from "../../util";
import { ErrorMessage } from "../../../common/ErrorMessage";
import { StyledCard } from "../../DeviceManagement/Devices/Device";
import styled from "styled-components";
import { breakpoints } from "../../../common/breakpoints";
import { useHistory } from "react-router-dom";

import SlicedTextPopUp from "../../DeviceManagement/Devices/SlicedTextPopUp";

const StyledGridRow = styled(GridRow)`
  padding: 2px 0px !important;
`;

const StyledDeviceKey = styled(GridColumn)`
  justify-content: flex-start;
  text-align: left;
  font-weight: normal;
  font-size: 13px;
  color: ${({ theme }) => theme.colors["secondary-color-2"]} !important;
`;

const StyledDeviceValue = styled(GridColumn)`
  justify-content: flex-end;
  text-align: right;
  font-weight: bold;
  font-size: 14px;
  white-space: normal;
  word-break: break-word;
  color: ${({ theme }) => theme.colors["foreground-color"]};
`;

type SearchResultsProps = {
  devices: Device[];
  metadataKeysToShow: string[];
  onDeviceSelected: (device: Device) => void;
  filters: { [key: string]: string[] };
};

export function SearchResults(props: SearchResultsProps) {
  const history = useHistory();

  const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);

  const updateDimensions = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  useEffect(() => {
    console.log("History Action ", history.action);
    const urlParams = new URLSearchParams(window.location.search);
    if (
      history.action === "POP" &&
      !props.filters["id"] &&
      urlParams.get("id")
    ) {
      props.onDeviceSelected(
        props.devices.find(
          (d) => d.id === parseInt(urlParams.get("id") as string)
        ) as Device
      );
    }
  }, [window.location.search]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Table
      celled
      selectable
      style={
        screenWidth <= breakpoints.sm
          ? {
              border: "none",
            }
          : {}
      }
    >
      {screenWidth > breakpoints.sm && (
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>ID</Table.HeaderCell>
            <Table.HeaderCell>Last Heartbeat</Table.HeaderCell>
            {props.metadataKeysToShow.map((key) => {
              return (
                <Table.HeaderCell key={key} style={{ minWidth: "120px" }}>
                  {capitalizeFirstLetter(key)}
                </Table.HeaderCell>
              );
            })}

            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
      )}

      <Table.Body>
        {props.devices.length !== 0 ? (
          props.devices.map((device) => {
            if (screenWidth > breakpoints.sm) {
              return (
                <Table.Row key={device.id}>
                  <Table.Cell>{device.id}</Table.Cell>
                  <Table.Cell>
                    {device.state?.timestamp
                      ? capitalizeFirstLetter(
                          moment
                            .duration(
                              -1 *
                                moment().diff(
                                  moment.utc(device.state["timestamp"])
                                )
                            )
                            .humanize(true)
                        )
                      : "-"}
                  </Table.Cell>
                  {props.metadataKeysToShow.map((key) => {
                    return (
                      <Table.Cell key={key}>
                        <SlicedTextPopUp
                          text={device?.metadata[key]}
                          length={12}
                        />
                      </Table.Cell>
                    );
                  })}

                  <Table.Cell>
                    <Button
                      primary
                      onClick={() => props.onDeviceSelected(device)}
                    >
                      Select
                    </Button>
                  </Table.Cell>
                </Table.Row>
              );
            } else {
              return (
                <StyledCard
                  key={device.id}
                  onClick={() => props.onDeviceSelected(device)}
                >
                  <Grid style={{ padding: "8px 4px", cursor: "pointer" }}>
                    <StyledGridRow>
                      <StyledDeviceKey width={8}>DEVICE ID</StyledDeviceKey>
                      <StyledDeviceValue width={8}>
                        {device.id}
                      </StyledDeviceValue>
                    </StyledGridRow>

                    {props.metadataKeysToShow.map((key) => {
                      return (
                        <StyledGridRow key={key}>
                          <StyledDeviceKey width={8}>
                            {capitalizeFirstLetter(key)?.toUpperCase()}
                          </StyledDeviceKey>
                          <StyledDeviceValue width={8}>
                            {device.metadata[key]}
                          </StyledDeviceValue>
                        </StyledGridRow>
                      );
                    })}
                    <StyledGridRow>
                      <StyledDeviceKey width={8}>PULSE</StyledDeviceKey>
                      <StyledDeviceValue width={8}>
                        {device.state?.timestamp
                          ? capitalizeFirstLetter(
                              moment
                                .duration(
                                  -1 *
                                    moment().diff(
                                      moment.utc(device.state["timestamp"])
                                    )
                                )
                                .humanize(true)
                            )
                          : "--"}
                      </StyledDeviceValue>
                    </StyledGridRow>
                  </Grid>
                </StyledCard>
              );
            }
          })
        ) : (
          <Table.Row>
            <Table.Cell colspan={3 + props.metadataKeysToShow.length}>
              <ErrorMessage marginTop="30px" message={"No data Found!"} />
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
    </Table>
  );
}
